import { useState } from 'react';

import TheStakeFormCard from './TheStakeFormCard';
import { StakingCardProps } from '@/utils/types';
import { TheTabs } from '@/components/compounds/common/TheTabs';
import { TransparentCard } from '@/components/atoms/containers/Card';
import { SYMBOLS } from '@/utils/constants';

const { EURO3, A3A } = SYMBOLS;

const tabs = [
  {
    hash: "euro3",
    name: `Stake ${EURO3}`,
    content: <TheStakeFormCard type="euro3" />
  },
  {
    hash: "a3a",
    name: `Stake ${A3A} Token`,
    content: <TheStakeFormCard type="a3a" />
  }
];

export function TheStakingForm({ type, setType }: StakingCardProps) {
  const [selectedIndex, setSelectedIndex] = useState(
    type ? tabs.findIndex(tab => tab.hash === type) : 0
  );

  const handleChange = (event: React.SyntheticEvent, index: number) => {
    setSelectedIndex(index);
    if (setType) setType(tabs[index]?.hash as 'euro3' | 'a3a');
  };

  return (
    <>
      <TransparentCard
        sx={{
          width: "100%",
          pt: 2,
          pb: 4,
          pl: 4,
          pr: 6,
        }} 
      >
        <TheTabs
          tabs={tabs}
          value={selectedIndex}
          onChange={handleChange}
        />
      </TransparentCard>
    </>
  );
};
