import axios from 'axios';
import { useEffect, useState } from 'react';

import { API_BASE_URL } from '@/utils/constants';
import { CenturionStatus } from "@/utils/types";
import { useAccount } from 'wagmi';
import { readContract } from '@wagmi/core';
import { useAbi } from './useAbi';
import { BigNumberish } from 'ethers';

export const useRealCenturion = () => {
	const [centurionStatus, setCenturionStatus] = useState<CenturionStatus>({ isCenturion: false });

	const { address } = useAccount();
	const { AbiFactory: centurionAbi } = useAbi({ abiName: 'CenturionProgram' });

	useEffect(() => {

		const getIsCenturion = async () => {
			try {
				console.log("here");
				if (address && centurionAbi) {
					const hasBalance = await readContract({
						address: centurionAbi!.address!,
						abi: centurionAbi!.abi,
						functionName: "balanceOf",
						args: [address]
					  }) as BigNumberish;

					  console.log(centurionAbi!.address!);
					  console.log(hasBalance.toString());
					  const isCenturion = parseInt(hasBalance.toString()) > 0;
					  setCenturionStatus({ isCenturion });
				}
			} catch (err) {
				setCenturionStatus({ isCenturion: false });
			}
		};
		getIsCenturion();
	}, [address, centurionAbi]);

	return centurionStatus;
};

export default useRealCenturion;