import "@fontsource/all-round-gothic";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/500.css";
import "@fontsource/work-sans/600.css";
import "@fontsource/work-sans/700.css";
import { paperClasses, tooltipClasses } from "@mui/material";
import { alpha } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
    neutral: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
    neutral?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    neutral: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h4_bold: React.CSSProperties;
    h4_regular: React.CSSProperties;
    subheading_bold: React.CSSProperties;
    subheading_regular: React.CSSProperties;
    subheading_medium: React.CSSProperties;
    body_bold: React.CSSProperties;
    body_semibold: React.CSSProperties;
    body_medium: React.CSSProperties;
    body_regular: React.CSSProperties;
    caption_bold: React.CSSProperties;
    caption_medium: React.CSSProperties;
    caption_regular: React.CSSProperties;
    captions: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h4_bold?: React.CSSProperties;
    h4_regular?: React.CSSProperties;
    subheading_bold?: React.CSSProperties;
    subheading_regular?: React.CSSProperties;
    subheading_medium?: React.CSSProperties;
    body_bold?: React.CSSProperties;
    body_semibold?: React.CSSProperties;
    body_medium?: React.CSSProperties;
    body_regular?: React.CSSProperties;
    caption_bold?: React.CSSProperties;
    caption_medium?: React.CSSProperties;
    caption_regular?: React.CSSProperties;
    captions?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h4_bold: true,
    h4_regular: true,
    subheading_bold: true,
    subheading_regular: true,
    subheading_medium: true,
    body_bold: true,
    body_semibold: true,
    body_medium: true,
    body_regular: true,
    caption_bold: true,
    caption_medium: true,
    caption_regular: true,
    captions: true,
    h4: false,
    h5: false,
    h6: false,
    subtitle1: false,
    subtitle2: false,
    body1: false,
    body2: false,
    button: false,
    caption: false,
    overline: false,
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    laptop: true;
    desktop: true;
  }
}

const lightPalette = {
  primary: {
    main: "#5CEAC0",
    dark: "#149972"
  },
  secondary: {
    main: "#2D1D75",
  },
  tertiary: {
    main: "#575DDC",
  },
  neutral: {
    main: "#fefefe",
    light: "#f4f6fc",
    dark: "#e8ecfb" //"#f8f8f8",
  },
  contrastText:{
    main: "#FAFAFA",
  }
}

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#FEE9D1',
  light: '#FDAB76',
  main: '#FA541C',
  dark: '#B3200E',
  darker: '#770508',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#E6DBFE',
  light: '#B195FE',
  main: '#FF3632',
  dark: '#51310e',
  darker: '#1C0F79',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#FFFFFF',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF3632',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const darkPalette = {
  tertiary: {
    main: "#575DDC",
  },
  neutral: {
    main: "#1a1a1a",
    dark: "#242424"
  },
  contrastText:{
    main: "#FAFAFA",
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    disabled: '#919EAB',
  }
}

const retroPalette = {
  primary: {
    main: "#149972",
    dark: "#5CEAC0"
  },
  secondary: {
    main: "#e1e1e1",
  },
  tertiary: {
    main: "#575DDC",
  },
  neutral: {
    main: "#1D3557",
    dark: "#22507F"
  },
  contrastText:{
    main: "#FAFAFA",
  }
}

export type PaletteMode = 'light' | 'dark' | 'retro'

export const getCustomTheme = (mode: PaletteMode):any => ({
  palette: { 
    ...(mode === 'light'
      && lightPalette
    ),
    ...(mode === 'dark'
      && darkPalette
    ),
    ...(mode === 'retro'
      && retroPalette
    ),
  },
  typography: {
    fontFamily: [
      "Barlow",
      "Public Sans",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontFamily: "Public Sans",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "40px"
    },
    h2: {
      fontFamily: "Public Sans",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px"
    },
    h3: {
      fontFamily: "Public Sans",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px"
    },
    h4_bold: {
      fontFamily: "Public Sans",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px"
    },
    h4_regular: {
      fontFamily: "Public Sans",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px"
    },
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    button: undefined,
    caption: undefined,
    overline: undefined,
    subheading_bold: {
      fontFamily: "Public Sans",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px"
    },
    subheading_regular: {
      fontFamily: "Public Sans",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px"
    },
    subheading_medium: {
      fontFamily: "Public Sans",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px"
    },
    body_bold: {
      fontFamily: "Public Sans",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px"
    },
    body_regular: {
      fontFamily: "Public Sans",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px"
    },
    body_medium: {
      fontFamily: "Public Sans",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px"
    },
    body_semibold: {
      fontFamily: "Public Sans",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px"
    },
    caption_bold: {
      fontFamily: "Public Sans",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px"
    },
    caption_regular: {
      fontFamily: "Public Sans",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px"
    },
    caption_medium: {
      fontFamily: "Public Sans",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px"
    },
    captions: {
      fontFamily: "Public Sans",
      fontWeight: 700,
      fontSize: "10px",
      lineHeight: "12px"
    },
  },
  breakpoints: {
    values: {
      laptop: 1024,
      desktop: 2560
    },
  },
  components: {
    MuiCssBaseline:{
      styleOverrides: ( theme:any ) => ({
        "*::-webkit-scrollbar": {
          width: "8px"
        },
        "*::-webkit-scrollbar-track": {
          backgroundColor: "#D9D9D9",
          borderLeft: "5.5px solid transparent",
          borderRight: "5.5px solid transparent",
          borderRadius: 7,
          backgroundClip: "padding-box"
        },
        "*::-webkit-scrollbar-thumb": {
          borderRadius: 100,
          backgroundColor: 'rgba(250, 84, 28, 0.31)',
        }
      })
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }:any) => ({
          fontFamily: "Public Sans",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          padding: "12px 24px",
          borderRadius: "8px",
          boxShadow: 'none',
          color: theme.palette.text.primary,
          ...(ownerState.variant === "contained" && {
            ...(ownerState.color !== "primary" ? {
              color: theme.palette.contrastText.main,
            } : {
              '&:disabled': {
                opacity: 0.4,
                backgroundColor: theme.palette.primary.main,
              },
            }),
          })
        }),
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState, theme }:any) => ({
          height: "48px",
          fontFamily: "Public Sans",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          padding: "12px 24px",
          borderRadius: "56px",
          textTransform: "capitalize",
          color: theme.palette.secondary.main,
          ...(ownerState.variant === "contained" && {
            ...(ownerState.color !== "primary" && {
              color: theme.palette.neutral.main,
            }),
          })
        }),
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }:any) => ({
          borderRadius: "32px",
          backgroundColor: theme.palette.neutral.main
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: ({ theme }:any) => ({
          [`& .${paperClasses.root}`]: {
            width: "456px",
            padding: "32px",
            borderRadius: "32px 0px 0px 32px",
            backgroundColor: theme.palette.neutral.main
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: ({ theme }:any) => ({
          backgroundColor: theme.palette.neutral.dark,
        }),
        paper: ({ theme }:any) => ({
          border: "1px solid",
          borderRadius: "100%",
          borderColor: theme.palette.text.primary,
        }),
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }:any) => ({
          backgroundColor: theme.palette.neutral.dark,
          "&:hover": {
            backgroundColor: alpha(theme.palette.neutral.main, 0.95)
          },
          "&.Mui-selected:hover": {
            backgroundColor: alpha(theme.palette.neutral.main, 0.95)
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.neutral.main
          },
        }),
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }:any) => ({
          "& .MuiTabs-indicator": {},
        })
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }:any) => ({
          padding: "8px 4px",
          minWidth: "72px",
          borderRadius: theme.spacing(1),
          "&.Mui-selected": {
            backgroundColor: 'transparent',
            borderRight: '0px',
            color: 'transparent',
          },
        })
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: ({ theme }:any) => ({
          [`& .${tooltipClasses.tooltip}`]: {
            // body_regular
            fontFamily: "Public Sans",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "24px",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.contrastText.main,
            border: "1px solid",
            borderColor: alpha(theme.palette.primary.main, 0.38),
            borderRadius: "8px",
          },
          [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.primary.main,
          },
        })
      }
    },
    MuiTable:{
      styleOverrides: {
        root: {
          paddingRight: "24px"
        }
      }
    },
    MuiTableCell:{
      styleOverrides: {
        root: ({ theme }:any) => ({
          borderColor: alpha(theme.palette.primary.main, 0.32)
        }),
        head: ({ theme }:any) => ({
          backgroundColor: alpha(theme.palette.neutral.main, 0.75),
          paddingBottom: "5px"
        })
      }
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }:any) => ({
          height: "2px",
          backgroundColor: alpha(theme.palette.primary.main, 0.32),
          borderRadius: "8px"
        })
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          ":hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiSnackbarContent:{
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    }
  }
})