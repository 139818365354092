type Props = {
  color?: string;
}

export const GraphIcon = ({ color = 'white' }: Props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66666 1.66675V15.8334C1.66666 17.2167 2.78332 18.3334 4.16666 18.3334H18.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16666 14.1666L7.99166 9.69993C8.62499 8.9666 9.74999 8.91658 10.4333 9.60825L11.225 10.3999C11.9083 11.0833 13.0333 11.0416 13.6667 10.3082L17.5 5.83325"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
