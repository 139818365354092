import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { BigNumber } from 'ethers';
import { useAccount } from 'wagmi';
import {
  fetchBalance,
  fetchToken,
  readContract,
  FetchTokenResult,
  watchAccount
} from '@wagmi/core';

import {
  MAX_BIGNUMBER_DECIMAL_PRECISION,
  bigNumberToNumber,
  formatValue,
  getBEURAddress,
  getBNQAddress,
  getCollateralMBCR,
  getContractValue,
  isCollateralNative,
} from '@/utils/utils';
import { AbiResponse, Balance, Collateral, Trove, TroveData } from '@/utils/types';
import { API_BASE_URL, ENDPOINTS, SYMBOLS } from '@/utils/constants';
import { useAbi } from '@/hooks/useAbi';
import { useVaultBorrowRate } from '@/hooks/useVaultBorrowRate';

const { EURO3, A3A } = SYMBOLS;
const { VAULTS_USER, VAULTS_ADDRESS, COLLATERALS } = ENDPOINTS;

export interface TotalEuro3Supply {
  isLoading: boolean
  value: string
}

export interface NumberData {
  isLoading: boolean
  value: number
}
export interface Context {
  troveData: TroveData
  isLoading: boolean
  isVaultLoading: boolean
  setSelectedCollateralToken: (token: string) => void
  selectedTrove?: Trove
  setSelectedTrove: (troveId: string) => void
  trovesListError?: string
  refetchTrovesList?: () => Promise<void>
  euroBalance: Balance
  a3aBalance: Balance
  borrowingFeeRate: NumberData
  refetchBalances?: () => void
  refetchTroveData?: () => Promise<void>
  totalEuro3Supply: TotalEuro3Supply
}

export const defaultTroveData = {
  walletAddress: '',
  troves: [],
  minBorrowingAmount: 0,
  borrowingAmount: 0,
  borrowingSymbol: EURO3,
  liquidationReserve: 1,
  borrowingFeeRate: 0.005,
  borrowingFeeAmount: 0,
  debtAmount: 0,
  minCollateralAmount: 0,
  collateralAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
  collateralAmount: 0,
  collateralSymbol: "",
  collateralBalance: 0,
  collateralPrice: 0,
  collateralMCR: 1.2,
  collateralMBCR: getCollateralMBCR("WETH"),
  collateralSafeguard: 0.25,
  collateralTokens: [],
  collaterals: [],
  collateralization: 0,
  liquidationPrice: 0,
  redemptionThreshold: 0,
  redemptionFactor: 0,
  setBorrowingAmount: () => {},
  setCollateralAmount: () => {},
  setCollateralBalance: () => {},
  setCollateralPrice: () => {},
  setSelectedCollateral: () => {},
};

export const DashboardContext = createContext<Context>({
  troveData: defaultTroveData,
  setSelectedCollateralToken: () => {},
  selectedTrove: undefined,
  setSelectedTrove: () => {},
  isLoading: false,
  isVaultLoading: false,
  trovesListError: '',
  euroBalance: {
    formatted: '0.0',
    decimals: MAX_BIGNUMBER_DECIMAL_PRECISION,
    symbol: EURO3,
    value: BigNumber.from(0),
  },
  a3aBalance: {
    formatted: '0.0',
    decimals: MAX_BIGNUMBER_DECIMAL_PRECISION,
    symbol: A3A,
    value: BigNumber.from(0),
  },
  totalEuro3Supply: {
    isLoading: false,
    value: '0.0',
  },
  borrowingFeeRate: {
    isLoading: false,
    value: 0.005,
  },
});

type Props = {
  // 
}

export function DashboardDataProvider({ children }: PropsWithChildren<Props>) {
  const { address } = useAccount();

  const { AbiFactory: TokenToPriceFeedFactory } = useAbi({ abiName: 'TokenToPriceFeed' });
  const { VaultBorrowRate } = useVaultBorrowRate();

  const [isLoading, setIsLoading] = useState(false);
  const [isVaultLoading, setIsVaultLoading] = useState(false);
  const [trovesListError, setTrovesListError] = useState('');
  const [collateralAmount, setCollateralAmount] = useState<number>(0);
  const [borrowingAmount, setBorrowingAmount] = useState<number>(0);
  const [collateralBalance, setCollateralBalance] = useState<number>(0);
  const [collateralPrice, setCollateralPrice] = useState<number>(0);
  const [borrowingFeeRate, setBorrowingFeeRate] = useState<NumberData>({
    isLoading: true,
    value: 0,
  });
  const [selectedCollateral, setSelectedCollateral] = useState<Collateral | undefined>();
  const [shouldUpdateSelectedVault, setShouldUpdateSelectedVault] = useState(false);
  const [troveData, setTroveData] = useState<TroveData>({
    ...defaultTroveData,
    walletAddress: address,
  });
  const [euroBalance, setEuroBalance] = useState<Balance>({
    decimals: MAX_BIGNUMBER_DECIMAL_PRECISION,
    formatted: '0.0',
    symbol: EURO3,
    value: BigNumber.from(0),
    valueNumber: 0,
    valueDisplay: '0',
    isLoading: true,
  });
  const [a3aBalance, setA3aBalance] = useState<Balance>({
    decimals: MAX_BIGNUMBER_DECIMAL_PRECISION,
    formatted: '0.0',
    symbol: A3A,
    value: BigNumber.from(0),
    valueNumber: 0,
    valueDisplay: '0',
    isLoading: true,
  });
  const [totalEuro3Supply, setTotalEuro3Supply] = useState({
    isLoading: false,
    value: '0.0',
  });

  const setSelectedCollateralToken = (tokenId: string) => {
    setTroveData({
      ...troveData,
      selectedCollateral: troveData?.collaterals?.find(t => t.tokenName === tokenId),
    });
  };

  const [selectedTrove, updateSelectedTrove] = useState<Trove>();
  const setSelectedTrove = (troveAddress: string) => {
    const trove = troveData?.troves?.find(t => t.address.toLowerCase() === troveAddress.toLowerCase());
    updateSelectedTrove(trove);
  };

  const getUserTroveList = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}${VAULTS_USER}${address}`)
      const vaults: Trove[] = [];
      const vaultsPromises = [];
      for (let i = 0; i < res.data.length; i++) {
        const vault = res.data[i];
        vaultsPromises.push(axios.get(`${API_BASE_URL}${VAULTS_ADDRESS}${vault.address}`))
      }
      const vaultsRes = await Promise.all(vaultsPromises);
      for (let i = 0; i < vaultsRes.length; i++) {
        const vault = vaultsRes[i].data;
        vaults.push(vault);
      }

      updateSelectedTrove(vaults[0]);
      return { troves: vaults }
    } catch (err) {
      setTrovesListError((err as Error).message);
      return { troves: [] };
    }
  };

  const getCollateralPrice = async () => {
    try {
      const config: any = {
        address: TokenToPriceFeedFactory?.address,
        abi: TokenToPriceFeedFactory?.abi,
        functionName: "tokenPrice",
        args: [selectedCollateral?.address],
      }
      const txnResult: any = await readContract(config)
      const price: number = bigNumberToNumber(txnResult)
      setCollateralPrice(price);
    } catch (err) {
      console.error('getCollateralPrice::', err)
      setCollateralPrice(0);
    }
  };

  const getCollateralBalance = async () => {
    try {
      const config: any = {
        ...(!isCollateralNative(selectedCollateral!) && {
          token: selectedCollateral?.address,
        }),
        address: troveData.walletAddress
      };
      const txnResult = await fetchBalance({...config})
      const balance = parseFloat(txnResult.formatted)
      setCollateralBalance(balance);
    } catch (err) {
      setCollateralBalance(0);
    }
  };

  const getCollateralTokens = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}${COLLATERALS}`)
      return { collateralTokens: res.data || [] };
    } catch (err) {
      return { collateralTokens: [] };
    }
  };

  const getCollaterals = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}${COLLATERALS}`)
      return { collaterals: res.data || [] };
    } catch (err) {
      return { collaterals: [] };
    }
  };

  const getEuroBalance = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}abi/MintableToken`);
      const MintableToken: AbiResponse = res.data;
      if (MintableToken) {
        const balanceRes = await readContract({
          address: MintableToken.address!,
          abi: MintableToken.abi,
          functionName: "balanceOf",
          args: [address],
        }) as BigNumber;
        const balance = bigNumberToNumber(balanceRes);
        setEuroBalance({
          ...euroBalance,
          formatted: formatValue(balance),
          value: balanceRes,
          valueNumber: balance,
          valueDisplay: formatValue(balance, {
            minimumFractionDigits: 0,
            notation: 'compact',
          }),
          isLoading: false,
        });
      }
    } catch (err) {
      setEuroBalance({
        ...euroBalance,
        formatted: '0.0',
        value: BigNumber.from(0),
        valueNumber: 0,
        valueDisplay: '0',
        isLoading: false,
      });
    }
  };

  const getA3aBalance = async () => {
    const config: any = { token: getBNQAddress(), address }
    fetchBalance(config)
      .then(txnResult => {
        setA3aBalance({
          ...txnResult,
          valueNumber: bigNumberToNumber(txnResult.value, txnResult.decimals),
          valueDisplay: formatValue(
            parseInt(txnResult.formatted || '0'),
            {
              minimumFractionDigits: 0,
              notation: 'compact',
            },
          ),
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error('getA3aBalance::', err)
      });
  };

  const getTotalEuro3Supply = async () => {
    const beurTotalSupplyConfig: any = {
      address: getBEURAddress(),
    };
    setTotalEuro3Supply({
      ...totalEuro3Supply,
      isLoading: true,
    });
    fetchToken(beurTotalSupplyConfig)
      .then((result: FetchTokenResult) => {
        const totalSupplyBN = bigNumberToNumber(result.totalSupply.value, result.decimals)
        setTotalEuro3Supply({
          isLoading: false,
          value: formatValue(totalSupplyBN)
        });
      })
      .catch((err) => {
        setTotalEuro3Supply({
          ...totalEuro3Supply,
          isLoading: false,
        });
      });
  };

  const getBorrowingRate = async () => {
    try {
      setBorrowingFeeRate({
        isLoading: true,
        value: borrowingFeeRate.value,
      });
      const config: any = {
        address: VaultBorrowRate?.address,
        abi: VaultBorrowRate?.abi,
        functionName: "getBorrowRate",
        args: [selectedTrove?.address],
      }
      const txnResult: any = await readContract(config)
      const borrowRate: number = getContractValue(txnResult)
      setBorrowingFeeRate({
        isLoading: false,
        value: borrowRate,
      });
    } catch (err) {
      console.error('getBorrowingRate::', err)
      setBorrowingFeeRate({
        isLoading: false,
        value: 0,
      });
    }
  };

  useEffect(() => {
    const getAllData = async () => {
      setIsLoading(true);


      const [
        trovesPromise,
        collateralTokensPromise,
        collateralsPromise,
      ] = await Promise.all([
        getUserTroveList(),
        getCollateralTokens(),
        getCollaterals(),
      ]);

      const { troves } = trovesPromise;
      const { collateralTokens } = collateralTokensPromise;
      const { collaterals } = collateralsPromise;

      setIsLoading(false);
      setTroveData({
        ...troveData,
        troves,
        collateralTokens,
        collaterals,
        setBorrowingAmount,
        setCollateralAmount,
        setCollateralBalance,
        setCollateralPrice,
        setSelectedCollateral,
      });
    };
    getAllData();
    getEuroBalance();
    getA3aBalance();
    getTotalEuro3Supply();

    const unwatch = watchAccount( (account) => { } )
    return () => unwatch()
  }, [address]);

  useEffect(() => {
    if (VaultBorrowRate?.address && selectedTrove?.address) {
      getBorrowingRate();
    }
  }, [VaultBorrowRate, selectedTrove]);

  useEffect(() => {
    if (selectedCollateral) {
      getCollateralBalance();
      getCollateralPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollateral]);

  useEffect(() => {
    setTroveData({
      ...troveData,
      borrowingAmount,
      collateralAmount,
      collateralBalance,
      collateralPrice,
      selectedCollateral,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    borrowingAmount,
    collateralAmount,
    collateralBalance,
    collateralPrice,
    selectedCollateral,
  ]);

  useEffect(() => {
    if (shouldUpdateSelectedVault === true && troveData?.troves?.length) {
      const { troves = [] } = troveData;
      updateSelectedTrove(troves?.[troves?.length - 1]);
    }
  }, [troveData?.troves, shouldUpdateSelectedVault]);

  const refetchTrovesList = async () => {
    const { troves } = await getUserTroveList();
    setTroveData({ ...troveData, troves });
    setShouldUpdateSelectedVault(true);
  };

  const refetchTroveData = async () => {
    if (selectedTrove) {
      setIsVaultLoading(true);
      axios
        .get(`${API_BASE_URL}${VAULTS_ADDRESS}${selectedTrove.address}`)
        .then((res) => {
          setIsVaultLoading(false);
          const updatedTrove = res.data as Trove;
          const troves = troveData.troves;
          const index = troves.findIndex(item => item.address === selectedTrove.address);
          troves[index] = updatedTrove;
          setTroveData({ ...troveData, troves });
          updateSelectedTrove(updatedTrove);
        }).catch(err => {
          setIsVaultLoading(false);
          console.error(err);
        });
    }
  }

  const refetchBalances = () => {
    getEuroBalance();
    getA3aBalance();
    getTotalEuro3Supply();
    getBorrowingRate();
  };

  return (
    <DashboardContext.Provider value={{
      troveData,
      isLoading,
      isVaultLoading,
      setSelectedCollateralToken,
      trovesListError,
      selectedTrove,
      setSelectedTrove,
      euroBalance,
      a3aBalance,
      totalEuro3Supply,
      borrowingFeeRate,
      refetchTrovesList,
      refetchBalances,
      refetchTroveData,
    }}>
      {children}
    </DashboardContext.Provider>
  );
}

export const useDashboardData = () => {
  return useContext(DashboardContext) as Context;
};