import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Typography } from "../../../../atoms/data-display/typography/Typography";
import { TransparentCard } from "../../../../atoms/containers/Card";
import { FlexRowAlignCenter, FlexRowSpaceBetween } from "../../../../atoms/containers/FlexRow";
import { AntSwitch } from "../../../../atoms/inputs/toggleButton/ToggleButton";
import { TheBotsExportSettings } from "../the-bots-export-settings/TheBotsExportSettings";

export function TheBotsComingSoonCard() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-bots-coming-soon-card"})

  return (
    <>
      <TransparentCard sx={{ p: 3 }}>
        <FlexRowSpaceBetween sx={{
          mb: 1,
          alignItems: 'center',
          py: 1,
        }}>
          <Box>
            <Typography
              variant='h3'
              sx={{
                fontWeight: 600,
              }}
            >
              { t ("new-bot") }
            </Typography>
          </Box>
          <AntSwitch />
        </FlexRowSpaceBetween>
        <FlexRowAlignCenter 
          sx={{ 
            py: 6,
            mb: 1,
          }}>
          <Typography
            variant='h2'
            color="primary"
            sx={{
              flex: 1,
              textAlign: "center"
            }}
          >
            { t ("coming-soon") }
          </Typography>
        </FlexRowAlignCenter>
        <TheBotsExportSettings />
      </TransparentCard>
    </>
  )
}