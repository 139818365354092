import { Box, Tooltip, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import VaultBot from '../../../../../assets/images/vault_bot.png';
import { FlexColumn } from '../../../../atoms/containers/FlexColumn';
import { Typography } from '../../../../atoms/data-display/typography/Typography';
import { Button } from '../../../../atoms/inputs/button/Button';
import { TransparentCard } from '../../../../atoms/containers/Card';

const TheVaultBot = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { text, primary } = theme.palette;
  // TODO: to remove once bots is implemented
  const isComingSoon = true;

  const onClickTryNow = () => {
    if (isComingSoon) return;
    navigate('/bots');
  };

  const renderTryNow = () => (
    <Button
      variant="outlined"
      color="primary"
      onClick={onClickTryNow}
      sx={{
        color: primary.main,
        py: '6px',
        px: 2,
        width: 'fit-content',
      }}
    >
      Try Now!
    </Button>
  );

  return (
    <TransparentCard
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 2,
      }}
    >
      <Box
        component="img"
        src={VaultBot}
        sx={{
          width: 134,
          height: 'auto',
          objectFit: 'contain',
          mr: 4,
        }}
      />
      <FlexColumn>
        <Typography
          variant='h4_bold'
          sx={{
            fontSize: 20,
            lineHeight: '30px',
            mb: 0.5,
          }}
        >
          Keep Your Vault Safe
        </Typography>
        <Typography
          variant='caption_regular'
          sx={{
            color: text.disabled,
            lineHeight: '18px',
            mb: 2,
          }}
        >
          Avoid liquidations and automate your vault management
        </Typography>
        {isComingSoon ? (
          <Tooltip title="Coming soon">
            {renderTryNow()}
          </Tooltip>
        ) : renderTryNow()}
      </FlexColumn>
    </TransparentCard>
  )
}

export default TheVaultBot;
