import { useState } from 'react';

import { Box, Button, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

import { BodyCell, Header } from '../../../common/TheTable';
import { Typography } from "../../../../atoms/data-display/typography/Typography";
import { FlexRowAlignCenter } from "../../../../atoms/containers/FlexRow";
import { AntSwitch } from "../../../../atoms/inputs/toggleButton/ToggleButton";
import { FlexRow } from '../../../../atoms/containers/FlexRow';
import { FlexColumn } from '../../../../atoms/containers/FlexColumn';
import { InputField } from '../../../../atoms/inputs/inputField/InputField';
import { Dropdown, ItemProp } from '../../../../atoms/inputs/select/Dropdown';

interface VaultRow {
  id: string | number;
  vault: string;
  min_cr: number;
  target_cr: number;
  max_cr: number;
  strategy: string;
};

const InputFieldBaseStyle = {
  '& .MuiInputBase-input': {
    px: 1.75,
    py: 1,
  },
  minHeight: 40,
};

const BodyCellStyle = {
  py: 1.25,
  px: 2,
};

const DropdownStyle = {
  minHeight: 40,
  '& .MuiSelect-select': {
    py: 1,
    px: 1.75,
  },
};

const strategies: ItemProp[] = [
  {
    label: 'Staking Pool',
    value: 'staking-pool',
    svg: null,
  },
  {
    label: 'LPs',
    value: 'lps',
    svg: null,
  },
];

export function TheKeepYourVaultSafeSettingsTable() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-trove-opt-card.keep-your-vault-safe"})

  const [toggled, setToggled] = useState(true);
  const [rows, setRows] = useState<VaultRow[]>([
    {
      id: 1,
      vault: 'Vault 1',
      min_cr: 1,
      target_cr: 1,
      max_cr: 1,
      strategy: 'staking-pool',
    },
    {
      id: 2,
      vault: 'Vault 2',
      min_cr: 1,
      target_cr: 1,
      max_cr: 1,
      strategy: 'lps',
    },
    {
      id: 3,
      vault: 'Vault 3',
      min_cr: 1,
      target_cr: 1,
      max_cr: 1,
      strategy: 'staking-pool',
    },
  ]);

  const onSelectStrategy = (id: string | number, strategy: string) => {
    const updatedRows = rows.map(row => ({
      ...row,
      ...(row.id === id && { strategy }),
    }));
    setRows(updatedRows);
  };

  const handleClick = (id: string | number) => {
    console.log({id});
    // TODO: handle confirm button
  };

  return (
    <FlexColumn>
      <FlexRowAlignCenter sx={{ mb: 4 }}>
        <Box mr={2}>
          <Typography
            variant='h3'
            sx={{
              fontWeight: 600,
            }}
          >
            { t ("title") }
          </Typography>
        </Box>
        <AntSwitch checked={toggled} />
      </FlexRowAlignCenter>
      <TableContainer sx={{
        maxHeight: '100vh',
        width: '100%',
        borderRadius: '16px',
        border: '1px solid rgba(145, 158, 171, 0.24)',
      }}>
        <Table 
          stickyHeader
          sx={{ p: 0 }}
        >
          <TableHead>
            <TableRow sx={{
              width: '100%',
              borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
            }}>
              <Header name="Vault" minWidth={184} />
              <Header name="Min HF" minWidth={152} hasIcon />
              <Header name="Target HF" minWidth={152} hasIcon />
              <Header name="Max HF" minWidth={152} hasIcon />
              <Header name="Strategy" minWidth={152} hasIcon />
              <Header name="Action" minWidth={184} align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: VaultRow, index: number) => {
              const isLastItem = index === rows.length - 1;
              const selectedStrat = strategies.find(s => s.value === row.strategy);
              console.log({selectedStrat})
              return (
                <TableRow key={row.id}>
                  <BodyCell align="left" isLastItem={isLastItem}>
                    <FlexRow
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography
                        variant="body_regular"
                      >
                        {row.vault}
                      </Typography>
                    </FlexRow>
                  </BodyCell>
                  <BodyCell
                    isLastItem={isLastItem}
                    sx={BodyCellStyle}
                  >
                    <InputField
                      type='text'
                      value={
                        new Intl.NumberFormat(i18next.language, {
                          style: "percent",
                          notation:"standard",
                          minimumFractionDigits: 0,
                        }).format(row.min_cr)
                      }
                      setValue={() => {}}
                      sx={InputFieldBaseStyle}
                    />
                  </BodyCell>
                  <BodyCell
                    isLastItem={isLastItem}
                    sx={BodyCellStyle}
                  >
                    <InputField
                      type='text'
                      value={
                        new Intl.NumberFormat(i18next.language, {
                          style: "percent",
                          notation:"standard",
                          minimumFractionDigits: 0,
                        }).format(row.target_cr)
                      }
                      setValue={() => {}}
                      sx={InputFieldBaseStyle}
                    />
                  </BodyCell>
                  <BodyCell
                    isLastItem={isLastItem}
                    sx={BodyCellStyle}
                  >
                    <InputField
                      type='text'
                      value={
                        new Intl.NumberFormat(i18next.language, {
                          style: "percent",
                          notation:"standard",
                          minimumFractionDigits: 0,
                        }).format(row.max_cr)
                      }
                      setValue={() => {}}
                      sx={InputFieldBaseStyle}
                    />
                  </BodyCell>
                  <BodyCell align="left" isLastItem={isLastItem}>
                    <Dropdown
                      defaultItem={selectedStrat}
                      items={strategies}
                      onSelectChanged={(selectedStrategy) => {
                        onSelectStrategy(row.id, selectedStrategy);
                      }}
                      sx={DropdownStyle}
                    />
                  </BodyCell>
                  <BodyCell
                    isLastItem={isLastItem}
                    sx={BodyCellStyle}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleClick(row.id)}
                      sx={{
                        maxHeight: 40,
                      }}
                    >
                      Confirm
                    </Button>
                  </BodyCell>
                </TableRow>
              )
            })}
            {!rows.length && ( 
              <TableRow>
                <BodyCell
                  align="center"
                  colSpan={12}
                  sx={{ borderBottom: 0, minWidth: '100%' }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="body_regular">
                      No settings found yet
                    </Typography>
                  </Grid>
                </BodyCell>
              </TableRow> 
            )}
          </TableBody>
        </Table>       
      </TableContainer>
    </FlexColumn>
  )
}