type Props = {
  color?: string;
}

export const EuroIcon = ({ color = 'white' }: Props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0255 2C10.46 1.9998 10.8915 2.07241 11.3022 2.21485C11.8239 2.39585 12.3937 2.11959 12.5747 1.59781C12.7557 1.07603 12.4794 0.50631 11.9576 0.325307C11.3264 0.106326 10.6624 -0.00364813 9.9943 9.22967e-05C9.98084 0.000167697 9.96738 0.000514715 9.95394 0.00113323C8.16623 0.0833766 6.48381 0.870113 5.27453 2.18932C4.73096 2.78231 4.30287 3.4612 4.00292 4.19135H1.99988C1.44759 4.19135 0.999878 4.63906 0.999878 5.19135C0.999878 5.74363 1.44759 6.19135 1.99988 6.19135H3.5193C3.49659 6.45896 3.48972 6.72898 3.49918 7.00008C3.48972 7.2711 3.49658 7.54105 3.51928 7.80859H1.99988C1.44759 7.80859 0.999878 8.25631 0.999878 8.80859C0.999878 9.36088 1.44759 9.80859 1.99988 9.80859H4.00283C4.30278 10.5388 4.7309 11.2178 5.27453 11.8108C6.4838 13.13 8.16623 13.9168 9.95394 13.999C9.96811 13.9997 9.9823 14 9.99649 14.0001C10.8218 14.0029 11.6388 13.8354 12.3965 13.5081C12.9035 13.289 13.1369 12.7005 12.9179 12.1935C12.6989 11.6865 12.1103 11.453 11.6033 11.6721C11.1047 11.8875 10.5674 11.9991 10.0244 12.0001C8.77282 11.9369 7.59603 11.3836 6.74884 10.4594C6.56229 10.2559 6.39504 10.038 6.24813 9.80859H7.99988C8.55216 9.80859 8.99988 9.36088 8.99988 8.80859C8.99988 8.25631 8.55216 7.80859 7.99988 7.80859H5.52951C5.49893 7.55556 5.48849 7.29892 5.49906 7.04099C5.50017 7.01373 5.50017 6.98643 5.49906 6.95916C5.48849 6.70116 5.49893 6.44445 5.52954 6.19135H7.99988C8.55216 6.19135 8.99988 5.74363 8.99988 5.19135C8.99988 4.63906 8.55216 4.19135 7.99988 4.19135H6.24827C6.39514 3.96202 6.56234 3.74422 6.74884 3.54077C7.59627 2.61629 8.7735 2.06295 10.0255 2Z"
        fill={color}
      />
    </svg>
  )
}
