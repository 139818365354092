import { useState } from 'react';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import { writeContract, readContract } from '@wagmi/core';
import { erc20ABI, useAccount, useContractRead } from 'wagmi';

import {
  API_BASE_URL,
  ENDPOINTS,
  TX_CONFIRMATIONS,
  statuses,
} from '@/utils/constants';
import { useAbi } from './useAbi';
import { LiquidityOverviewResponse, LiquidityPool } from '@/utils/types';
import { MAX_BIGNUMBER_DECIMAL_PRECISION } from '@/utils/utils';

const useLiquidityMining = (type?: string) => {
  const { address } = useAccount();
  const { AbiFactory: StakingZapper} = useAbi({ abiName: 'StakingZapper'})
  const { AbiFactory: StakingContract} = useAbi({ abiName: 'StakingContract'})
  const { AbiFactory: ICHIVault } = useAbi({ abiName: 'ICHIVault' })
  const [ poolBalance, SetPoolBalance ] = useState<number | undefined>(undefined);
  const [ poolId, SetPoolId ] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState(statuses.NONE);
  const [poolShareLoading, setPoolShareLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [txHash, setTx] = useState("");

  const getAllPendingRewards = useContractRead({
    address: StakingContract?.address!,
    abi: StakingContract?.abi,
    functionName: 'getAllPendingRewards',
    args: [address],
    watch: true
  })
  
  let getBalanceOf;
  if (type == "staking") {
  getBalanceOf = async (poolId: number, tokenAddress: string) =>{
    const result = await readContract({
      address: StakingContract!.address!,
      abi: StakingContract!.abi,
      functionName: 'balanceOf',
      args: [poolId, address]
    })

    return result;
  }
  } else {
    getBalanceOf = async (poolId: number, tokenAddress: string) =>{
      const result = await readContract({
        address: tokenAddress,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [address ?? "0x0"]
      })
  
      return result;
    }
  }

  const getPoolSharePercent = async(poolId: number) => {
    setPoolShareLoading(true);
    if(address && StakingContract){
      const result = await readContract({
        address: StakingContract!.address!,
        abi: StakingContract!.abi,
        functionName: 'poolShare',
        args: [poolId, address]
      })
      const poolShare = ethers.utils.formatEther((result as BigNumber) ?? 0);
      setPoolShareLoading(false);
      return poolShare;
    }
    return 0;
  }

  const getUserBalanceOf = async(tokenAddress: string) => {
    const result = await readContract({
      address: tokenAddress,
      abi: erc20ABI,
      functionName: 'balanceOf',
      args: [address!]
    })

    return result;
  }

  const getLiquidityOverview = async (): Promise<LiquidityOverviewResponse> => {
    try {
      const response = await axios.get(`${API_BASE_URL}${ENDPOINTS.LIQUIDITY_MINING_OVERVIEW}`);
      return response.data;
    } catch (err) {
      return null;
    }
  }

  let getPoolBalance;


  if (type == "staking") {
  getPoolBalance = async(poolId: number, tokenAddress: string) => {
    const balance = await readContract({
      address: StakingContract?.address!,
      abi: StakingContract!.abi,
      functionName: 'balanceOf',
      args: [poolId, address]
    })

    return balance;
  };
  } else {

    getPoolBalance = async(poolId: number, tokenAddress: string) => {
      const balance = await readContract({
        address: tokenAddress,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [address?? "0x0"]
      });

      return balance;
    };
  }



  const withdraw = async ( amount: string, pool: LiquidityPool) => {
    try {

      if (type == "staking") {
      setIsLoading(true);
      setStatus(statuses.APPROVING);
      if (amount && StakingContract && address && pool) {
        const newAmount =  ethers.utils.parseUnits(amount as string, MAX_BIGNUMBER_DECIMAL_PRECISION ?? 18)
        const withdrawWrite = await writeContract({
          address: StakingContract!.address!,
          abi: StakingContract!.abi,
          functionName: "withdraw",
          mode: 'recklesslyUnprepared',
          args: [pool!.poolId, newAmount, address],
        });
        await withdrawWrite?.wait(TX_CONFIRMATIONS);
        setTx(withdrawWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
      } else {
        console.log("i'm in providing")
        setIsLoading(true);
        setStatus(statuses.APPROVING);
        if (amount && StakingContract && address && pool) {
          console.log("amount is");
          let newAmount =  ethers.utils.parseUnits(amount as string, MAX_BIGNUMBER_DECIMAL_PRECISION ?? 18)

          const balance = await readContract({
            address: pool?.lpToken!,
            abi: erc20ABI,
            functionName: 'balanceOf',
            args: [address]
          })

          if (newAmount.gt(balance)) {
            newAmount = balance;
          }

          console.log(newAmount.toString());
          console.log(MAX_BIGNUMBER_DECIMAL_PRECISION);
          const {hash, wait} = await writeContract({
            address: pool!.lpToken,
            abi: erc20ABI,
            functionName: "approve",
            mode: 'recklesslyUnprepared',
            args: [pool!.lpToken as `0x${string}`, newAmount],
          });
          setStatus(statuses.SIGNING);
          await wait(TX_CONFIRMATIONS);
          
          const withdrawWrite = await writeContract({
            address: pool!.lpToken,
            abi: ICHIVault!.abi,
            functionName: "withdraw",
            mode: 'recklesslyUnprepared',
            args: [newAmount, address],
          });
          await withdrawWrite?.wait(TX_CONFIRMATIONS);
          setTx(withdrawWrite.hash);
          setStatus(statuses.FINISHED);
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  }

  const deposit = async ( amount: string, pool: LiquidityPool) => {
    try {

    if (type == "staking") {
      setIsLoading(true);
      setStatus(statuses.APPROVING);
      if (amount && StakingZapper && address && pool) {


        const decimals = await readContract({
          address: pool?.lpToken!,
          abi: erc20ABI,
          functionName: 'decimals'
        })

        let newAmount = ethers.utils.parseUnits(amount as string, parseInt(decimals.toString())); 

        const balance = await readContract({
          address: pool?.lpToken!,
          abi: erc20ABI,
          functionName: 'balanceOf',
          args: [address]
        })

        if (newAmount.gt(balance)) {
          newAmount = balance;
        }

        const {hash, wait} = await writeContract({
          address: pool!.lpToken,
          abi: erc20ABI,
          functionName: "approve",
          mode: 'recklesslyUnprepared',
          args: [StakingContract!.address!, newAmount],
        });
        setStatus(statuses.SIGNING);
        await wait(TX_CONFIRMATIONS);
        
        const stakeWrite = await writeContract({
          address: StakingContract!.address!,
          abi: StakingContract!.abi,
          functionName: "deposit",
          mode: 'recklesslyUnprepared',
          args: [pool!.poolId, newAmount, address],
        });
        await stakeWrite?.wait(TX_CONFIRMATIONS);
        setTx(stakeWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);

    } else {

      setIsLoading(true);
      setStatus(statuses.APPROVING);
      if (amount && StakingZapper && address && pool) {
        const newAmount = ethers.utils.parseUnits(amount as string, pool!.depositTokenDecimals! ?? MAX_BIGNUMBER_DECIMAL_PRECISION) 
    
        const {hash, wait} = await writeContract({
          address: pool!.depositToken,
          abi: erc20ABI,
          functionName: "approve",
          mode: 'recklesslyUnprepared',
          args: [pool!.lpToken as `0x${string}`, newAmount],
        });
        setStatus(statuses.SIGNING);
        await wait(TX_CONFIRMATIONS);
        let stakeWrite;

          if (pool!.poolId == 0) {
            stakeWrite = await writeContract({
              address: pool!.lpToken as `0x${string}`,
              abi: ICHIVault!.abi,
              functionName: "deposit",
              mode: 'recklesslyUnprepared',
              args: [newAmount, 0, address],
            });
            await stakeWrite?.wait(TX_CONFIRMATIONS);
            setTx(stakeWrite.hash);
            setStatus(statuses.FINISHED);
        } else {
          stakeWrite = await writeContract({
            address: pool!.lpToken as `0x${string}`,
            abi: ICHIVault!.abi,
            functionName: "deposit",
            mode: 'recklesslyUnprepared',
            args: [0, newAmount, address],
          });
          await stakeWrite?.wait(TX_CONFIRMATIONS);
          setTx(stakeWrite.hash);
          setStatus(statuses.FINISHED);
        }
        
        } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);

    }
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  const harvestAll = async () => {
    try {
      setIsLoading(true);
      setStatus(statuses.APPROVING);
      if (StakingContract && address ) {
       
        const harvestAllWrite = await writeContract({
          address: StakingContract!.address!,
          abi: StakingContract!.abi,
          functionName: "harvestAll",
          mode: 'recklesslyUnprepared',
          args: [address],
        });
        await harvestAllWrite?.wait(TX_CONFIRMATIONS);
        setTx(harvestAllWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  }


  return {
    StakingZapper,
    StakingContract,
    getAllPendingRewards,
    poolBalance,
    poolId,
    txHash,
    status,
    deposit,
    withdraw,
    SetPoolId,
    getPoolBalance,
    getBalanceOf,
    getUserBalanceOf,
    getPoolSharePercent,
    getLiquidityOverview,
    harvestAll,
    poolShareLoading,
  };
};

export default useLiquidityMining;
