import { Box, LinearProgress } from "@mui/material";

import { TheTroveForm } from "./the-trove-form/TheTroveForm";
import { TheTrovesTableCard } from "./the-troves-table-card/TheTrovesTableCard";
import { TheCreateTrove } from "../the-create-trove/TheCreateTrove";
import { FlexRow } from "../../../atoms/containers/FlexRow";
import { FlexColumn } from "../../../atoms/containers/FlexColumn";
import TheVaultOverview from "./the-trove-form/TheVaultOverview";
import TheVaultBot from "./the-trove-form/TheVaultBot";
import TheVaultOptimizationCard from "./the-trove-form/TheVaultOptimizationCard";
import { useDashboardData } from "../../../../contexts/DashboardDataProvider";

function TheTroveLayout() {
  // @TODO: to be changed; should check if bot optimization is turned on
  const showOptimization = false;

  return (
    <Box sx={{ py: 3, px: 5 }}>
      <TheVaultOverview />
      <FlexRow>
        <FlexColumn mr={4}>
          <TheTroveForm />
        </FlexColumn>
        <FlexColumn>
          {!!showOptimization && <TheVaultOptimizationCard />}
          <TheTrovesTableCard />
          {!showOptimization && <TheVaultBot />}
        </FlexColumn>
      </FlexRow>
    </Box>
  );
}

export function TheTrove() {
  const { isLoading, troveData } = useDashboardData();

  return (
    <>
      {isLoading
        ? <LinearProgress color="primary" />
        : (
          troveData?.troves?.length ? <TheTroveLayout /> : <TheCreateTrove />
        )}
      {/*
      { state.matches("failure") &&
        <TheTrovesFailed /> 
      } */}
    </> 
  )
}