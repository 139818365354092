import { Box, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useMemo } from "react";

import { Icon } from "@/components/atoms/data-display/icons/Icon";
import { Tooltip } from "@/components/atoms/data-display/tooltip/Tooltip";
import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { InfoIcon } from "@/components/atoms/data-display/icons/Info";
import { useDashboardData } from "@/contexts/DashboardDataProvider";
import {
  formatValue,
  getCollateralMBCR,
  getContractValue,
  getTroveRisk,
  MAX_DECIMAL_PRECISION,
  TroveRiskResult,
} from "@/utils/utils";
import { useVault } from "@/hooks/useVault";
import { FORMAT_CONFIGS } from "@/utils/constants";

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
}));

const BoxRow = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
}));

const Flex = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
}));

const TextInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: '14px',
  lineHeight: '22px',
}));

const formatConfig = { maximumFractionDigits: MAX_DECIMAL_PRECISION };

export function TheSummaryCard() {

  const { troveData: data } = useDashboardData();
  const { LIQUIDATION_RESERVE } = useVault();


  const {
    collateralAmount,
    minCollateralAmount,
    collateralPrice,
    collateralSymbol,
    collateralMCR,
    collateralSafeguard,
    borrowingAmount,
    minBorrowingAmount,
    borrowingSymbol,
    selectedCollateral,
  } = data;

  const borrowRate = selectedCollateral?.borrowRate || 0;

  const {
    collateralization,
    liquidationPrice,
    collateralWorthInEUR,
    troveRisk,
    hideTroveRisk,
  } = useMemo(() => {
    const { tokenName = '', mlr = 0 } = selectedCollateral || {};
    let collateralWorthInEUR:number = 0,
      borrowingTooLow: boolean = true,
      collateralTooLow: boolean = true,
      troveRisk: TroveRiskResult = { level: "", color: ""},
      hideTroveRisk: boolean = true

    const collateralMBCR = getCollateralMBCR(tokenName);
    collateralWorthInEUR = collateralAmount * collateralPrice
    borrowingTooLow = borrowingAmount < minBorrowingAmount
    collateralTooLow = collateralAmount < minCollateralAmount
    const collateralization = borrowingAmount ? collateralAmount * collateralPrice / borrowingAmount : 0;
    const liquidationPrice = collateralAmount ? borrowingAmount * mlr / 100 : 0;
    troveRisk = getTroveRisk(collateralMBCR, collateralSafeguard, collateralization)
    hideTroveRisk = borrowingTooLow || collateralTooLow || !borrowingAmount || !collateralAmount

    return {
      collateralization,
      liquidationPrice,
      collateralWorthInEUR,
      troveRisk,
      hideTroveRisk,
    };
  }, [data]);

  const {
    borrowingFeeAmount,
    totalDebt,
  } = useMemo(() => {
    const liquidationReserveValue = borrowingAmount ? getContractValue(LIQUIDATION_RESERVE) : 0;
    const borrowingRate = borrowingAmount ? borrowRate : 0;

    return {
      borrowingFeeAmount: borrowingAmount * borrowingRate,
      liquidationReserve: liquidationReserveValue,
      totalDebt: borrowingAmount,
    };
  }, [
    borrowingAmount,
    borrowRate,
    LIQUIDATION_RESERVE,
  ]);

  return (
    <Box>
      <BoxRow>
        <Flex>
          <Typography variant="h4_bold">
            Total Debt
          </Typography>

          <Tooltip
            title={""}
            placement="top"
            arrow
          >
            <Box sx={{ pl: 1, pt: 0.5, display: 'none' }}>
              <InfoIcon />
            </Box>
          </Tooltip>
        </Flex>
        <Typography variant="h4_bold">
          { `${
            formatValue(totalDebt, {
              ...formatConfig,
              notation: 'standard',
            })} ${borrowingSymbol}` }
        </Typography>
      </BoxRow>
      <BoxRow>
        <Flex>
          <TextInfo variant="subheading_regular">
            Borrowing
          </TextInfo>
        </Flex>
        <TextInfo variant="subheading_regular">
          { `${formatValue(borrowingAmount, formatConfig)} ${borrowingSymbol}` }
        </TextInfo>
      </BoxRow>
      <BoxRow>
        <Flex>
          <TextInfo variant="subheading_regular">
            Borrowing Fee
          </TextInfo>
        </Flex>
        <TextInfo variant="subheading_regular">
          { `${formatValue(borrowingFeeAmount, formatConfig)} ${borrowingSymbol}` }
        </TextInfo>
      </BoxRow>

      <StyledDivider />

      <BoxRow>
        <Flex>
          <Typography variant="h4_bold">
            Total Collateral
          </Typography>

          <Tooltip
            title={""}
            placement="top"
            arrow
          >
            <Box sx={{ pl: 1, pt: 0.5, display: 'none' }}>
              <InfoIcon />
            </Box>
          </Tooltip>
        </Flex>
        <Typography variant="h4_bold">
          {`${formatValue(collateralAmount, formatConfig)} ${collateralSymbol}`}
        </Typography>
      </BoxRow>
      <BoxRow>
        <Flex>
          <TextInfo variant="subheading_regular">
            Collateral worth in EUR
          </TextInfo>
        </Flex>
        <TextInfo variant="subheading_regular">
          {` ~= ${formatValue(collateralWorthInEUR, FORMAT_CONFIGS.EURO_WITH_MAX)}`}
        </TextInfo>
      </BoxRow>
      <BoxRow>
        <Flex>
          <TextInfo variant="subheading_regular">
            { `Current ${collateralSymbol} price in EUR` } 
          </TextInfo>
        </Flex>
        <TextInfo variant="subheading_regular">
          {` ~= ${formatValue(collateralPrice, FORMAT_CONFIGS.EURO_WITH_MAX)}`}
        </TextInfo>
      </BoxRow>

      {!hideTroveRisk &&
        <>
          <StyledDivider />
          <BoxRow>
            <Flex>
              <Typography variant="h4_bold">
                Vault Risk Level
              </Typography>

              <Tooltip
                title={""}
                placement="top"
                arrow
              >
                <Box sx={{ pl: 1, pt: 0.5, display: 'none' }}>
                  <InfoIcon />
                </Box>
              </Tooltip>
            </Flex>
            <Box display="flex">
              {!!troveRisk.svg && 
                <Icon 
                  svg={troveRisk.svg}
                  width="24px" 
                  height="24px"
                />
              }
              <Typography
                variant="h4_bold" 
                color={ troveRisk.color }
                sx={{
                  pl: "4px"
                }}
              >
                {troveRisk.level}
              </Typography>
            </Box>
          </BoxRow>
          <BoxRow>
            <Flex>
              <TextInfo variant="subheading_regular">
                Collateral Ratio
              </TextInfo>
            </Flex>
            <TextInfo
              variant="subheading_regular"
              sx={{ color: troveRisk.color }}
            >
              {formatValue(collateralization, {
                ...formatConfig,
                style: "percent",
                notation: "standard",
              })}
            </TextInfo>
          </BoxRow>
          <BoxRow>
            <Flex>
              <TextInfo variant="subheading_regular">
                Liquidation Price in EUR
              </TextInfo>
            </Flex>
            <TextInfo variant="subheading_regular">
              {formatValue(liquidationPrice, FORMAT_CONFIGS.EURO_WITH_MAX)}
            </TextInfo>
          </BoxRow>
        </>
      }
    </Box>
  )
} 