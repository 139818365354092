import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { getCustomTheme, PaletteMode } from './theme';

export type ThemeContextType = {
  mode: PaletteMode,
  setThemeMode:(newMode: PaletteMode) => void
}

export const ThemeContext = React.createContext<ThemeContextType>({
  mode: "dark",
  setThemeMode: (_: PaletteMode) => {}
});

export default function CustomeThemeProvider(props:React.PropsWithChildren) {
  const storedMode: PaletteMode = localStorage.getItem("bonq-settings-theme-mode") as PaletteMode
  const [mode, setMode] = React.useState<PaletteMode>(storedMode || "dark");

  const setThemeMode = (newMode: PaletteMode) => {
    setMode(newMode)
    localStorage.setItem("bonq-settings-theme-mode", newMode as string)
  }

  const theme = createTheme(getCustomTheme(mode))

  return (
    <ThemeContext.Provider value={{mode, setThemeMode}}>
      <ThemeProvider theme={theme}>
        { props.children }
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}