import axios from 'axios';
import { useEffect, useState } from 'react';

import { API_BASE_URL } from '@/utils/constants';
import { CenturionStatus } from "@/utils/types";

export const useIsCenturion = (address: any) => {
	const [centurionStatus, setCenturionStatus] = useState<CenturionStatus>({ isCenturion: false });

	useEffect(() => {
		const getIsCenturion = async () => {
			try {
				if (address) {
					const response = await axios.get(`${API_BASE_URL}centurion/isCenturion/${address}`);
					setCenturionStatus({ isCenturion: response.data.result });
				}
			} catch (err) {
				setCenturionStatus({ isCenturion: false });
			}
		};
		getIsCenturion();
	}, [address]);

	return centurionStatus;
};

export default useIsCenturion;