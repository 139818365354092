import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { BodyCell, Header } from '@/components/compounds/common/TheTable';
import { Typography } from '@/components/atoms/data-display/typography/Typography';
import { FlexRow } from '@/components/atoms/containers/FlexRow';
import useRedemptionLiquidation from '@/hooks/useRedemptionLiquidation';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import TheTransactionModal, { ExplorerTx } from '../../common/TheTransactionModal';
import { API_BASE_URL, ENDPOINTS, statuses } from '@/utils/constants';
import { formatValue } from '@/utils/utils';
import { Icon } from '@/components/atoms/data-display/icons/Icon';
import { InputField } from '@/components/atoms/inputs/inputField/InputField';

import { ReactComponent as VaultLinkIcon } from '@/assets/icons/vault-link-icon.svg';
import { useVaultFactory } from '@/hooks/useVaultFactory';
import { writeContract, readContract } from '@wagmi/core';
import { erc20ABI, useAccount } from "wagmi";
import { ethers } from 'ethers';


function toFixedTrunc(x: string | number, n: number) {
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (n <= 0) return v[0];
  let f = v[1] || '';
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += '0';
  return `${v[0]}.${f}`
}

export interface RedemptionRow {
  address: string;
  collateralAmount: string;
  collateralAmountHuman: string;
  collateralToken: string;
  collateralTokenSymbol: string;
  debt: string;
  debtHuman: string;
  owner: string;
  url: string;
  amount?: string | number | Decimal;
  maxReedemableHuman?: string | number | Decimal;
  price: {
    price: string;
    priceHuman: string;
  }
};

const { REDEEMABLE_VAULTS } = ENDPOINTS;

const TheRedemptionTable = () => {
  const { address } = useAccount();
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })
  const { selectedTrove, setSelectedTrove, euroBalance } = useDashboardData();
  const [selected, setSelected] = useState<RedemptionRow | null>();
  const [amount, setAmount] = useState<number | string | Decimal>('');
  const [isModalOpen, showModal] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<RedemptionRow[]>([]);
  const { valueNumber: balance = 0 } = euroBalance;
  const { VaultFactory } = useVaultFactory();

  const getCollaterals = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${API_BASE_URL}${REDEEMABLE_VAULTS}`);
      setIsLoading(false);
      setRows(res.data);
    } catch (err) {
      setIsLoading(false);
      setRows([]);
    }
  };

  useEffect(() => {
    getCollaterals();
  }, []);

  const {
    redeem,
    status,
    isLoading: isRedeeming,
    reset,
    txHash,
  } = useRedemptionLiquidation();

  useEffect(() => {
    if (status === statuses.FINISHED) {
      getCollaterals();
      setSelected(null);
      const row = rows.find(row => row.address === selected?.address);
      if (row) setRedeemAmount(row, 0);
    }
  }, [status]);

  const setRedeemAmount = (row: RedemptionRow, newAmount: string | number | Decimal) => {
    setRows(
      rows.map(item => {
        if (row.address === item.address) {
          return {
            ...item,
            amount: newAmount,
          }
        }
        return item;
      })
    );
  };

  const onClickVaultLink = (vaultAddress: string) => {
    window.location.href = `https://polygonscan.com/address/${vaultAddress}`;
  };

  const getStableAddress = async () => {
    const stableAddress = await readContract({
      address: VaultFactory!.address,
      abi: VaultFactory!.abi,
      functionName: "stable",
      args: []
    }) as string;
    return stableAddress;
  };

  const redeemCollateral = (row: RedemptionRow) => redeem(row);

  const handleClick = (id: string) => {
    const row = rows.find(row => row.address === id);
    if (row) {
      setSelected(row);
      showModal(true);
      setCallTx({ callTx: () => redeemCollateral(row) });
    }
  };

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
          [statuses.APPROVING]: {
            detail: `Approve EURO3 for redemption`,
          },
          [statuses.SIGNING]: {
            title: modalT('redeeming.title'),
            detail: `Redeeming ${selected?.collateralTokenSymbol} collateral`,
          },
          [statuses.FINISHED]: {
            detail: (
              <>
                {modalT('finished.subtitle')}<br /><ExplorerTx tx={txHash} label={txHash.slice(-6)} />
              </>
            ),
            confirmLabel: modalT('confirm.primary'),
          },
        }
        : {},
    [isModalOpen, txHash]
  );

  return (
    <>
      {isModalOpen && callTx && (
        <TheTransactionModal
          status={status}
          isOpen={isModalOpen}
          onClose={() => {
            showModal(false);
            reset();
          }}
          callTx={callTx}
          statusProps={statusProps}
        />
      )}
      <TableContainer sx={{
        maxHeight: '100vh',
        width: '100%',
        borderRadius: '16px',
        border: '1px solid rgba(145, 158, 171, 0.24)',
        mt: 3,
      }}>
        <Table
          stickyHeader
          sx={{ p: 0 }}
        >
          <TableHead>
            <TableRow sx={{
              width: '100%',
              borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
            }}>
              <Header name="Collateral" minWidth={150} />
              <Header
                name="Vault Link"
                minWidth={150}
                sx={{ maxWidth: 150 }}
              />
              {/*
              <Header
                name="EURO3"
                minWidth={150}
                hasIcon
                tooltip='EURO3'
                tooltipAlign='left'
              />
              */}
              <Header name="Redeemable Value" minWidth={150} />
              <Header name="Action" minWidth={505} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: RedemptionRow, index: number) => {
              const vaultAddress = row.url.split('/').at(-1)?.slice(0, 8) || '';
              const maxRedeemableInEuro = Number(row.maxReedemableHuman) * Number(row.price.priceHuman);
              const isLastItem = index === rows.length - 1;
              const maxValue = balance > Number(maxRedeemableInEuro) ? Number(toFixedTrunc(Number(maxRedeemableInEuro), 2)) : Number(toFixedTrunc(Number(balance), 2));
              return (
                <TableRow key={index}>
                  <BodyCell align="left" isLastItem={isLastItem}>
                    <FlexRow alignItems="center" justifyContent="flex-start">
                      <Typography variant="body_regular">
                        {row.collateralTokenSymbol}
                      </Typography>
                    </FlexRow>
                  </BodyCell>
                  <BodyCell isLastItem={isLastItem}>
                    <Link
                      onClick={() => onClickVaultLink(row.address)}
                      color="text.primary"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Icon svg={VaultLinkIcon} width='16px' height='16px' />
                      <Typography
                        variant='body_regular'
                        sx={{
                          ml: 1.5,
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {`${vaultAddress}...`}
                      </Typography>
                    </Link>
                  </BodyCell>
                  {/*
                  <BodyCell
                    isLastItem={isLastItem}
                    value={formatValue(Number(row.debtHuman))}
                  />
                  */}
                  <BodyCell
                    isLastItem={isLastItem}
                  >
                    <Typography
                      variant='body_regular'>
                      {formatValue(
                        Number(row.maxReedemableHuman),
                        { minimumFractionDigits: 2 },
                      )}&nbsp;
                      ({formatValue(Number(row.maxReedemableHuman) * Number(row.price.priceHuman),
                        { minimumFractionDigits: 2 })}&euro;)
                    </Typography>
                  </BodyCell>
                  <BodyCell
                    isLastItem={isLastItem}

                  >
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                      <InputField
                        placeholder="Enter EURO3 Amount"
                        value={row.amount}
                        setValue={val => {

                          if (Number(val) > maxValue) {
                            setRedeemAmount(row, maxValue)
                          } else if (Number(val) < 0) {
                            setRedeemAmount(row, "");
                          } else {
                            setRedeemAmount(row, val);
                          }

                        }}
                        maxValue={maxValue}
                        sx={{
                          minHeight: 40,
                          '& .MuiInputBase-root': {
                            fontSize: 12,
                            lineHeight: '18px',
                            maxHeight: 40,
                          },
                        }}
                        counterButtonStyle={{
                          py: '1.5px',
                          height: 12,
                        }}
                      />
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isRedeeming || !row.amount}
                        sx={{
                          width: 144,
                          height: 40,
                          ml: 1.25,
                          py: 1,
                          px: 2,
                          fontSize: 14,
                          lineHeight: '24px'
                        }}
                        onClick={() => handleClick(row.address)}
                      >
                        {isRedeeming && (
                          <CircularProgress
                            size={16}
                            sx={{ color: 'text.primary', mr: 1 }}
                          />
                        )}
                        Redeem
                      </Button>
                    </Box>
                    {row.amount && (
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          variant='body_regular'
                          sx={{
                            fontSize: 12,
                            lineHeight: '18px',
                            color: 'text.secondary',
                            pt: 3,
                            ml: 1,
                          }}
                        >
                          {`You get: ${toFixedTrunc(Number(row.amount) * 0.99 / Number(row.price.priceHuman), 2)} ${row.collateralTokenSymbol}`}<br />
                        </Typography>
                        <Typography
                          variant='body_regular'
                          sx={{
                            fontSize: 12,
                            lineHeight: '18px',
                            color: 'text.secondary',
                            ml: 1,
                          }}>
                          {`Redemption fee: ${toFixedTrunc(Number(row.amount) * 0.01, 2)} EURO3`}
                        </Typography>
                      </Box>
                    )}
                  </BodyCell>
                </TableRow>
              );
            })}
            {!rows.length && (
              <TableRow>
                {isLoading
                  ? (
                    <BodyCell colSpan={5}>
                      <LinearProgress
                        color='primary'
                        sx={{ borderRadius: '8px' }}
                      />
                    </BodyCell>
                  ) : (
                    <BodyCell
                      colSpan={5}
                      value='No redemptions found yet'
                      sx={{ textAlign: 'center' }}
                    />
                  )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TheRedemptionTable;
