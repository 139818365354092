type Props = {
  color?: string
}

export const VaultHfGraph = ({ color = '#B71931' }: Props) => {
  return (
    <svg width="77" height="23" viewBox="0 0 77 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.1"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 15.7391C1 15.7391 3.95644 15.2167 7.31774 16.4716C13.7107 18.8583 14.0754 14.4876 16.446 12.3802C18.6148 10.4521 20.411 11.6285 22.8259 13.9864C24.6069 15.7254 27.2025 18.6446 29.8906 18.1747C32.3478 17.7451 34.0897 14.3213 37.4956 13.4506C40.195 12.7605 42.3887 14.6564 45.4971 13.9864C49.7138 13.0775 51.5974 7.72691 54.6111 7.72691C58.3307 7.72691 60.9495 3.20154 64.3333 3.20154C67.6971 3.20154 68.1537 5.86257 71.8333 7.72691C74.6464 9.15222 76 7.72691 76 7.72691V23.0001H1V15.7391Z" fill="url(#paint0_linear_732_2919)"
      />
      <path
        d="M1 13.3668C1 13.3668 3.95644 12.852 7.31774 14.0887C13.7107 16.4409 14.0754 12.1335 16.446 10.0566C18.6148 8.1564 20.411 9.31576 22.8259 11.6395C24.6069 13.3534 27.2025 16.2303 29.8906 15.7672C32.3478 15.3438 34.0897 11.9696 37.4956 11.1115C40.195 10.4313 42.3887 12.2998 45.4971 11.6395C49.7138 10.7438 51.5974 5.47064 54.6111 5.47064C58.3307 5.47064 60.9495 1 64.3333 1C67.6971 1 67.8759 3.63329 71.5556 5.47064C74.3687 6.87532 76 5.47064 76 5.47064"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_732_2919"
          x1="14.5544"
          y1="13.2474"
          x2="14.5544"
          y2="23.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white"/>
          <stop offset="1" stop-color="white" stop-opacity="0.01"/>
        </linearGradient>
      </defs>
    </svg>
  )
}