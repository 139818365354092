import { useState } from 'react';

import TheLiquidityMiningFormCard from './TheLiquidityMiningFormCard';
import { TheTabs } from '@/components/compounds/common/TheTabs';
import { TransparentCard } from '@/components/atoms/containers/Card';

const tabs = [
  {
    hash: "liquidity",
    name: 'Provide Liquidity',
    content: <TheLiquidityMiningFormCard type="liquidity" />
  },
  {
  hash: "staking",
  name: 'LP Staking',
  content: <TheLiquidityMiningFormCard type="staking" />
  }
];

type Props = {
  type: 'liquidity' | 'staking';
  setType: (type: 'liquidity' | 'staking') => void;
}
export function TheLiquidityMiningForm({ type, setType }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(
    type ? tabs.findIndex(tab => tab.hash === type) : "liquidity"
  );

  const handleChange = (event: React.SyntheticEvent, index: number) => {
    setSelectedIndex(index);
    if (setType) setType(tabs[index]?.hash as 'liquidity' | 'staking');
  };

  return (
    <>
      <TransparentCard
        sx={{
          width: "100%",
          pt: 2,
          pb: 4,
          pl: 4,
          pr: 6,
        }} 
      >
        <TheTabs
          tabs={tabs}
          value={selectedIndex}
          onChange={handleChange}
        />
      </TransparentCard>
    </>
  );
};
