import { FunctionComponent, SVGProps, useRef } from 'react';

import { Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from "react-i18next";

import { Icon } from '@/components/atoms/data-display/icons/Icon';
import { Typography } from '@/components/atoms/data-display/typography/Typography';
import { FlexRow } from '@/components/atoms/containers/FlexRow';
import { BodyCell, Header } from '@/components/compounds/common/TheTable';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import { Vault } from '@/utils/types';
import {
  formatValue,
  getCollateralDisplayData,
  getHealthFactorValue,
} from '@/utils/utils';
import { FORMAT_CONFIGS } from '@/utils/constants';

interface Row {
  id: string
  svg?: FunctionComponent<SVGProps<SVGSVGElement>> | null
  name: string
  symbol?: string
  collateral: number
  collateralization: number,
  redemptionFactor: number
}

export function TheTrovesTable() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-troves-table-card.the-troves-table"})
  const { troveData, selectedTrove, setSelectedTrove } = useDashboardData();
  const { address: troveId } = selectedTrove || {};

  const rows = useRef<Row[]>([])
  if (troveData?.troves?.length) {
    const troves: Vault[] = troveData?.troves;
    rows.current = troves.map((trove: Vault, index): Row => {
      const { address, vaultName, liquidationFactor, tvl, redemptionFactor } = trove;

      return {
        id: address, 
        svg: null,
        name: vaultName,
        symbol: '',
        collateral: parseFloat(tvl),
        collateralization: getHealthFactorValue(liquidationFactor),
        redemptionFactor: parseFloat(redemptionFactor),
      }
    })
  }

  const handleClick = (troveId: string) => {
    setSelectedTrove(troveId);
  }

  return (
    <TableContainer sx={{
      maxHeight: 326,
      width: '100%',
      borderRadius: '16px',
      border: '1px solid rgba(145, 158, 171, 0.24)',
    }}>
      <Table 
        stickyHeader
        sx={{
          p: 0,
          "& .MuiTableRow-root:hover": {
            backgroundColor: "rgba(250, 84, 28, 0.07)",
          },
        }}
      >
        <TableHead>
          <TableRow sx={{
            width: '100%',
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
            cursor: 'pointer',
          }}>
            <Header name={ t ("name") } minWidth={252} />
            <Header name={ t ("collateral-value") } />
            <Header name={ t ("collateral-ratio") } />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.current.map((row: Row, index: number) => {
            const { tvl = '0', liquidationFactor = '0' } = selectedTrove || {};
            const isSelectedTrove = row.id === troveId;
            const collateral = isSelectedTrove ? parseFloat(tvl) : row.collateral;
            const healthFactorValue = isSelectedTrove ? getHealthFactorValue(liquidationFactor) : row.collateralization;

            return (
              <TableRow
                key={row.id}
                selected={isSelectedTrove}
                onClick={() => handleClick(row.id)}
                sx={{ cursor: 'pointer' }}
              >
                <BodyCell align="left" isLastItem={index === rows.current.length - 1}>
                  <FlexRow
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {row.svg && (
                      <Icon
                        svg={ row.svg }
                        width="20px"
                        height="20px"
                      />
                    )}
                    <Typography
                      variant="body_regular"
                      sx={{ ml: 2 }}
                    >
                      { row.name }
                    </Typography>
                    <Typography
                      variant="body_regular"
                      color="text.disabled"
                      sx={{ ml: 0.25 }}
                    >
                      { row.symbol }
                    </Typography>
                  </FlexRow>
                </BodyCell>
                <BodyCell
                  isLastItem={index === rows.current.length - 1}
                  value={formatValue(collateral, FORMAT_CONFIGS.EURO)}
                />
                <BodyCell
                  isLastItem={index === rows.current.length - 1}
                  value={formatValue(healthFactorValue, { notation: "standard" })}
                  valueProps={{
                    sx: { color: getCollateralDisplayData(healthFactorValue, row.redemptionFactor).color },
                  }}
                />
              </TableRow>
            )
          })}
          {!rows.current.length && ( 
            <TableRow>
              <BodyCell
                align="center"
                colSpan={12}
                sx={{ borderBottom: 0, minWidth: '100%' }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body_regular">
                    { t ("no-troves-found-yet") }
                  </Typography>
                </Grid>
              </BodyCell>
            </TableRow> 
          )}
        </TableBody>
      </Table>       
    </TableContainer>
  )
}