export const requestAddToken : any = async (
    symbol: 'A3A' | 'EURO3', 
    address: `0x${string}`,
    type: any = "ERC20", // Allowed values:  ERC20, ERC721, ERC1155
    decimal: number = 18,
    image: string | undefined = undefined,
  ) => {
    await window?.ethereum?.request({
      "method": "wallet_watchAsset",
      "params": {
        "type": type,
        "options": {
          "address": address,
          "symbol": symbol,
          "decimals": decimal,
          "image": image
        }
      }
    });
  };

