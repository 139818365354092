import { erc20ABI, useAccount } from "wagmi";
import { writeContract, readContract } from '@wagmi/core';
import { BigNumber, BigNumberish } from "ethers";
import { useState } from "react";

import { TX_CONFIRMATIONS, statuses } from "@/utils/constants";
import { useVaultFactory } from "./useVaultFactory";

interface IncreaseCollateralParams {
  amount: BigNumber;
  newNextTrove: string;
}

interface DecreaseCollateralParams {
  amount: BigNumber;
  newNextTrove: string;
  recipient: string;
}

const useTroveDebt = (troveAddress: `0x${string}`) => {
  const { address } = useAccount();
  const [status, setStatus] = useState(statuses.NONE);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [txHash, setTx] = useState("");

  const { VaultFactory } = useVaultFactory();

  const reset = () => {
    setStatus(statuses.NONE);
    setTx("");
  };

  const getDecimalPrecision = async () => {
    const decimalPrecision = await readContract({
      address: VaultFactory!.address,
      abi: VaultFactory!.abi,
      functionName: "DECIMAL_PRECISION",
      args: []
    }) as BigNumberish;
    return decimalPrecision.toString();
  };

  const getStableAddress = async () => {
    const stableAddress = await readContract({
      address: VaultFactory!.address,
      abi: VaultFactory!.abi,
      functionName: "stable",
      args: []
    }) as string;
    return stableAddress;
  };

  const repay = async (params: IncreaseCollateralParams) => {
    setIsLoading(true);
    const { amount } = params;
    try {
      if (amount && VaultFactory && address) {
        setStatus(statuses.APPROVING);
        const stableAddress = await getStableAddress();
        const approveWrite = await writeContract({
          address: stableAddress,
          abi: erc20ABI,
          functionName: "approve",
          mode: 'recklesslyUnprepared',
          args: [VaultFactory.address, amount],
        });
        setStatus(statuses.SIGNING);
        await approveWrite?.wait(TX_CONFIRMATIONS);
        const repayWrite = await writeContract({
          address: VaultFactory.address,
          abi: VaultFactory.abi,
          functionName: "repay",
          mode: 'recklesslyUnprepared',
          args: [troveAddress, amount],
        });
        await repayWrite?.wait(TX_CONFIRMATIONS);
        setTx(repayWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setIsError(true);
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setIsError(true);
      setStatus(statuses.ERROR);
    }
  };

  const borrow = async (params: DecreaseCollateralParams) => {
    setIsLoading(true);
    const { amount, recipient } = params;
    try {
      if (amount && VaultFactory && address) {
        setStatus(statuses.SIGNING);
        const borrowWrite = await writeContract({
          address: VaultFactory.address,
          abi: VaultFactory.abi,
          functionName: "borrow",
          mode: 'recklesslyUnprepared',
          args: [troveAddress, amount, recipient],
        });
        await borrowWrite?.wait(TX_CONFIRMATIONS);
        setTx(borrowWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setIsError(true);
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setIsError(true);
      setStatus(statuses.ERROR);
    }
  };

  return {
    repay,
    borrow,
    getDecimalPrecision,
    status,
    isLoading,
    isError,
    reset,
    txHash,
  };
};

export { useTroveDebt };
