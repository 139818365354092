import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { BodyCell, Header } from '@/components/compounds/common/TheTable';
import { TextGradient, Typography } from '@/components/atoms/data-display/typography/Typography';
import { FlexRow } from '@/components/atoms/containers/FlexRow';
import { API_BASE_URL, ENDPOINTS, FORMAT_CONFIGS, PATHS } from '@/utils/constants';
import { FlexColumn } from '@/components/atoms/containers/FlexColumn';
import { formatValue } from '@/utils/utils';
import { Icon } from '@/components/atoms/data-display/icons/Icon';

import { ReactComponent as ExternalLinkIcon } from '@/assets/icons/ic_external_link_outline.svg';
import { YieldOverviewResponse, YieldRow } from '@/utils/types';
import { generatePathName } from '@/utils/strings';
import useStaking from '@/hooks/useStaking';

const { NO_MIN_DIGITS, EURO } = FORMAT_CONFIGS;

const TheYields = () => {
  const [rows, setRows] = useState<YieldRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRewards, setTotalRewards] = useState({ data: 0 });
  const {
    liquidationReserve,
    DECIMAL_PRECISION,
    getStakingOverview
  } = useStaking("");
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {

        const data = await getStakingOverview();
        setTotalRewards({ data: parseFloat(data?.totalEuro3RewardsHuman.toString() || "0") ?? 0 });

        const res = await axios.get(`${API_BASE_URL}${ENDPOINTS.YIELDS.OVERVIEW}`);
        if (res.data) {
          const data: YieldOverviewResponse = res.data;
          const yields = Object.keys(data)
            .map(key => {
              return data[key]
            })
            .flat(1)
            .sort((a, b) => {

              return Number(b.apr) - Number(a.apr)
            });

          const sanitizedYields = yields.map(yieldItem => {

            if (Number(yieldItem.apr) > 0) {
              yieldItem.apr = Number(yieldItem.apr).toFixed(2);
            }


            return yieldItem;
          });

          setIsLoading(false);
          setRows(sanitizedYields);
        } else {
          setIsLoading(false);
          setRows([]);
        }
      } catch (err) {
        setIsLoading(false);
        setRows([]);
      }
    };

    fetchData();
  }, []);

  return (
    <FlexColumn px={11.5} pt={10} alignItems="center">
      <TableContainer sx={{
        maxHeight: '100vh',
        width: '100%',
        borderRadius: '16px',
        border: '1px solid rgba(145, 158, 171, 0.24)',
        mt: 3,
      }}>
        <Table
          stickyHeader
          sx={{ p: 0 }}
        >
          <TableHead>
            <TableRow sx={{
              width: '100%',
              borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
            }}>
              <Header name="Where" />
              <Header name="Pool" />
              <Header name="Deposit Token" />
              <Header name="Rewards" />
              <Header name="TVL" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: YieldRow, index: number) => {
              const isLast = index === rows.length - 1;
              return (
                <TableRow key={index}>
                  <BodyCell align="left" isLastItem={isLast}>
                    <FlexRow
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Link href={row.url} target="_blank" rel="noreferrer">
                        <Icon
                          svg={ExternalLinkIcon}
                          width='20px'
                          height='20px'
                        />
                      </Link>
                      <Typography
                        variant="body_regular"
                        sx={{ ml: 2 }}
                      >
                        {row.name}
                      </Typography>
                    </FlexRow>
                  </BodyCell>
                  <BodyCell
                    isLastItem={isLast}
                    value={row.pool}
                  />
                  <BodyCell
                    isLastItem={isLast}
                    value={row.depositToken}
                  />
                  <BodyCell isLastItem={isLast}>
                    <TextGradient
                      variant='body_semibold'
                      sx={{
                        backgroundImage: `linear-gradient(170deg, #1CFA75 60%, #FA541C)`,
                      }}
                    >
                      {row.name.includes('Cashback') ? `${totalRewards?.data.toFixed(2)} €` :
                      (row.name.includes('ApeBond') ? ((Number(row.arr) > 0) ? `${row.arr}%` : 'N/A') :
                        (Number(row.apr) > 0) ? `${row.apr}%` : 'N/A'
                      )}
                    </TextGradient>
                  </BodyCell>
                  <BodyCell
                    isLastItem={isLast}
                    value={
                      (row.tvl !== 'N/A') ?
                        `${formatValue(
                          Number(row.tvl),
                          {
                            ...NO_MIN_DIGITS,
                            ...EURO,
                            notation: 'compact',
                          },
                        )
                        }` : 'N/A'}
                  />
                </TableRow>
              );
            })}
            {!rows.length && (
              <TableRow>
                <BodyCell
                  align="center"
                  colSpan={12}
                  sx={{ borderBottom: 0, minWidth: '100%' }}
                >
                  {isLoading ? (
                    <LinearProgress color="primary" sx={{ borderRadius: 1 }} />
                  ) : (
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="body_regular">
                        No yields found yet
                      </Typography>
                    </Grid>
                  )}
                </BodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={5.5}
      >
        {/* <Link
          href={`${generatePathName(PATHS.YIELDS_DISCLAIMER)}`}
          target="_blank">
          <Typography variant="body_regular">Disclaimer</Typography>
        </Link> */}
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: 181,
            maxHeight: 36,
            mt: 1,
            py: 1,
            px: 2,
            fontSize: 14,
            lineHeight: '24px'
          }}
          href="/swap"
        >
          Buy EURO3
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant="caption_regular" sx={{ fontSize: 10 }}>
          <b>DISCLAIMER</b><br /><br />
          Users participating in farming should be aware that our service relies on external providers. Any issues related to the performance, security, or availability of the third-party solution are subject to the terms and conditions of the service provider. 3A shall not be held liable for any direct or indirect financial losses incurred by users in connection with their use of third-party solutions for yield generation.<br /><br />
        </Typography>
      </Box>
    </FlexColumn>
  );
};

export default TheYields;
