type Props = {
  color?: string
}

export const TwitterIcon = ({ color = '#000000' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="21px"
      viewBox="3 1 22 19"
      version="1.1"
    >
      <g id="ic:twitterx">
        <path
          d="M 5.648438 2.445312 L 12.128906 11.332031 L 5.605469 18.554688 L 7.074219 18.554688 L 12.785156 12.230469 L 17.398438 18.554688 L 22.394531 18.554688 L 15.546875 9.167969 L 21.617188 2.445312 L 20.152344 2.445312 L 14.890625 8.269531 L 10.644531 2.445312 Z M 7.804688 3.554688 L 10.101562 3.554688 L 20.234375 17.445312 L 17.941406 17.445312 Z M 7.804688 3.554688"
          fill={color}
        />
      </g>
    </svg>
  )
}