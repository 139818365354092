import { LinearProgress, Link, Tooltip } from "@mui/material";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { SYMBOLS } from "@/utils/constants";
import { useDashboardData } from "@/contexts/DashboardDataProvider";
import { requestAddToken } from "@/wagmi/utils";
import { useTranslation } from "react-i18next";
import { useAbi } from "@/hooks/useAbi";

export function TheBNQBalance() {
  const { t } = useTranslation("translation", { keyPrefix: "nav"})
  const { a3aBalance } = useDashboardData();
  const { AbiFactory: A3ATokenFactory } = useAbi({abiName: 'A3A'})
  
  return (
    <>
    <Tooltip title={t ('a3a-tooltip-description')}>
      <Link 
          component="button"
          color="inherit"
          onClick={async () => {
            if(A3ATokenFactory?.address){
              requestAddToken('A3A', A3ATokenFactory.address!)
            }
          }}>
          <Typography variant='body_regular'>
            {`${a3aBalance.valueDisplay} ${SYMBOLS.A3A}`}
          </Typography>
        </Link>
      </Tooltip>
      {a3aBalance.isLoading && <LinearProgress color="primary" />}
    </>
  );
}