import { Box } from "@mui/material";

import { TheKeepYourVaultSafeSettingsTable } from "./the-keep-your-vault-safe-card/TheKeepYourVaultSafeSettingsTable";

export function TheBotsSettings() {

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      py: 3.5,
      px: 5,
    }}>
      <TheKeepYourVaultSafeSettingsTable />
    </Box>
  );
}