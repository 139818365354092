import { Box, Link, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Decimal from "decimal.js";

import { InputField } from '@/components/atoms/inputs/inputField/InputField';
import { Button } from '@/components/atoms/inputs/button/Button';
import { CoinIcon } from '@/components/atoms/data-display/icons/Coin';
import { Dropdown, ItemProp } from '@/components/atoms/inputs/select/Dropdown';
import useLiquidityMining from '@/hooks/useLiquidityMining';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import { LiquidityPool } from '@/utils/types';
import { useAccount, useContractRead, erc20ABI } from 'wagmi';
import { bigNumberToNumber, formatValue, getContractValue } from '@/utils/utils';
import TheTransactionModal, {ExplorerTx} from '@/components/compounds/common/TheTransactionModal';
import { statuses } from '@/utils/constants';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/atoms/data-display/tooltip/Tooltip';
import { requestAddToken } from '@/wagmi/utils';
import { writeContract, readContract } from '@wagmi/core';


const MAX_DECIMAL_PLACES = 3;

type Props = {
  type: "liquidity" | "staking"
};

const maxConfig = {
  minimumFractionDigits: 0,
  notation: 'compact',
};

const TheLiquidityMiningFormCard = ({ type }: Props) => {

  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })
  const [depositAmount, setDepositAmount] = useState<number | string | Decimal>('');
  const [maxDepositAmount, setMaxDepositAmount] = useState<number>(0);
  const [withdrawAmount, setWithdrawAmount] = useState<number | string | Decimal>('');
  const [maxWithdrawAmount, setMaxWithdrawAmount] = useState<number>(0);
  const [pools, SetPools] = useState<LiquidityPool[]>([]);
  const [liquidityPairs, SetLiquidityPairs] = useState<ItemProp[]>([]);
  const [selectedPool, SetSelectedPool] = useState<LiquidityPool | undefined>(undefined);

  const { selectedTrove, euroBalance, a3aBalance, refetchBalances } = useDashboardData();
  const [isModalOpen, showModal] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });

  const {
    getBalanceOf,
    getUserBalanceOf,
    getLiquidityOverview,
    deposit,
    withdraw,
    txHash,
    status
  } = useLiquidityMining(type);

  const fetchOverview = async () => {
    try{
      const data = await getLiquidityOverview();
      const pools = data?.pools?.map(pool => { return {label: `${pool!.lpTokenSymbol}`, value: `${pool!.poolId}`} }) ?? [];
      SetPools(data?.pools??[]);
      SetLiquidityPairs(pools);
    } catch (error) {
      console.error('Error fetching overview:', error);
    }
  };

  const updateMaxDepositValue = async (pool?: LiquidityPool) => {
    let decimals; 

    if(pool){
      let tokenBalance;
      if (type == "liquidity") {
      tokenBalance = await getUserBalanceOf(pool!.depositToken);
      decimals = await readContract({
        address: pool?.depositToken!,
        abi: erc20ABI,
        functionName: 'decimals',
      });
      } else {
        tokenBalance = await getUserBalanceOf(pool!.lpToken);
        decimals = await readContract({
          address: pool?.lpToken!,
          abi: erc20ABI,
          functionName: 'decimals',
        });
      }


      const tokenBalanceValue = bigNumberToNumber(tokenBalance, parseInt(decimals.toString()));
      setMaxDepositAmount(tokenBalanceValue)
    }    
  }

  const updateMaxWithdrawValue = async (pool?: LiquidityPool) => {
    if(pool){
      const tokenBalance = await getBalanceOf(pool!.poolId, pool!.lpToken);

      setMaxWithdrawAmount(getContractValue(tokenBalance))
    }    
  }

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
            [statuses.APPROVING]: {
              detail: 
              (depositAmount)?
                `Allowance for ${depositAmount || withdrawAmount} ${((type == "liquidity")? selectedPool?.depositTokenSymbol : selectedPool?.lpTokenSymbol)}` :
                `Withdraw ${withdrawAmount} ${selectedPool?.lpTokenSymbol}`,
            },
            [statuses.SIGNING]: {
              title: `${depositAmount ? "Deposit & Stake" : "Withdraw"}`,
              detail: depositAmount
                ? `Depositing ${depositAmount} ${((type == "liquidity")? selectedPool?.depositTokenSymbol : selectedPool?.lpTokenSymbol)}`
                : (type == "staking")? `Withdrawing ${withdrawAmount} ${selectedPool?.depositTokenSymbol}` : `Unwrapping ${withdrawAmount} ${selectedPool?.lpTokenSymbol}`,
            },
            [statuses.FINISHED]: {
              detail: (
                <>
                  {modalT('finished.subtitle')}<br /><ExplorerTx tx={txHash} label={txHash.slice(-6)} />
                </>
              ),
              confirmLabel: modalT('confirm.primary'),
            },
          }
        : {},
    [isModalOpen, txHash]
  );


  const handleDeposit = () => deposit( depositAmount.toString(), selectedPool! );
  const handleWithdraw = () => withdraw(withdrawAmount.toString(), selectedPool! );

  const handleConfirm = () => {
    if (depositAmount || withdrawAmount) {
      const processTx = depositAmount ? handleDeposit : handleWithdraw;
      setCallTx({
        callTx: () =>
          processTx().finally(() => {
            setDepositAmount('');
            setWithdrawAmount('');
          })
      });
      showModal(true);
    }
  };

  useEffect(() => {
    fetchOverview();
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      {isModalOpen && callTx && (
        <TheTransactionModal
          status={status}
          isOpen={isModalOpen}
          onClose={() => {
            showModal(false);
            refetchBalances?.();
            updateMaxDepositValue(selectedPool)
            updateMaxWithdrawValue(selectedPool)
          }}
          callTx={callTx}
          statusProps={statusProps}
        />
      )}
      <Box mt={5}>
        <Dropdown
          defaultItem={{
            label: !pools?.length ? "Loading pools..." : "Select Pair",
            value: null,
          }}
          disabled={!liquidityPairs?.length}
          placeholder={!pools?.length ? "Loading pools..." : "Select Pair"}
          items={liquidityPairs}
          onSelectChanged={(liquidityPool) => {
            const pool = pools?.filter(p=>`${p!.poolId}` == liquidityPool)?.pop() ?? undefined;
            setDepositAmount('')
            setWithdrawAmount('')
            updateMaxDepositValue(pool)
            updateMaxWithdrawValue(pool)
            SetSelectedPool(pool)
          }}
          label="Select Liquidity Pair"
        />
      </Box>
      
      <Box mt={3}>
        <InputField
          disabled={!(!withdrawAmount) || !selectedPool}
          label={`Deposit ${type === 'staking' ? 'LP' : ''}`}
          caption={selectedPool ? `Max deposit: ${formatValue(maxDepositAmount, maxConfig)} ${((type == "liquidity")? selectedPool?.depositTokenSymbol : selectedPool?.lpTokenSymbol)}` : `Select liquidity pair`}
          maxValue={maxDepositAmount}
          value={depositAmount}
          setValue={setDepositAmount}
          placeholder="Enter the amount"
          captionIcon={<CoinIcon />}
          decimalPoints={MAX_DECIMAL_PLACES}
        />
      </Box>
      <Box mt={3}>
        <InputField
          disabled={!(!depositAmount) || !selectedPool}
          label={`Withdraw ${type === 'staking' ? 'LP' : ''}`}
          caption={selectedPool ? `Max withdraw: ${formatValue(maxWithdrawAmount, maxConfig)} ${selectedPool?.lpTokenSymbol}` : `Select liquidity pair`}
          maxValue={maxWithdrawAmount}
          value={withdrawAmount}
          setValue={setWithdrawAmount}
          placeholder="Enter the amount"
          captionIcon={<CoinIcon />}
          decimalPoints={MAX_DECIMAL_PLACES}
        />
      </Box>
      <Button
        disabled={(!depositAmount && !withdrawAmount) || !selectedPool || (type == "liquidity")}
        variant="contained"
        color="primary"
        onClick={handleConfirm}
        sx={{
          alignSelf: 'flex-end',
          width: '100%',
          height: '48px',
          mt: 3,
        }}
      >
        {depositAmount ? (type == "liquidity")? `Provide liquidity` : `Stake`
          : withdrawAmount ? ((type == "liquidity")? `Unwrap LP` : `Withdraw`) : `Confirm`}
      </Button>

      { selectedPool && 
        <Link 
          component="button"
          color="primary"
          sx={{
            marginTop: 2
          }}
          onClick={async () => {
            if(selectedPool){
              requestAddToken(selectedPool?.lpTokenSymbol, selectedPool?.lpToken)
            }
          }}>
          <Typography variant="body_regular">
            Add {`${selectedPool?.lpTokenSymbol}`} to your wallet
          </Typography>
        </Link>
      }
    </Box>
  );
};

export default TheLiquidityMiningFormCard;
