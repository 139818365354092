import { Box, Stack, Grid } from "@mui/material";
import { useNetwork, useSwitchNetwork } from 'wagmi'

import { Button } from "@/components/atoms/inputs/button/Button";
import { supportedChains } from "@/chains/supported";
import { Card } from "@/components/atoms/surfaces/card/Card";
import { Typography } from "@/components/atoms/data-display/typography/Typography";

import Background from '@/assets/images/background.svg';

function ChainNotSupported() {
  const { chain } = useNetwork()
  const { error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()

  return (
    <>
      <Grid 
        container
        sx={{
          height: "100vh",
          backgroundImage: `url(${Background})`,
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              "& > :not(style)": {
                padding: 3
              },
            }}
          >
            {chain && <Typography variant="h3">Connected to an unsupported chain: "{chain.name}"</Typography>}
            <Card elevation={5}>
              <Stack 
                direction={'column'} 
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="body_regular">Please switch to one of the following:</Typography>
                {supportedChains.map((x) => (
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={!switchNetwork || x.id === chain?.id}
                    key={x.id}
                    onClick={() => switchNetwork?.(x.id)}
                  >
                    {x.name}
                    {isLoading && pendingChainId === x.id && ' (switching)'}
                  </Button>
                ))}

                <Typography variant="caption_regular" color="error.main">
                  {error && error.message}
                </Typography>
              </Stack>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ChainNotSupported