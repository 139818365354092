import { useEffect, useState } from "react";
import { Box, Button, TextField, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

import TheModal from "../../common/TheModal";
import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { SwapInputData as InputData, SwapToken } from "@/utils/types";
import { FlexRow } from "@/components/atoms/containers/FlexRow";

type Props = {
  open: boolean,
  inputData: InputData,
  onClose: () => void,
  selected?: SwapToken | null,
  onSelect: (item: SwapToken, inputData: InputData) => void,
  tokens: SwapToken[],
}

const SearchTextField = styled(TextField)(({ theme }) => ({
  fontFamily: 'Public Sans',
  fontSize: 14,
  fontWeight: 500,
  marginLeft: theme.spacing(1),
  flex: 1,
  '& .MuiInputBase-root': {
    color: theme.palette.primary.main,
  },
}));

export function TheSwapTokenModal ({
  open,
  inputData,
  onClose = () => {},
  selected,
  onSelect = () => {},
  tokens = [],
}: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-swap"})

  const [keyword, setKeyword] = useState('');
  const [displayTokens, setDisplayTokens] = useState<SwapToken[]>([]);

  useEffect(() => {

    let tokenList;
    if (!keyword) {
      tokenList = tokens;
    } else {

    const filtered = tokens.filter(
      item => { 
        return (item.name.toLowerCase().includes(keyword.toLowerCase()) || 
                item.symbol.toLowerCase().includes(keyword.toLowerCase()) ||
                item.address.toLowerCase().includes(keyword.toLowerCase()))
      }
    );
    tokenList = filtered;
    }

    // put MATIC, WMATIC, USDC.e, USDC, USDT, WETH, ETH, WBTC at the top of the list
    const topTokens = ['MATIC', 'WMATIC', 'USDC_1', 'USDC', 'USDT', 'WETH', 'ETH', 'WBTC'];
    const topTokensList = tokenList.filter(item => topTokens.includes(item.symbol));
    const otherTokensList = tokenList.filter(item => !topTokens.includes(item.symbol));
    tokenList = [...topTokensList, ...otherTokensList];

    setDisplayTokens(tokenList);
  }, [keyword, tokens]);

  return (
    <TheModal open={open}>
      <>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            variant="h3"
            sx={{
              flex: 1,
              textAlign: 'center',
            }}
          >
            {t('select-token-modal-title')}
          </Typography>
          <Button
            onClick={onClose}
            sx={{
              p: 0,
              borderRadius: 10,
            }}
          >
            <CloseIcon fontSize="medium" />
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SearchIcon color="primary" sx={{ fontSize: 24 }} />
          <SearchTextField
            variant="standard"
            placeholder="Search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            inputProps={{
              sx: {
                '&::placeholder': {
                  color: 'primary.main',
                  opacity: 1,
                },
              },
            }}
          />
        </Box>
        <Box mt={3} overflow="auto">
          {!displayTokens.length && (
            <FlexRow justifyContent="center">
              <Typography variant="body_semibold">
                No tokens to display
              </Typography>
            </FlexRow>
          )}
          {displayTokens.map(item => (
            <Button
              variant="outlined"
              onClick={() => onSelect(item, inputData)}
              fullWidth
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
                p: 2,
                mb: 1,
                ...(selected?.name === item.name && {
                  backgroundColor: 'primary.main'
                }),
                '&:active': {
                  backgroundColor: 'primary.main',
                },
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              <Box
                component="img"
                src={item.logoURI}
                sx={{ width: 24, height: 24 }}
              />
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 2,
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            </Button>
          ))}
        </Box>
      </>
    </TheModal>
  )
}