import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from 'react-i18next';

import TheTroveCollateralCard from './TheTroveCollateralCard';
import TheTroveDebtCard from './TheTroveDebtCard';
import { TheTabs } from "../../../common/TheTabs";
import { TransparentCard } from "../../../../atoms/containers/Card";

export function TheTroveForm() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-trove-form"})
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabs = [
    {
      hash: "collateral",
      name: t("collateral.title"),
      content: <TheTroveCollateralCard />
    },
    {
      hash: "debt",
      name: t("debt.title"),
      content: <TheTroveDebtCard />
    }
  ];

  const handleChange = (event: React.SyntheticEvent, index: number) => {
    setSelectedIndex(index);
  };

  return (
    <TransparentCard
      sx={{
        width: "100%",
        pt: 2,
        pl: 4,
        pb: 4,
        pr: 6,
      }} 
    >
      <TheTabs
        tabs={tabs}
        value={selectedIndex}
        onChange={handleChange}
      />
    </TransparentCard>
  );
};
