import axios from 'axios';
import { useEffect, useState } from 'react';

import { API_BASE_URL } from '@/utils/constants';
import { VaultBorrowRate } from '@/utils/types';

export const useVaultBorrowRate = () => {
  const [vaultBorrowRate, setVaultBorrowRate] = useState<VaultBorrowRate>();

  useEffect(() => {
    const getVaultBorrowRate = async () => {
      const response = await axios.get(`${API_BASE_URL}abi/VaultBorrowRate`);
      setVaultBorrowRate(response.data || {});
    }
    if (!vaultBorrowRate) getVaultBorrowRate();
  }, []);

  return { VaultBorrowRate: vaultBorrowRate };
};