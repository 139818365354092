import { useEffect, useState } from 'react';
import axios from 'axios';

import { API_BASE_URL } from '@/utils/constants';
import { AbiResponse } from '@/utils/types';

export const useAbi = ({ abiName }: { abiName: string }) => {
  const [factory, setFactory] = useState<AbiResponse>();

  useEffect(() => {
    const getFactory = async () => {
      const response = await axios.get(`${API_BASE_URL}abi/${abiName}`);
      setFactory(response.data || {});
    }
    getFactory();
  }, []);

  return { AbiFactory: factory };
};
