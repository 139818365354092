import {
  Box,
  Button,
  CircularProgress,
  Link,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { Error } from '@mui/icons-material';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNetwork } from 'wagmi';

import { Typography } from '@/components/atoms/data-display/typography/Typography';
import { BonqLogoFull } from '@/components/atoms/icons';
import { local, mumbai, polygon, statuses } from '@/utils/constants';
import TheModal from './TheModal';
import { FlexColumn } from '@/components/atoms/containers/FlexColumn';

import TransactionComplete from '@/assets/images/transaction-complete.png';

let CHAIN_EXPLORER = "tx";
export const CONFIRM = "CONFIRM";

interface StatBarProps {
  steps?: (string | number)[];
  current: number;
  isFinished: boolean;
}

type BarBallProps = TypographyProps & {
  step: number;
  steps: number;
  isCurrent: boolean;
  isComplete: boolean;
}

export const ExplorerTx: FC<{ tx: string; label?: string }> = ({ tx, label = "tx" }) => (
  <Link target="_blank" rel="noreferrer" href={`${CHAIN_EXPLORER}/${tx}`}>
    {label}
  </Link>
);

const BarBall = ({ step, steps, isCurrent, isComplete, children }: PropsWithChildren<BarBallProps>) => (
  <Typography
    variant="body_regular"
    sx={{
      position: 'absolute',
      height: 24,
      width: 24,
      backgroundColor: (isComplete || isCurrent) ? 'primary.main' : 'text.disabled',
      color: 'text.primary',
      left: `${(step / steps) * 100}%`,
      borderRadius: '50%',
      textAlign: 'center',
      zIndex: 1,
      marginTop: '-12px',
    }}>
    {children}
  </Typography>
)

const StatusBar: FC<StatBarProps> = ({ steps = [1, 2], current, isFinished }) => {
  const theme = useTheme();
  const { primary } = theme.palette;

  const percentage = Number((current / (steps.length + 1)) * 100)

  return (
    <Box sx={{
      position: 'relative',
      height: '1px',
      width: '100%',
      margin: '0 auto',
      border: 'none',
      borderRadius: '16px',
      backgroundColor: 'rgba(145, 158, 171, 0.24)',
    }}>
      <Box sx={{
        height: '100%',
        borderRadius: '16px',
        width: `${Math.min(100, percentage)}%`,
        background: `linear-gradient(to right, ${primary.main}, #FA541C3D)`,
        transition: 'all 0.4s ease-out',
      }} />
      {steps.map((label, step) => (
        <BarBall
          key={"step:" + step}
          step={step + 1}
          steps={steps.length + 1}
          isCurrent={step + 1 === current}
          isComplete={(current > step + 1) || isFinished}
        >
          {label}
        </BarBall>
      ))}
    </Box>
  )
}

const LoadingCircle = () => (
  <Box sx={{
    width: 70,
    height: 70,
    borderRadius: '99px',
    bgcolor: '#F5F7FE',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 4,
  }}>
    <CircularProgress size={40} />
  </Box>
);


const ErrorCircle = () => (
  <Error
    color="error"
    sx={{
      fontSize: 99,
      mb: 4,
    }} 
  />
);

export interface StatusText {
  title?: string;
  detail?: string | JSX.Element;
  status?: number | string;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  steps?: (number | string)[];
  isLoading?: boolean;
}

const RenderStatus = ({
  title,
  detail,
  status,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  steps = [1, 2],
  isLoading,
}: StatusText) => (
  <FlexColumn>
    <FlexColumn justifyContent="center" alignItems="center">

      <Box position="relative" alignSelf="flex-start">
        <BonqLogoFull sx={{
          position: 'absolute',
          top: -16,
          left: -16,
        }}/>
      </Box>

      {status !== statuses.FINISHED ? (
        (status === statuses.ERROR && !confirmLabel)
          ? <ErrorCircle />
          : <LoadingCircle />
      ) : (
        <Box
          component="img"
          src={TransactionComplete}
          sx={{
            width: 150,
            height: 90,
            mb: 4,
          }}
        />
      )}

      <Typography
        variant="h1"
        sx={{
          mb: 1,
          fontSize: 30,
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>

      {!!detail && (
        <Typography
          variant="subheading_regular"
          sx={{
            color: 'text.disabled',
            fontSize: 18,
            textAlign: 'center',
          }}
        >
          {detail}
        </Typography>
      )}
    </FlexColumn>

    {(!confirmLabel && status !== statuses.NAVIGATING) && (
      <Box mt={6} mb={2}>
        <StatusBar
          current={status as number}
          steps={steps}
          isFinished={status === statuses.FINISHED || status === statuses.ERROR}
        />
      </Box>
    )}

    {(!!confirmLabel || !!cancelLabel) && (
      <Box sx={{
        mt: 4,
        display: "flex",
        justifyContent: confirmLabel && !cancelLabel ? 'center' : 'space-between',
      }}>
        {!!cancelLabel && (
          <Button
            variant="outlined"
            color="primary"
            sx={{ width: 102 }}
            onClick={onCancel}
            disabled={!onCancel}
          >
            {cancelLabel}
          </Button>
        )}
        {!!confirmLabel && (
          <Button
            variant="contained"
            sx={{ width: 102 }}
            onClick={onConfirm}
            disabled={!onConfirm}
          >
            {confirmLabel}
          </Button>
        )}
      </Box>
    )}
  </FlexColumn>
);

type Props = {
  status: number | string,
  isOpen: boolean;
  approvalRequired?: boolean;
  confirmed?: boolean;
  onClose?: () => void;
  callTx?: () => Promise<void>;
  statusProps: {
    [key: number | string]: StatusText | undefined;
  };
};

const TheTransactionModal = ({
  status,
  approvalRequired = true,
  confirmed = false,
  isOpen,
  onClose,
  callTx,
  statusProps,
}: Props) => {
  const { chain } = useNetwork();
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })

  useEffect(() => {
    if (chain && CHAIN_EXPLORER === "tx") {
      if (chain?.id === mumbai.id) {
        CHAIN_EXPLORER = mumbai.blockExplorers?.default.url + '/' + CHAIN_EXPLORER;
      } else if (chain?.id === polygon.id) {
        CHAIN_EXPLORER = polygon.blockExplorers?.default.url + '/' + CHAIN_EXPLORER;
      } else if (chain?.id === local.id) {
        CHAIN_EXPLORER = local.blockExplorers?.default.url + '/' + CHAIN_EXPLORER;
      }
    }
  }, [chain]);

  const [isConfirmed, setConfirmed] = useState(confirmed);
  useEffect(() => {
    if (!approvalRequired || isConfirmed) {
      callTx && callTx();
    }
  }, [isConfirmed]);

  const statusProp = useMemo(
    () =>
      isOpen
        ? {
            [CONFIRM]: {
              title: modalT('confirm.title'),
              detail: "",
              cancelLabel: modalT('confirm.secondary'),
              confirmLabel: modalT('confirm.primary')
            },
            [statuses.NONE]: {
              title: modalT('loading.title'),
              detail: "The transaction is being minted.",
            },
            ...statusProps,
            [statuses.APPROVING]: {
              title: modalT('approving.title'),
              ...statusProps[statuses.APPROVING]
            },
            [statuses.FINISHED]: {
              title: modalT('finished.title'),
              ...statusProps[statuses.FINISHED]
            },
            [statuses.ERROR]: {
              title: modalT('error.title'),
              detail: modalT('error.subtitle'),
            },
          }
        : {},
    [isOpen, statusProps]
  ) as {[key: number | string]: StatusText | undefined};

  if (!(isOpen && callTx)) return null;

  return (
    <TheModal
      open={isOpen}
      {...(!!onClose && { onClose })}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      containerProps={{
        sx: {
          height: 'auto',
        }
      }}
    >
      {!isConfirmed ? (
        <RenderStatus
          {...(statusProp[CONFIRM] as StatusText)}
          status={status}
          onConfirm={() => setConfirmed(true)}
          onCancel={onClose}
        />
      ) : (
        <RenderStatus
          {...(statusProp[status] as StatusText)}
          status={status}
          onConfirm={onClose}
        />
      )}
    </TheModal>
  );
};

export default TheTransactionModal;
