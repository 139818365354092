import { Box, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import InfoToolTip from "@/components/atoms/data-display/tooltip/InfoTooltip";
import { FlexRow, FlexRowAlignCenter } from "@/components/atoms/containers/FlexRow";
import { useDashboardData } from "@/contexts/DashboardDataProvider";
import useStabilityPool from "@/hooks/useStabilityPool";
import useStaking from "@/hooks/useStaking";
import { stakingRewards } from "@/utils/constants";
import { StakingCardProps } from "@/utils/types";
import { formatValue, getContractValue } from "@/utils/utils";
import i18next from "i18next";

const { a3a, euro3 } = stakingRewards;

export function ThePendingStakingRewards({ type } : StakingCardProps) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-staking.the-staking-rewards-card"})

  const unit = type === a3a ? euro3.toUpperCase() : a3a.toUpperCase()

  const { selectedTrove } = useDashboardData();
  const { totalA3ARewardsLeft, getDepositorA3AGain } = useStabilityPool();
  const { totalRewards, getUnpaidStableCoinGain } = useStaking(selectedTrove?.address!);

  const { pendingRewards, isLoading } = useMemo(() => {
    
    const pending = type === 'euro3' ? getContractValue(getDepositorA3AGain) : getContractValue(getUnpaidStableCoinGain);
    return {
      pendingRewards: formatValue(pending, { minimumFractionDigits: 2 }),
      isLoading: totalA3ARewardsLeft?.isLoading || totalRewards?.isLoading,
    }
  }, [getDepositorA3AGain, getUnpaidStableCoinGain]);

  return (
    <> 
      <Box mb={'10px'}>
        <FlexRow sx={{ alignItems: 'baseline' }}>
          <Typography
            variant='h3'
            sx={{
              fontWeight: 700,
              fontSize: 32,
              lineHeight: '48px',
              mr: 0.5,
            }}
          >


        { pendingRewards }

          </Typography>
          <Typography
            variant='subheading_regular'
            sx={{
              fontSize: 20,
              lineHeight: '24px',
            }}
          >
            {unit}
          </Typography>
        </FlexRow>
        {isLoading &&
          <LinearProgress color="primary" />
        }
      </Box>
      <FlexRowAlignCenter>
        <Typography
          variant='subheading_regular'
          color="text.disabled"
        >
          Pending rewards
        </Typography>
        <InfoToolTip 
          title={"Pending rewards"}
          containerProps={{
            sx: { display: 'none' }
          }}
        />
      </FlexRowAlignCenter>   
    </>
  )
}