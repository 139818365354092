import { useEffect, useMemo, useState } from "react";
import { Box, LinearProgress, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { TextGradient, Typography } from "@/components/atoms/data-display/typography/Typography";
import { TransparentCard } from "@/components/atoms/containers/Card";
import { FlexColumn } from "@/components/atoms/containers/FlexColumn";
import { FlexRowAlignCenter, FlexRowSpaceBetween } from "@/components/atoms/containers/FlexRow";
import { GraphIcon } from "@/components/atoms/data-display/icons/Graph";
import InfoToolTip from "@/components/atoms/data-display/tooltip/InfoTooltip";
import { StakingCardProps } from "@/utils/types";
import useStaking from "@/hooks/useStaking";
import { useDashboardData } from "@/contexts/DashboardDataProvider";
import useStabilityPool from "@/hooks/useStabilityPool";
import { stakingRewards } from "@/utils/constants";

const month = 'APR';
const { a3a, euro3 } = stakingRewards;

export function ThePoolShareCard({ type }: StakingCardProps) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-staking.the-pool-share-card"})
  const theme = useTheme();
  const { text } = theme.palette;

  const { selectedTrove } = useDashboardData();
  const {
    stakes,
    totalA3AStaked,
    totalStake,
    percent: PERCENT,
    DECIMAL_PRECISION,
    getStakingOverview
  } = useStaking(selectedTrove?.address as string);


  const {
    totalDeposit,
    getWithdrawableDeposit,
    DECIMAL_PRECISION: stabilityDecimalPrecision,
    getStabilityPoolOverview
  } = useStabilityPool();

  /*const percent = useMemo(() => {
    if (PERCENT?.data && DECIMAL_PRECISION?.data) {
      const decimalPrecision = DECIMAL_PRECISION?.data as string;
      return (PERCENT?.data as BigNumber)?.div(decimalPrecision)?.toNumber() * 100;
    }
    return 0;
  }, [DECIMAL_PRECISION, PERCENT, type]);*/

  const [percent, setPercent] = useState({data: 0, isLoading: true});

  const poolShare = useMemo(() => {
    if (type === 'a3a' && totalA3AStaked?.data && totalStake?.data) {
      const totalStakedBn = (totalA3AStaked.data as number);
      const totalStakeBn = (stakes.data as number);
      return totalStakedBn > 0 ? totalStakeBn*100/totalStakedBn : 0; // totalStakeBn.gt(0) ? (totalStakeBn.mul(100).div(totalStakedBn)) : BigNumber.from(0);
    }else if (type === 'euro3' && getWithdrawableDeposit?.data && totalDeposit?.data){
      const totalStakedBn = (totalDeposit.data as number);
      const totalStakeBn = (getWithdrawableDeposit.data as number);
      return totalStakedBn > 0 ? totalStakeBn*100/totalStakedBn : 0;
    }
    return 0;
  }, [
    totalA3AStaked,
    stakes,
    totalStake,
    totalDeposit,
    getWithdrawableDeposit,
  ]);

  const isLoading = useMemo(() =>
    totalA3AStaked?.isLoading || totalStake?.isLoading || PERCENT?.isLoading || DECIMAL_PRECISION?.isLoading,
    [totalA3AStaked, totalStake, PERCENT, DECIMAL_PRECISION],
  );

  useEffect( () => {
    setPercent({data: 0, isLoading: true});
    async function fetchOverview(){
      let data;
      if(type === a3a){
        data = await getStakingOverview();
      }else if(type === euro3){
        data = await getStabilityPoolOverview();
      }

      setPercent({data: data?.APR ?? 0, isLoading:!data})
    }

    fetchOverview();
  }, [type]);


  return (
    <TransparentCard>
      <FlexColumn>
        <FlexRowSpaceBetween mb={3}>
          {type === euro3 && (
          <Typography
            variant="subheading_bold"
            sx={{ flex: 1 }}>
            {`${type === a3a ? euro3.toUpperCase() : a3a.toUpperCase()} ${month}`}
          </Typography>
          )}
          {percent?.isLoading ? (
            <FlexColumn>
                <LinearProgress color="primary" />
            </FlexColumn>
          ) : (
          <Box display="flex" flexDirection="row">
            {type === euro3 && (
            <>
            <TextGradient
              variant="subheading_bold"
              sx={{ mr: 1 }}
            >
              {
                new Intl.NumberFormat(
                  i18next.language,
                  { style: "percent", minimumFractionDigits: 2 },
                ).format(percent.data/100)}
            </TextGradient>
            <GraphIcon />
            </>
            )}
          </Box>
          )}
        </FlexRowSpaceBetween>
        <FlexRowSpaceBetween mb={1}>
          <FlexRowAlignCenter>
            <Typography
              variant='body_regular'
              color={text.disabled}
            >
              { t ("pool-share") }
            </Typography>
            <InfoToolTip
              title="Pool share"
              containerProps={{ sx: { display: 'none' } }}
            />
          </FlexRowAlignCenter>
          {/* <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              variant='body_regular'
              color={text.disabled}
              sx={{ mr: 0.5 }}
            >
              More information
            </Typography>
            <Link href="#" sx={{ textDecoration: 'none' }}>
              <ExternalLinkOpenIcon />
            </Link>
          </Box> */}
        </FlexRowSpaceBetween>
        <FlexRowAlignCenter>
          <Typography variant='caption_regular' sx={{ mr: 1 }}>
            { `${new Intl.NumberFormat(i18next.language, { style: "decimal", minimumFractionDigits: 2 })
              .format(poolShare)}%` }
          </Typography>
          <LinearProgress
            variant="determinate"
            sx={{
              flex: 1,
              height: 8,
              borderRadius: 6.25,
              backgroundColor: 'rgba(250, 84, 28, 0.24)',
              '& .MuiLinearProgress-barColorPrimary': {
                borderRadius: 6.25,
              },
            }}
            value={poolShare}
          />
        </FlexRowAlignCenter>
        {isLoading &&
          <LinearProgress color="primary" sx={{ mt: 2 }} />
        }
      </FlexColumn>
    </TransparentCard>
  )
}