import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import Decimal from "decimal.js";
import { useNavigate } from 'react-router-dom';

import { TheSummaryCard } from './the-summary-card/SummaryCard';
import { Button } from '@/components/atoms/inputs/button/Button';
import { useCreateTrove } from '@/hooks/useCreateTrove';
import TheTransactionModal, { ExplorerTx } from '@/components/compounds/common/TheTransactionModal';
import { InputField } from '@/components/atoms/inputs/inputField/InputField';
import { CoinIcon } from '@/components/atoms/data-display/icons/Coin';
import { Dropdown, ItemProp } from '@/components/atoms/inputs/select/Dropdown';
import { TransparentCard } from '@/components/atoms/containers/Card';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import { FORMAT_CONFIGS, PATHS, statuses } from '@/utils/constants';
import { Collateral } from '@/utils/types';
import { MAX_DECIMAL_PRECISION, formatValue, getCollateralSvg, limitDecimalPlaces } from '@/utils/utils';
import { useCollateralTokenBalance } from '@/hooks/useCollateralTokenBalance';

const StyledButton = styled(Button)(({ theme }) => ({
  width: "172px",
  height: "48px",
  fontSize: '15px',
  lineHeight: '26px',
  py: '11px',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
}));

const formatConfig = { notation: 'standard', maximumFractionDigits: MAX_DECIMAL_PRECISION };
const MAX_DECIMAL_PLACES = 3;

export function TheCreateTroveCard() {
  const { t } = useTranslation('translation', { keyPrefix: 'the-dashboard.the-create-trove.the-create-trove-card' })
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })
  const navigate = useNavigate()

  const { address } = useAccount();
  const { troveData, refetchTrovesList, refetchBalances } = useDashboardData();

  const [collateralValue, setCollateralValue] = useState<number | string | Decimal>('');
  const [borrowingValue, setBorrowingValue] = useState<number | string | Decimal>('');
  const [selectedCollateral, setSelectedCollateral] = useState<Collateral>();
  const [isModalOpen, showModal] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });

  const { tokenBalance } = useCollateralTokenBalance({ selectedCollateral });

  let borrowingErrorMessages: string[] = [],
    collateralErrorMessages: string[] = [];
  const {
    borrowingAmount,
    borrowingSymbol,
    minBorrowingAmount,
    collateralAmount,
    minCollateralAmount,
    collateralBalance,
    collateralPrice,
    collateralSymbol,
    setBorrowingAmount,
    setCollateralAmount,
    setSelectedCollateral: setSelectedCollateralContext,
  } = troveData;

  const { borrowingTooLow, collateralTooLow, balanceNotEnough } = useMemo(() => {
    return {
      borrowingTooLow: borrowingAmount < minBorrowingAmount,
      collateralTooLow: collateralAmount < minCollateralAmount,
      balanceNotEnough: collateralBalance < collateralAmount || collateralBalance < minCollateralAmount
    }
  }, [borrowingAmount, minBorrowingAmount, collateralAmount, minCollateralAmount, collateralBalance]);

  borrowingErrorMessages = [
    borrowingTooLow ? `${formatValue(minBorrowingAmount, formatConfig)} ${borrowingSymbol} is the minimum borrowing amount allowed.` : ''
  ];
  collateralErrorMessages = [
    collateralTooLow ? `• ${formatValue(minCollateralAmount, formatConfig)} ${collateralSymbol} is the minimum deposit amount required.` : '',
    balanceNotEnough ? `• Insufficient funds. Please decrease the borrowing amount or buy more collateral.` : ''
  ];

  const maxDepositNumber = useMemo(
    () => tokenBalance?.valueNumber || 0,
    [tokenBalance],
  );

  const borrowable = useMemo(() => {
    const { mcr = 0 } = selectedCollateral || {};
    if (!collateralAmount || !collateralPrice || !mcr) return 0;

    return Math.floor(((collateralAmount * collateralPrice) / mcr) * 100);
  }, [selectedCollateral, collateralAmount, collateralPrice]);

  const collateralTokens: ItemProp[] = useMemo(() => {
    if (troveData.collaterals.length) {
      const collaterals = troveData.collaterals.map((collateral: Collateral): ItemProp => {
        const { tokenName, address } = collateral
    
        return {
          label: tokenName,
          svg: getCollateralSvg(tokenName),
          value: address,
        }
      });
      return collaterals;
    }
    return [];
  }, [troveData.collaterals]);

  const {
    createTrove,
    isLoading,
    status: createTroveStatus,
    reset: resetCreateTroveStatus,
    setStatus,
    txHash,
  } = useCreateTrove(
    troveData?.selectedCollateral?.address || troveData?.collateralAddress
  );

  const disableCreateTrove = useMemo(
    () =>
      (Number(borrowingValue) < 0) ||
        (Number(collateralValue) <= 0) ||
        !selectedCollateral ||
        balanceNotEnough ||
        isLoading,
    [
      collateralValue,
      borrowingValue,
      selectedCollateral,
      balanceNotEnough,
      isLoading,
    ],
  );

  const onCreateTrove = useCallback(() => {
    if ((!collateralValue && !borrowingAmount) || !selectedCollateral) return;
    const deposit = Number(collateralValue).toFixed(selectedCollateral.decimals);

    showModal(true);
    setCallTx({
      callTx: () =>
        createTrove({
          params: {
            recipient: address,
            nextTrove: ethers.constants.AddressZero,
            deposit: ethers.utils.parseUnits(deposit, selectedCollateral.decimals),
            borrow: ethers.utils.parseUnits(`${borrowingAmount || 0}`, selectedCollateral.decimals),
            selectedCollateral,
          },
        })
    });
  }, [
    troveData?.troves,
    collateralValue,
    borrowingAmount,
    address,
    createTrove,
    selectedCollateral,
  ]);

  const onCloseModal = async () => {
    switch (createTroveStatus) {
      case statuses.NONE:
        showModal(false);
        return;
      case statuses.APPROVING:
      case statuses.SIGNING:
      case statuses.NAVIGATING:
        return;
      case statuses.FINISHED:
        setStatus(statuses.NAVIGATING);
        resetAmounts();
        refetchBalances && refetchBalances();
        if (refetchTrovesList) {
          await refetchTrovesList();
          showModal(false);
          navigate(`/${PATHS.VAULT}`);
          resetCreateTroveStatus();
        }
        break;
      default:
        showModal(false);
        resetCreateTroveStatus();
        return;
    }
  };

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
            [statuses.APPROVING]: {
              detail: <>{`Allowance for ${collateralValue} ${selectedCollateral?.tokenName}`}</>
            },
            [statuses.SIGNING]: {
              title: modalT('creating_vault.title'),
              detail: <>{modalT('creating_vault.subtitle')}</>
            },
            [statuses.FINISHED]: {
              detail: <>{modalT('finished.subtitle')}<br /><ExplorerTx tx={txHash} label={txHash.slice(-6)} /></>,
              confirmLabel: "Confirm",
            },
            [statuses.NAVIGATING]: {
              title: "Navigating to vaults list",
              detail: ""
            },
          }
        : {},
    [
      isModalOpen,
      txHash,
      borrowingValue,
      collateralValue,
      selectedCollateral,
    ]
  );

  const { disabledDeposit, disabledBorrow } = useMemo(() => ({
    disabledDeposit: !selectedCollateral?.address,
    disabledBorrow: !selectedCollateral?.address || !collateralValue || Number(collateralValue) <= 0,
  }), [selectedCollateral, collateralValue]);

  const depositCaption = useMemo(() => {
    const { tokenName = '' } = selectedCollateral || {};
    if (!Number(collateralValue)) {
      const { valueDisplay = '0.00' } = tokenBalance || {};
      const maxDeposit = selectedCollateral ? valueDisplay : 0;
      return `Max Deposit: ${maxDeposit} ${tokenName}`;
    }

    return `${formatValue(
      Number(collateralValue) * collateralPrice,
      FORMAT_CONFIGS.EURO_WITH_MAX,
    )} ${collateralSymbol}
      `;
  }, [
    tokenBalance,
    selectedCollateral,
    collateralValue,
    collateralPrice,
    collateralSymbol,
  ]);

  const { symbol } = troveData.collateralToken || {};

  const resetAmounts = () => {
    updateDepositValue('');
    updateBorrowValue('');
  };

  const updateCollateral = async (value: string) => {
    const selected = troveData.collaterals.find(item => item.address === value);
    if (selected) {
      setSelectedCollateral(selected);
      setSelectedCollateralContext(selected);
      resetAmounts();
    }
  };

  const updateDepositValue = (amount: any) => {
    let newAmount = amount;
    if (Number(amount) > maxDepositNumber) {
      newAmount = maxDepositNumber;
    }
    newAmount = limitDecimalPlaces(newAmount, MAX_DECIMAL_PLACES);
    setCollateralValue(newAmount);
    setCollateralAmount(newAmount);

    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      updateBorrowValue('');
    }
  };

  const updateBorrowValue = (borrowingAmount: any) => {
    if (Number(borrowingAmount) > borrowable) {
      setBorrowingValue(borrowable);
      setBorrowingAmount(borrowable);
    } else {
      setBorrowingValue(borrowingAmount);
      setBorrowingAmount(parseInt(`${borrowingAmount || 0}`));
    }
  };

  return (
    <>
      <TransparentCard
        sx={{
          p: 5,
        }}
      >
        {isModalOpen && callTx && (
          <TheTransactionModal
            status={createTroveStatus}
            isOpen={isModalOpen}
            onClose={onCloseModal}
            callTx={callTx}
            statusProps={statusProps}
          />
        )}
        <Box sx={{ mb: 3 }}>
          <Dropdown
            defaultItem={{
              label: t("choose-collateral"),
              value: null,
              svg: null,
            }}
            placeholder={t("choose-collateral").toString()}
            items={collateralTokens}
            value={selectedCollateral?.address || ''}
            onSelectChanged={updateCollateral}
            label={t("collateral")}
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputField
            label={`${t("deposit")} ${symbol ? `(${symbol})` : ''}`}
            caption={depositCaption}
            disabled={disabledDeposit}
            maxValue={maxDepositNumber}
            value={collateralValue}
            setValue={updateDepositValue}
            captionIcon={<CoinIcon />}
            error={collateralTooLow || balanceNotEnough}
            errorMessages={collateralErrorMessages}
            decimalPoints={MAX_DECIMAL_PLACES}
          />
        </Box>

        <Box sx={{ mb: 4 }}>
          <InputField
            label={`${t("borrow")} ${troveData.borrowingSymbol ? `(${troveData.borrowingSymbol})` : ''}`}
            caption={`Available ${formatValue(borrowable, { minimumFractionDigits: 0 })} EURO3`}
            disabled={disabledBorrow}
            maxValue={borrowable}
            value={borrowingValue}
            setValue={updateBorrowValue}
            captionIcon={<CoinIcon />}
            error={borrowingTooLow}
            errorMessages={borrowingErrorMessages}
            disableDecimals
          />
        </Box>

        <StyledDivider />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', mb: 4 }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedCollateral(undefined)
              setSelectedCollateralContext(undefined);
              resetAmounts();
            }}
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {t("reset-button")}
          </Button>
          <StyledButton
            variant="contained"
            color="primary"
            disabled={disableCreateTrove}
            onClick={onCreateTrove}
          >
            {t("create-vault-button")}
          </StyledButton>
        </Box>

        <TheSummaryCard />
      </TransparentCard>
    </>
  )
}