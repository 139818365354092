import { Box, BoxProps, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Typography } from "../../../../atoms/data-display/typography/Typography";
import { FlexRowAlignCenter } from "../../../../atoms/containers/FlexRow";
import { ReactComponent as FileSvg } from "../../../../../assets/icons/file.svg";
import { ReactComponent as SettingsSvg } from "../../../../../assets/icons/settings.svg";
import { Icon } from "../../../../atoms/data-display/icons/Icon";

export function TheBotsExportSettings(props: BoxProps) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-bots-coming-soon-card"})

  return (
    <Box {...props}>
      <Divider sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      }} />
      <FlexRowAlignCenter
        sx={{
          justifyContent: 'space-between',
          mt: 2
        }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
        }}>
          <Icon 
            svg={FileSvg}
            width="24px" 
            height="24px"
          />
          <Typography
            variant='subheading_regular'
            sx={{ ml : 1 }}
          >
            { t ("transaction-log") }
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <Icon 
            svg={SettingsSvg} 
            width="24px" 
            height="24px" 
          />
          <Typography
            variant='subheading_regular'
            sx={{ ml : 1 }}
          >
            { t ("settings") }
          </Typography>
        </Box>
      </FlexRowAlignCenter>
    </Box>
  )
}