import axios from 'axios';
import { useEffect, useState } from 'react';

import { API_BASE_URL } from '@/utils/constants';
import { VaultFactoryHelper } from '@/utils/types';

export const useVaultFactoryHelper = () => {
  const [vaultFactoryHelper, setVaultFactoryHelper] = useState<VaultFactoryHelper>();

  useEffect(() => {
    const getVaultFactoryHelper = async () => {
      const response = await axios.get(`${API_BASE_URL}abi/VaultFactoryHelper`);
      setVaultFactoryHelper(response.data || {});
    }
    if (!vaultFactoryHelper) getVaultFactoryHelper();
  }, []);

  return { VaultFactoryHelper: vaultFactoryHelper };
};