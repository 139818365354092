import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { TheNavbar } from './the-navbar/TheNavbar';
import { TheSidebar } from './the-sidebar/TheSidebar';
import { FlexRow } from '../../atoms/containers/FlexRow';
import { FlexColumn } from '../../atoms/containers/FlexColumn';
import { DashboardDataProvider } from '../../../contexts/DashboardDataProvider';

import Background from '../../../assets/images/background.svg';

export function TheDashboard() {

  return (
    <DashboardDataProvider>
      <FlexRow
        sx={{
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right bottom',
          height: "100vh",
        }}
      >
        <Box sx={{ width: 256 }}>
          <TheSidebar />
        </Box>
        <FlexColumn>
          <TheNavbar />
          <Box sx={{
            maxHeight: "calc(100vh - 90px)",
            overflowY: "auto"
          }}> 
            <Outlet />
          </Box>
        </FlexColumn>
      </FlexRow>
    </DashboardDataProvider>
  );
}
