import { Box } from "@mui/material";

import TheStatisticsOverview from "./TheStatisticsOverview";
import TheStatisticsCollateralsTable from "./TheStatisticsCollateralsTable";

export function TheStatistics() {

  return (
    <>
      <Box sx={{ py: 3, px: 5 }}>
        <TheStatisticsOverview />
        <TheStatisticsCollateralsTable />
      </Box>
    </> 
  )
}