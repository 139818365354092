import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { ReactComponent as CoinbaseWalletSvg } from "../../../assets/icons/coinbase.svg";
import { ReactComponent as MetamaskSvg } from "../../../assets/icons/metamask.svg";
import { ReactComponent as WalletConnectSvg } from "../../../assets/icons/wallet-connect.svg";
import { Icon } from "../../atoms/data-display/icons/Icon";
import { Typography } from "../../atoms/data-display/typography/Typography";
import { Button } from "../../atoms/inputs/button/Button";

const StyledButton = styled(Button)(({ theme }) => ({
  width: '144px',
  height: '48px',
}));

type Props = {
  textColor?: string;
}

export const WalletList = ({ textColor = 'white' } : Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'walletlist'})

  const { connect, connectors } = useConnect()
  const { connector: connectedConnector, isConnected } = useAccount()
  const { disconnect } = useDisconnect()

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      rowSpacing="32px"
    >
      { connectors.map((connector) => {
          let name: string = "", svg: any
          // Wagmi connector ids
          switch (connector.id) {
            case "metaMask":
              name = t('metamask')
              svg = MetamaskSvg
              break;
            case "walletConnect":
              name = t('wallet-connect')
              svg = WalletConnectSvg
              break;
            case "coinbaseWallet":
              name = t('coinbase-wallet')
              svg = CoinbaseWalletSvg
              break;
            default:
              break;
          }
          return (
            <Fragment
              key={connector.id}
            >
              <Grid
                item 
                laptop={1} desktop={1}
              >
                <Icon svg={svg} />
              </Grid>
              <Grid
                item 
                laptop={6} desktop={6}
              >
                <Typography
                  variant="h4_bold"
                  sx={{
                    m: "0px 16px 0px 12px",
                    p: "4px 0px",
                    color: textColor,
                    fontWeight: 700,
                  }}
                >
                  { name }
                </Typography>
              </Grid>
              <Grid
                item 
                laptop={5} desktop={5}
                sx={{
                  textAlign: "center",
                  alignSelf: 'flex-end',
                }}
              >
                { isConnected
                  ? connector.name === connectedConnector?.name
                      ? <StyledButton 
                          variant="contained" 
                          color="primary"
                          disabled={!connector.ready}
                          onClick={() => disconnect()}
                        >
                          { t('disconnect') }
                        </StyledButton>
                      : <StyledButton 
                          variant="contained" 
                          color="primary"
                          disabled={!connector.ready}
                          onClick={() => {
                            disconnect()
                            connect({connector})
                          }}
                        >
                          { t('connect') }
                        </StyledButton>
                  : <StyledButton 
                      variant="contained" 
                      color="primary"
                      disabled={!connector.ready}
                      onClick={() => connect({connector})}
                    >
                      { t('connect') }
                    </StyledButton>
                }
              </Grid>
            </Fragment>
          )
        })
      }
    </Grid>
  )
}