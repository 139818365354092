import { useDashboardData } from "@/contexts/DashboardDataProvider";
import TheModal from "./TheModal";
import { StatusText } from "./TheTransactionModal";
import { useState } from "react";
import { Trove } from "@/utils/types";
import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import { SYMBOLS } from "@/utils/constants";
import { formatValue } from "@/utils/utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const { EURO3 } = SYMBOLS;

type TheSelectVaultModalProps = {
    title: string,
    detail: string,
    status: number | string,
    value?: number,
    isOpen: boolean;
    onClose?: () => void;
    onSubmit?: (selectedTrove: Trove | undefined, harvestValue: number) => void;
    statusProps: {
      [key: number | string]: StatusText | undefined;
    };
};


const TheSelectVaultModal = ({
    title,
    detail,
    status,
    value,
    isOpen,
    onClose,
    onSubmit,
    statusProps,
  }: TheSelectVaultModalProps) => {
    const { troveData, selectedTrove, setSelectedTrove } = useDashboardData();
    const [selectedOption, setSelectedOption] = useState<Trove | undefined>(undefined);
    const [ harvestValue, setHarvestValue ] = useState<number>(value ?? 0);
    const { t } = useTranslation('translation', { keyPrefix: 'modal.harvesting' })
    const { t: troveT } = useTranslation('translation', { keyPrefix: 'the-dashboard.the-trove.the-trove-overview-card' })
    const { t: navT } = useTranslation('translation', {keyPrefix: 'the-dashboard.the-navbar'})
    const navigate = useNavigate()

    const isSelectable = (trove: Trove) : boolean => {
        return Number(trove.debt ?? 0) > 0
    }

    const handleOptionChange = (e: Trove) => {
        if(isSelectable(e)){
            setSelectedOption(e);
            const debt = Number(e.debt ?? 0)
            const cashback = Number(value ?? 0)
            setHarvestValue(debt-cashback < 0 ? debt : cashback);
        }
    };

    const handleSubmit = () => {
        onSubmit?.(selectedOption, harvestValue);
    };

    function getNewDebtValue(debt: number, value: number): number {
        return (debt - value) < 0 ? 0 :  (debt - value );
    }

    return (
        <TheModal 
            open={isOpen}
            {...(!!onClose && { onClose })}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            containerProps={{
                sx: {
                    width: '50%',
                    height: 'auto',
                }
            }}
        >
            {troveData?.troves?.length ? (
                <div>
                    <Box
                        sx={{
                            maxHeight: 300,
                            overflowY: 'auto',
                            mb: 5
                        }}>
                        <Box>
                            <Typography
                                variant="h1"
                                sx={{
                                mb: 0,
                                fontSize: 30,
                                textAlign: 'center',
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                variant="h2"
                                sx={{
                                mb: 2,
                                fontSize: 24,
                                textAlign: 'center',
                                }}
                            >
                                {detail}
                            </Typography>
                        </Box>
                        <Typography
                            variant="body_regular"
                            sx={{
                                mb: 1,
                                textAlign: 'center',
                            }}
                        >
                            {t('select-vault')}
                        </Typography>
                        {troveData.troves.sort((t, tn) => Number(tn?.debt ?? 0) - Number(t?.debt ?? 0)).map((option, index) => (
                        <>
                        <Box
                            key={index}
                            onClick={() => handleOptionChange(option)}
                            className={selectedOption === option ? 'selected' : ''}
                            sx={{ 
                                p: 1, 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                justifyContent : 'space-between', 
                                cursor: (isSelectable(option) ? 'pointer' : 'not-allowed'),
                                '&:hover': {
                                   backgroundColor: (isSelectable(option) ? "rgba(250, 84, 28, 0.1)" : 'transparent'),
                                },
                            }}
                        >
                            <Box
                                sx={{ display: 'flex', flexDirection: 'column'}}
                            >
                                <Typography variant="body_bold">{option.vaultName}</Typography>
                                <Typography variant="body_regular" color={(Number(option.debt ?? 0) > 0) ? "primary" : 'grey'}>
                                {Number(option.debt ?? 0) > 0 ? <>
                                    {troveT('total-debt')}: 
                                    {selectedOption === option ?
                                    (   <>
                                        <span style={{ textDecoration: 'line-through' }}> {Number(option.debt ?? 0) > 0  && <>&cong;</> }  {`${formatValue(Number(option.debt ?? 0), { minimumFractionDigits: 0 })} ${EURO3}`}</span>
                                        <span style={{ marginLeft: 10 }}>{`${formatValue(getNewDebtValue(Number(option.debt ?? 0), value ?? 0), { minimumFractionDigits: 0 })} ${EURO3}`}</span>
                                        </>
                                    ):
                                    (
                                       <span> {Number(option.debt ?? 0) > 0  && <>&cong;</> } {`${formatValue(Number(option.debt ?? 0), { minimumFractionDigits: 0 })} ${EURO3}`}</span>
                                    )}
                                    </>: ` You need to have debt in order to harvest ${EURO3} cashback on this vault`}
                                </Typography>
                            </Box>
                            {selectedOption === option && <Chip label="Selected" color="primary" variant="filled" />}
                        </Box>
                        <Divider />
                        </>
                        ))}
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!selectedOption}
                        sx={{
                            width: "100%",
                            height: "48px",
                        }}
                    >
                        <Typography> {`Harvest ${formatValue(harvestValue ?? 0, { minimumFractionDigits: 0 })} ${EURO3}`} </Typography>
                    </Button>
                </div>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    >
                    <Typography variant="h1" gutterBottom>
                        {t('no-vault')}
                    </Typography>
                    <Typography variant="h3" gutterBottom>
                        {t('to-harvest-create-vault')}
                    </Typography>
                    <Button 
                        sx={{mt: 3}} 
                        variant="contained" 
                        color="primary"
                        onClick={() => navigate("/vault/create")}
                    >
                        {navT('create-trove')}
                    </Button>
                </Box>
            )}
            
        </TheModal>
    )
  }


export default TheSelectVaultModal