import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

import { Typography } from "../../../../atoms/data-display/typography/Typography";
import { Button } from '../../../../atoms/inputs/button/Button';
import { TransparentCard } from "../../../../atoms/containers/Card";
import { FlexRowAlignCenter, FlexRowSpaceBetween } from "../../../../atoms/containers/FlexRow";
import Bot from '../../../../../assets/images/vault_bot.png';
import { TheBotsExportSettings } from '../the-bots-export-settings/TheBotsExportSettings';
import { AntSwitch } from '../../../../atoms/inputs/toggleButton/ToggleButton';

const StyledBot = styled('img')(({ theme }) => ({
  width: 96.33,
  height: 75,
}));

type Props = BoxProps & {
  showExportSettings?: boolean;
  showToggle?: boolean;
  isToggleChecked?: boolean;
  primaryButtonText?: string;
  onClickPrimaryButton: () => void;
  titleProps?: BoxProps;
};

export function TheKeepYourVaultSafeCard({
  showExportSettings = false,
  showToggle = false,
  isToggleChecked = false,
  primaryButtonText,
  onClickPrimaryButton,
  titleProps,
  ...rest
}: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-trove-opt-card.keep-your-vault-safe"})

  return (
    <>
      <TransparentCard sx={{ p: 2, height: '100%' }} {...rest}>
        <Box sx={{ mb: 2 }}>
          <StyledBot alt="bot" src={Bot} />
        </Box>
        <FlexRowSpaceBetween
          sx={{
            mb: 0.5,
            alignItems: 'center',
          }}
          {...titleProps}
        >
          <Typography
            variant='h3'
            sx={{ fontWeight: 600 }}
          >
            { t ("title") }
          </Typography>
          {!!showToggle && <AntSwitch checked={isToggleChecked} />}
        </FlexRowSpaceBetween>
        <Box sx={{ mb: 2 }}>
          <Typography
            variant='caption_regular'
            color="text.disabled"
            sx={{ mb: 2 }}
          >
            { t ("description") }
          </Typography>
        </Box>
        {!!showExportSettings && (
          <TheBotsExportSettings my={4} />
        )}
        <FlexRowAlignCenter>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickPrimaryButton}
            sx={{
              alignSelf: 'flex-end',
              width: '100%',
              height: '48px'
            }}
          >
            <Typography>
            { primaryButtonText || t("activate") }
            </Typography>
          </Button>
        </FlexRowAlignCenter>
      </TransparentCard>
    </>
  )
}