import { Box } from '@mui/material';
import { useState } from 'react';
import { TheTabs } from '../../common/TheTabs';
import TheRedemptionTable from './TheRedemptionTable';
import TheLiquidationTable from './TheLiquidationTable';
import TheAuctionTable from './TheAuctionTable';

const tabs = [
  {
    hash: "redemption",
    name: "Redemption",
    content: <TheRedemptionTable />
  },
];

const TheRedemptionLiquidation = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={{
      py: 3,
      px: 5,
      width: '100%',
    }}>
      <TheTabs
        tabs={tabs}
        value={selectedIndex}
        onChange={handleChange}
      />
    </Box>
  );
};

export default TheRedemptionLiquidation;
