import { useAccount } from 'wagmi';
import { fetchBalance } from '@wagmi/core';
import { useEffect, useState } from 'react';

import { formatValue, isCollateralNative, isTokenNative } from '@/utils/utils';
import { Balance, Collateral, SwapToken } from '@/utils/types';

const useCollateralTokenBalance = (
  {selectedCollateral, selectedToken}:
  {selectedCollateral?: Collateral, selectedToken?: SwapToken | null}
) => {
  const { address } = useAccount();

  const [tokenBalance, setTokenBalance] = useState<Balance | null>();

  const getCollateralTokenBalance = async () => {
    try {
      const config: any = {
        address,
        ...((selectedCollateral && !isCollateralNative(selectedCollateral)) && {
          token: selectedCollateral?.address,
        }),
        ...((selectedToken && !isTokenNative(selectedToken)) && {
          token: selectedToken?.address,
        })
      }
      const response = await fetchBalance(config);
      const valueNumber = parseFloat(response.formatted || '0');
      setTokenBalance({
        ...response,
        valueNumber,
        valueDisplay: formatValue(valueNumber, { notation: 'compact' }),
      });
    } catch (error) {
      setTokenBalance(null);
    }
  };

  useEffect(() => {
    if (selectedCollateral) {
      getCollateralTokenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollateral]);

  useEffect(() => {
    if (selectedToken) {
      getCollateralTokenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToken]);

  return {
    getCollateralTokenBalance,
    tokenBalance,
  };
};

export { useCollateralTokenBalance };
