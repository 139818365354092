import { useAccount, useContractRead } from 'wagmi';

import { useAbi } from './useAbi';
import { StabilityPoolOverview, StabilityPoolRewards } from '@/utils/types';
import axios from 'axios';
import { API_BASE_URL, ENDPOINTS } from '@/utils/constants';

const useStabilityPool = () => {
  const { address } = useAccount();

  const { AbiFactory: StabilityPoolFactory } = useAbi({ abiName: 'StabilityPool' });

  const DECIMAL_PRECISION = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'DECIMAL_PRECISION',
    args: [],
    watch: true
  });

  const LIQUIDATION_RESERVE = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'LIQUIDATION_RESERVE',
    args: [],
    watch: true
  });

  const a3aToken = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'a3aToken',
    args: [],
    watch: true
  });

  const deposits = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'deposits',
    args: [address],
    watch: true
  });

  const stableCoin = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'stableCoin',
    args: [],
    watch: true
  });

  const totalDeposit = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'totalDeposit',
    args: [],
    watch: true
  });

  const totalA3ARewardsLeft = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'totalA3ARewardsLeft',
    args: [],
    watch: true
  });

  const getWithdrawableDeposit = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'getWithdrawableDeposit',
    args: [address],
    watch: true
  });

  const getDepositorA3AGain = useContractRead({
    address: StabilityPoolFactory?.address!,
    abi: StabilityPoolFactory?.abi,
    functionName: 'getDepositorA3AGain',
    args: [address],
    watch: true
  });

  const getStabilityPoolOverview = async (): Promise<StabilityPoolOverview> => {
    try {
      const response = await axios.get(`${API_BASE_URL}${ENDPOINTS.STABILITY_POOL_STAKING.OVERVIEW}`);
      return response.data;
    } catch (err) {
      return null;
    }
  }

  const getStabilityPoolRewards = async (address: `0x${string}`): Promise<StabilityPoolRewards> => {
    try {
      const response = await axios.get(`${API_BASE_URL}${ENDPOINTS.STABILITY_POOL_STAKING.REWARDS.replace(':address', address)}`);
      return response.data;
    } catch (err) {
      return null;
    }
  }

  return {
    DECIMAL_PRECISION,
    LIQUIDATION_RESERVE,
    a3aToken,
    deposits,
    stableCoin,
    totalDeposit,
    totalA3ARewardsLeft,
    getWithdrawableDeposit,
    getDepositorA3AGain,
    getStabilityPoolOverview,
    getStabilityPoolRewards
  };
};

export default useStabilityPool;
