import { useState } from 'react';
import axios from 'axios';
import { useAccount, useSigner } from 'wagmi';

import {
  API_BASE_URL,
  ENDPOINTS,
  SWAP_API_BASE_URL,
  statuses,
} from '@/utils/constants';
import {
  PricesResponse,
  SwapApiResponse,
  SwapApprovalResponse,
  SwapInputData,
  SwapPostResponse,
  SwapPricePayload,
  SwapPriceResponse,
  SwapToken,
  SwapTokenResponse,
} from '@/utils/types';
import { ethers } from 'ethers';

const { APPROVAL, HEALTH_CHECK, PRICE, SWAP, TOKENS } = ENDPOINTS.SWAP;

const useSwap = () => {
  const { address } = useAccount();
  const { data: signer } = useSigner();

  const [status, setStatus] = useState(statuses.NONE);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [txHash, setTx] = useState("");

  const reset = () => {
    setStatus(statuses.NONE);
    setIsLoading(false);
    setIsError(false);
    setTx("");
  };

  const getTokens = async () => {
    try {
      const res = await axios.get(`${SWAP_API_BASE_URL}${TOKENS}`);
      const data = res.data as SwapTokenResponse;
      return data.payload || [];
    } catch (err) {
      return [];
    }
  };

  const getHealthCheck = async () => {
    try {
      const res = await axios.get(`${SWAP_API_BASE_URL}${HEALTH_CHECK}`);
      const data = res.data as SwapApiResponse;
      return data.statusCode === 200;
    } catch (err) {
      return false;
    }
  };

  const getInitialPrices = async (): Promise<PricesResponse | null> => {
    try {
      const res = await axios.get(`${API_BASE_URL}${ENDPOINTS.PRICES}`);
      const data = res.data as PricesResponse;
      return data;
    } catch (err) {
      return null;
    }
  };

  const getTokenPrice = async (from: SwapToken, fromAmount: number, to: SwapToken): Promise<SwapPricePayload | null> => {
    try {
      const url = `${SWAP_API_BASE_URL}${PRICE}`;
      const fromAddress = from.address;
      const toAddress = to.address;

      const bnFromAmount = ethers.utils.parseUnits(fromAmount.toString(), from.decimals);
      const params = `?tokenFrom=${fromAddress}&amountFrom=${bnFromAmount.toString()}&tokenTo=${toAddress}&slippage=1`;

      const res = await axios.get(`${url}${params}`);
      const data = res.data as SwapPriceResponse;
      return data.payload;
    } catch (err) {
      return null;
    }
  };

  const getNewAmount = (data: SwapInputData) => {
    return ethers.utils.parseUnits(data.value.toString(), data.token?.decimals || 0).toString();
  };

  const fetchApproval = async (from: SwapInputData) => {
    try {
      const url = `${SWAP_API_BASE_URL}${APPROVAL}`;
      const params = `?tokenAddress=${from.token?.address}&amount=${getNewAmount(from)}`;
      const res = await axios.get(`${url}${params}`);
      const data = res.data as SwapApprovalResponse;
      return data.payload;
    } catch (err) {
      return null;
    }
  };

  const fetchSwap = async (from: SwapInputData, to: SwapInputData) => {
    try {
      const url = `${SWAP_API_BASE_URL}${SWAP}`;
      const params = `?walletFrom=${address}&tokenFrom=${from.token?.address}&tokenTo=${to.token?.address}&amount=${getNewAmount(from)}&slippage=1`;
      const res = await axios.get(`${url}${params}`);
      const data = res.data as SwapPostResponse;
      return data.payload;
    } catch (err) {
      return null;
    }
  };

  const handleSwap = async (from: SwapInputData, to: SwapInputData) => {
    setIsLoading(true);
    console.log({from, to})
    if (from.value && from.token) {
      if (from.token.name !== 'MATIC') {
        setStatus(statuses.APPROVING)
        const approvalResponse = await fetchApproval(from);
        console.log({approvalResponse})
        if (!approvalResponse) {
          setIsLoading(false);
          setStatus(statuses.ERROR);
          return;
        }
        const approveTransaction = {
          ...approvalResponse,
          from: address,
        };
        const trxResponse = await signer?.sendTransaction(approveTransaction);
        console.log({trxResponse})
      }

      setStatus(statuses.SIGNING);
      const swapResponse = await fetchSwap(from, to);
      console.log({swapResponse})
      if (!swapResponse) {
        setIsLoading(false);
        setStatus(statuses.ERROR);
        return;
      }
      const swapTxResponse = await signer?.sendTransaction(swapResponse);
      console.log({swapTxResponse})

      setIsLoading(false);
      setStatus(statuses.FINISHED);

    } else {
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  return {
    status,
    isLoading,
    setIsLoading,
    isError,
    txHash,
    reset,
    getInitialPrices,
    getTokens,
    getHealthCheck,
    getTokenPrice,
    handleSwap,
  };
};

export default useSwap;
