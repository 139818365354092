import axios from 'axios';
import { useEffect, useState } from 'react';

import { API_BASE_URL } from '@/utils/constants';
import { VaultFactory } from '@/utils/types';

export const useVaultFactory = () => {
  const [vaultFactory, setVaultFactory] = useState<VaultFactory>();

  useEffect(() => {
    const getVaultFactory = async () => {
      const response = await axios.get(`${API_BASE_URL}abi/VaultFactory`);
      setVaultFactory(response.data || {});
    }
    if (!vaultFactory) getVaultFactory();
  }, []);

  return { VaultFactory: vaultFactory };
};
