import { gql } from "@apollo/client";

export const tokensList = gql`
  query {
    tokens {
      id
      symbol
      name
      pricePoint
      priceAverage
      mcr
    }
  }
`;

export const getTokenBalance = gql`
  query getTokenBalance($walletAddress: ID!, $symbol: String!) {
    balance: getTokenBalance(walletAddress: $walletAddress, tokenSymbol: $symbol)
  }
`;

// note: wallet address must be lowercase
export const getUserTroves = gql`
  query getUserTroves($walletAddress: ID!) {
    troves(owner: $walletAddress) {
      token {
        id
        name
        symbol
        pricePoint
        mcr
      }
      id
      collateral
      debt
      isLiquidated
      arbitrageParticipation
      collateralization
    }
  }
`;

export const getProtocolState = gql`
  query {
    protocolData: getProtocolState {
      mintingFee
      bonqAddress
      bnqStaked
      bnqSupply
      beurAddress
      beurStaked
      beurSupply
    }
    bnqStakingAPR: getBONQStakingAPR
    bnqStakerCount: bonqStakingStakersCount
    beurStakerCount: stabilityPoolStakersCount
  }
`;

export const getWalletStake = gql`
  query getWalletStake($walletAddress: ID!) {
    wallet(id: $walletAddress) {
      id
      beurStake
      bonqStake
    }
  }
`;

export const getBalanceForWallet = gql`
  query getBalanceForWallet($walletAddress: ID!, $tokenAddress: String!) {
    balance: getERC20Balance(walletAddress: $walletAddress, tokenAddress: $tokenAddress)
  }
`;

export const getBonqStakingRewards = gql`
  query fetchBnqRewards($walletAddress: ID!) {
    pendingRewards: getPendingBonqStakingRewards(walletAddress: $walletAddress)
    totalRewards: totalBonqStakingRewardRedemption(walletAddress: $walletAddress)
  }
`;

export const getBeurStakingRewards = gql`
    query FetchBEURRewards($walletAddress: ID!) {
        pendingBNQRewards: pendingSPUserBNQRewards(wallet: $walletAddress)
        pendingRewards: pendingSPUserRewardsAtCurrentPrice(wallet: $walletAddress)
        totalRewards: totalStabilityPoolBonqRewardRedemption(wallet: $walletAddress)
    }
`;

export const getTroveLiquidationReserve = gql`
  query fetchLiquidationReserve($troveAddress: String!) {
    liquidationReserve: getTroveLiquidationReserve(troveAddress: $troveAddress)
  }
`;

export const getNextNewTrove = gql`
  query getTroveBoundaries($tokenAddress: ID!, $collateralizationRatio: String) {
      element: getTroveBoundaries(
          collateralToken: $tokenAddress
          collateralizationRatio: $collateralizationRatio
      ){
          nextTroveAddress
          previousTroveAddress
      }
  }
`;
