import { Box, Button, CardProps, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { BigNumber } from 'ethers';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/atoms/data-display/typography/Typography';
import { TransparentCard } from '@/components/atoms/containers/Card';
import { FlexRow, FlexRowAlignCenter } from '@/components/atoms/containers/FlexRow';
import { InfoIcon } from '@/components/atoms/data-display/icons/Info';
import { ThePendingStakingRewards } from '@/components/compounds/the-dashboard/the-staking/the-staking-rewards-card/the-pending-staking-rewards/ThePendingStakingRewards';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import useStaking from '@/hooks/useStaking';
import useStabilityPool from '@/hooks/useStabilityPool';
import { formatValue, getContractValue } from '@/utils/utils';
import TheTransactionModal, { CONFIRM, ExplorerTx } from '@/components/compounds/common/TheTransactionModal';
import { stakingRewards, statuses } from '@/utils/constants';
import useLiquidityMining from '@/hooks/useLiquidityMining';
import InfoToolTip from '@/components/atoms/data-display/tooltip/InfoTooltip';

type Props = CardProps

export function TheAllLiquidityCard({ ...rest }: Props) {
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })
  const [isModalOpen, showModal] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });

  const {
    getAllPendingRewards,
    txHash,
    status,
    harvestAll
  } = useLiquidityMining();

  const { selectedTrove } = useDashboardData();

  const {
    pendingRewards,
    pendingRewardsNum,
    pendingRewardsBn,
  } = useMemo(() => {
    const pending = getContractValue(getAllPendingRewards);
    return {
      pendingRewardsNum: pending,
      pendingRewards: formatValue(pending, { minimumFractionDigits: 0 }),
      pendingRewardsBn: getAllPendingRewards?.data as BigNumber,
    }
  }, [getAllPendingRewards]);

  const onClaim = () => {
    setCallTx({
      callTx: () => harvestAll()
    });
    showModal(true);
  };

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
          [statuses.SIGNING]: {
            title: modalT('harvesting.title'),
            detail: `Harvesting ${pendingRewards} A3A`,
          },
          [statuses.FINISHED]: {
            detail: (
              <>
                {modalT('finished.subtitle')}<br /><ExplorerTx tx={txHash} label={txHash.slice(-6)} />
              </>
            ),
            confirmLabel: modalT('confirm.primary'),
          },
        }
        : {},
    [isModalOpen, txHash]
  );

  return (
    <>
      {isModalOpen && callTx && (
        <TheTransactionModal
          status={status}
          isOpen={isModalOpen}
          onClose={() => {
            showModal(false);
            // reset();
          }}
          callTx={callTx}
          statusProps={statusProps}
        />
      )}
      <TransparentCard sx={{ p: 4, pt: 2 }} {...rest}>
        <FlexRowAlignCenter sx={{ mb: 2 }}>
          <Typography
            variant='h3'
            sx={{
              fontWeight: 600,
            }}
          >
            All Liquidity Rewards
          </Typography>
          <Tooltip
            title="Liquidity Rewards"
            placement="top"
            arrow
            sx={{ display: 'none' }}
          >
            <Box ml={1} mt={0.5}>
              <InfoIcon />
            </Box>
          </Tooltip>
        </FlexRowAlignCenter>
        <FlexRowAlignCenter
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Box mb={'10px'}>
              <FlexRow sx={{ alignItems: 'baseline' }}>
                <Typography
                  variant='h3'
                  sx={{
                    fontWeight: 700,
                    fontSize: 32,
                    lineHeight: '48px',
                    mr: 0.5,
                  }}
                >
                  {pendingRewards}
                </Typography>
                <Typography
                  variant='subheading_regular'
                  sx={{
                    fontSize: 20,
                    lineHeight: '24px',
                  }}
                >
                  {stakingRewards.a3a.toUpperCase()}
                </Typography>
              </FlexRow>
            </Box>
            <FlexRowAlignCenter>
              <Typography
                variant='subheading_regular'
                color="text.disabled"
              >
                Pending rewards
              </Typography>
              <InfoToolTip
                title={"Pending rewards"}
                containerProps={{
                  sx: { display: 'none' }
                }}
              />
            </FlexRowAlignCenter>
          </Box>
          <Button
            variant="contained"
            color="primary"
            disabled={pendingRewardsNum <= 0}
            onClick={onClaim}
            sx={{
              width: 130,
              height: 36,
            }}
          >
            <Typography>
              Claim
            </Typography>
          </Button>
        </FlexRowAlignCenter>
      </TransparentCard>
    </>
  )
}