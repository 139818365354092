import { Box, Link } from '@mui/material';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';

import { DisabledTypography, Typography } from "../../../../atoms/data-display/typography/Typography";
import { FlexRowAlignCenter } from "../../../../atoms/containers/FlexRow";
import { TransparentCard } from "../../../../atoms/containers/Card";
import { ReferralProps } from '../TheReferrals';
import { TheRewardsCollectedCard } from '../the-rewards-collected-card/TheRewardsCollectedCard';

import Nft from '@/assets/images/000.png';

const StyledNft = styled('img')(({ theme }) => ({
  width: 360.54,
  lineHeight: 'auto',
  cursor: 'pointer',
  borderRadius: '16px',
}));

export function TheNftRewardCard({ centurion }: ReferralProps) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-referrals.the-nft-rewards-card"})

  const becomeCenturionSteps = [
    '1. Double Your A3A Rewards',
    '2. Six Months of Free trial on every bot we launch',
    '3. .......',
  ];

  const howToBecomeCenturionSteps = [
    '1. Purchase it from https://blur.io/',
    '2. Partecipate in the Giveaways https://zealy.io/c/3adao/',
  ];

  const renderSteps = (steps: string[]) => (
    <Box>
      {steps.map(step => (
        <DisabledTypography key={step} variant='body_regular'>
          {step}<br />
        </DisabledTypography>
      ))}
    </Box>
  );

  return (
    <>
      <TransparentCard sx={{
        py: 2.5,
        px: 1,
        mr: 5,
      }}>
        <FlexRowAlignCenter>
          <Box sx={{
            
            borderRadius: '16px',
            pt: 0.5,
            px: 3,
          }}>
            <Link
              href="/#"
              color="inherit"
              aria-label="centurion nft"
              sx={{ lineHeight: 0 }}
            >
              <StyledNft
                alt="Centurion NFT"
                src={Nft}
                sx={{
                  ...!centurion && {
                    filter: 'blur(2px)',
                  },
                }}
              />
            </Link>
          </Box>
        </FlexRowAlignCenter>
      </TransparentCard>
      <Box position="relative" width={centurion? "100%" : 'auto'}>
        <Box width={centurion? "100%" : 'auto'}>
        {centurion ? (
          <>
                <TransparentCard minHeight={572} sx={{
        py: 3,
        px: 3,
        mb: 0,
        width: "100%",
      }}>
          <FlexRowAlignCenter sx={{ mb: 1 }}>
          <Typography
        variant="subheading_regular"
        sx={{
          color: 'primary.main',
          fontSize: 24,
          fontWeight: 600,
          lineHeight: '36px',
          whiteSpace: 'pre-wrap',
          mb: 0.75,
        }}
      >
              Rewards
            </Typography>
          </FlexRowAlignCenter>

            <DisabledTypography variant='body_regular'>
              For this phase, starting from January 31st, Centurions will receive 25% of all fees generated by referred users.<br />The rewards are not paid in A3A tokens but instead in EURO3.<br /><br />
              Additionally, Centurions will receive 2.5% of the A3A/USDC Ape Bonds purchased by the referred users (valid till 21st February).<br />
              The actions that count are:<br />
              <ul>
                <li>Minting EURO3 loans (0.5-5% fees based on the collateral).</li>
                <li>Redeeming (0.5%-2% fee).</li>
                <li>Buying Ape Bonds (2.5% of the Ape Bond purchases).</li>
              </ul>
            </DisabledTypography>
            </TransparentCard>
            </>
          ) : (<></>)}
          
          </Box>
        <FlexRowAlignCenter sx={{ mb: 1, mt: 3.5 }}>
          {!centurion || 1==1 ? (
            <>
            </>
          ) : (
            <>
            <TheRewardsCollectedCard
              mt={3.5}
              mr={2}
              width={243}
              titleBoxProps={{ mb: 1 }}
              text={"A3A Rewards collected"}
              value={"TBA"}
            />
            <TheRewardsCollectedCard
              mt={3.5}
              width={243}
              titleBoxProps={{ mb: 1 }}
              text={"EURO3 Rewards collected"}
              value={"TBA"}
            />
            </>
          )}
        </FlexRowAlignCenter>
      </Box>
    </>
  )
}
