import { assign, createMachine } from 'xstate';
import { Context, Typestate } from './types';
import { getApolloClient } from "../../../utils/utils";
import { getBeurStakingRewards, getBonqStakingRewards } from '../../../hooks/queries';
import { BeurStakingReward, BonqStakingReward } from '../../common/types';

const pollingInterval: number = Number(process.env.REACT_APP_STAKING_POLLING_INTERVAL)

export const readTotalStakingRewardsActor =
/** @xstate-layout N4IgpgJg5mDOIC5QCUwEMIGED2A7ALgE5oDG+AgmdoQJIQB0AlhADZgDEyAouQCID6mAPIA5ACrJymMQG0ADAF1EoAA7ZYjfIzzKQAD0QBGAEwBWegE4rVgOzGLNgBxXjAZgA0IAJ5Hjh+oamACwWAGzGQUF2NqGOxgC+8Z6oGDgExGSU+NR09IToWHhEpPjsEHhgTLgAbtgA1pUpheklWTkM+alFGfgIjDXYJGhaePIKY7pqGiO4ugYIpsY29LE2poYmFkGukcaePgiOrvRyp6cOctvGS4nJBWnFmVS0HffdJexghITU9CoswwAZtQALb0JoPHptF55N4tMh9AZDGZjCZIEBTTTaWbo+aLZardabba7fZGRz+ByhUyhVwWCkRVyGRJJEC4bAQOC6CHvJ7ZGHMNiTdRYnS4xBBPbeXz+akhIKXVy06n024gHnwijPXKdZqPfDC6bYuaIJmheimOlyUKGc4MskIBwrYJbGIK64RUJqjX66G5QFoRgsACu+UNopxoHmNlc5lCcgsriOoVC2zkUoOxjk5iCqccclcZg2axsQW9cN92oYsGDJBIcHg6MxMxNCBjcYTSaVqdc6YdtvNQUccQs9jCchiFlM5a6mr9EHDLfFCAAtKEHWuWfEgA */
createMachine<Context, Event, Typestate>(
{
  preserveActionOrder: true,
  predictableActionArguments: true,
  id: "ReadTotalStakingRewardsActor",
  initial: "readTotalStakingRewards",
  states: {
    readTotalStakingRewards: {
      invoke: {
        src: "readTotalStakingRewards",
        onDone: [
          {
            target: "success",
            actions: "assignTxnResult",
          },
        ],
        onError: [
          {
            target: "failure",
            actions: "assignError",
          },
        ],
      },
    },
    success: {
      after: [
        { delay: pollingInterval, target: 'readTotalStakingRewards' }
      ],
    },
    failure: {
      after: [
        { delay: pollingInterval, target: 'readTotalStakingRewards' }
      ]
    },
  },
},
{
  services: {
    readTotalStakingRewards: async (context: Context) =>
      new Promise(async (resolve, reject) => {
        try {
          const bnqResponse = await getApolloClient().query({
            query: getBonqStakingRewards,
            variables: {
              walletAddress: `${context.walletAddress?.toLowerCase()}`,
              walletId: context.walletAddress,
            },
          })
          const beurResponse = await getApolloClient().query({
            query: getBeurStakingRewards,
            variables: { walletAddress: `${context.walletAddress}` }
          })
          const bnqData: BonqStakingReward = bnqResponse.data
          const beurData: BeurStakingReward = beurResponse.data
          const txnResult = {
            bnqTotalRewards: bnqData.totalRewards,
            beurTotalRewards: beurData.totalRewards
          }
          resolve({ txnResult })
        } catch (error) {
          reject({ error })
        }
      }),
  },
  actions: {
    assignTxnResult: assign({
      txnResult: (_, event: any) => event.data.txnResult,
    }),
    assignError: assign({
      error: (_, event: any) => event.data.error,
    })
  }
});
