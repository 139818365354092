import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

type ButtonProps = Pick<
  MuiButtonProps,
  | "variant"
  | "size"
  | "color"
  | "disabled"
  | "href"
  | "sx"
  | "onClick"
  | "autoFocus"
  | "children"
>;

export const Button = ({ ...rest }: ButtonProps) => <MuiButton {...rest} />
