import { Box, TableCell, TableCellProps, TypographyProps } from '@mui/material';
import { Typography } from '../../atoms/data-display/typography/Typography';
import { InfoIcon } from '../../atoms/data-display/icons/Info';
import { FlexRow } from '../../atoms/containers/FlexRow';
import InfoToolTip from '../../atoms/data-display/tooltip/InfoTooltip';

type HeaderProps = TableCellProps & {
  name?: string | null;
  minWidth?: number;
  hasIcon?: boolean;
  bgcolor?: string;
  tooltip?: string;
  tooltipAlign?: 'left' | 'right';
};

type BodyProps = TableCellProps & {
  value?: string;
  valueProps?: TypographyProps;
  isLastItem?: boolean;
};

export const Header = ({
  name,
  minWidth = 136,
  hasIcon = false,
  bgcolor = '',
  tooltip = '',
  tooltipAlign = 'right',
  ...rest
}: HeaderProps) => (
  <TableCell
    {...rest}
    sx={{
      minWidth,
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      p: 2,
      '&.MuiTableCell-root': {
        backgroundColor: bgcolor || 'grey.900',
      },
      ...rest.sx
    }}
  >
    {!rest.children ? (
      <FlexRow sx={{
        justifyContent: rest.align || 'start',
      }}>
        {!!hasIcon && !!tooltip && tooltipAlign === 'left' && (
          <Box mr={0.5} mt={0.25}>
            <InfoToolTip
              title={tooltip || ''}
              iconColor='#919EAB'
              isSmallInfoIcon={false}
            />
          </Box>
        )}
        <Typography
          variant='body_semibold'
          sx={{ fontSize: 13 }}>
          {name}
        </Typography>
        {!!hasIcon && (!!tooltip || !!name) && tooltipAlign === 'right' && (
          <Box sx={{
            ml: 0.5,
            mt: 0.25,
          }}>
            <InfoToolTip
              title={tooltip || name || ''}
              iconColor='#919EAB'
              isSmallInfoIcon={false}
            />
          </Box>
        )}
      </FlexRow>
    ) : (
      rest.children
    )}
  </TableCell>
);

export const BodyCell = ({ value, valueProps, isLastItem = false, children, ...rest }: BodyProps) => (
  <TableCell
    {...rest}
    sx={{
      ...(!isLastItem
        ? { borderBottom: '1px solid rgba(145, 158, 171, 0.24)' }
        : { borderBottom: 0 }),
      p: 2,
      ...rest.sx
    }}
  >
    {value ? (
      <Typography variant="body_regular" {...valueProps}>
        {value}
      </Typography>
    ) : (
      children
    )}
  </TableCell>
);