import { Box, CardProps, LinearProgress, useTheme } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TextGradient, Typography } from "@/components/atoms/data-display/typography/Typography";
import { TransparentCard } from "@/components/atoms/containers/Card";
import { FlexColumn } from "@/components/atoms/containers/FlexColumn";
import { FlexRowAlignCenter, FlexRowSpaceBetween } from "@/components/atoms/containers/FlexRow";
import InfoToolTip from "@/components/atoms/data-display/tooltip/InfoTooltip";
import { LiquidityPool } from "@/utils/types";
import { GraphIcon } from "@/components/atoms/data-display/icons/Graph";
import useLiquidityMining from "@/hooks/useLiquidityMining";

type Props = CardProps & {
  pool: LiquidityPool
}
export function TheLiquidityMiningPoolShareCard({ pool, ...rest }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-staking.the-pool-share-card" })
  const theme = useTheme();

  const { text } = theme.palette;

  const [poolShare, setPoolShare] = useState<number>(0);

  const {
    StakingContract,
    poolShareLoading,
    getPoolSharePercent
  } = useLiquidityMining();

  const updatePoolShare = async () => {
    if (pool) {
      const thePoolShareValue = await getPoolSharePercent(pool!.poolId);
      setPoolShare(Number(thePoolShareValue ?? 0));
    }    
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      if (StakingContract) {
        await updatePoolShare();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [poolShare, StakingContract])

  return (
    <TransparentCard {...rest}>
      <FlexColumn>
        <FlexRowAlignCenter mb={2}>
          <Typography
            variant='body_regular'
            color={text.disabled}
          >
            {`${pool?.lpTokenSymbol}`}
          </Typography>
          <InfoToolTip
            title={`${pool?.lpTokenSymbol}`}
            containerProps={{
              sx: { display: 'none' }
            }}
          />
        </FlexRowAlignCenter>
        <FlexRowAlignCenter mb={2}>
          <Typography
            variant='body_regular'
            color={text.disabled}
          >
            {`Total deposits: ${
                new Intl.NumberFormat(
                  i18next.language,
                  { maximumFractionDigits: 2 },
                ).format((pool?.tvl ?? 0))} €`}
          </Typography>
        </FlexRowAlignCenter>
        <FlexRowSpaceBetween mb={2}>
          <Typography
            variant="subheading_bold"
            sx={{ flex: 1 }}>
            {`APR`}
          </Typography>
          <Box display="flex" flexDirection="row">
            <TextGradient
              variant="subheading_bold"
              sx={{ mr: 1 }}
            >
              {
                new Intl.NumberFormat(
                  i18next.language,
                  { style: "percent", minimumFractionDigits: 2 },
                ).format((pool?.apr ?? 0) / 100)}
            </TextGradient>
            <GraphIcon />

          </Box>
        </FlexRowSpaceBetween>
        <FlexRowSpaceBetween mb={1}>
          <FlexRowAlignCenter>
            <Typography
              variant='body_regular'
              color={text.disabled}
            >
              {t("pool-share")}
            </Typography>
            <InfoToolTip
              title="Pool share"
              containerProps={{ sx: { display: 'none' } }}
            />
          </FlexRowAlignCenter>
        </FlexRowSpaceBetween>
        {/* {!poolShareLoading && ( */}
          <FlexRowAlignCenter>
            <Typography variant='caption_regular' sx={{ mr: 1 }}>
              {`${new Intl.NumberFormat(i18next.language, { style: "decimal", minimumFractionDigits: 2 })
                .format(poolShare)}%`}
            </Typography>
            <LinearProgress
              variant="determinate"
              sx={{
                flex: 1,
                height: 8,
                borderRadius: 6.25,
                backgroundColor: 'rgba(250, 84, 28, 0.24)',
                '& .MuiLinearProgress-barColorPrimary': {
                  borderRadius: 6.25,
                },
              }}
              value={poolShare}
            />
          </FlexRowAlignCenter>
        {/* )}
        {/* {poolShareLoading &&
          <LinearProgress color="primary" sx={{ mt: 2 }} />
        } */}

      </FlexColumn>
    </TransparentCard>
  )
}