import { Box, Grid, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexRow } from '@/components/atoms/containers/FlexRow';
import TheVaultIndividualDataCard from './TheVaultIndividualDataCard';
import { useVault } from '@/hooks/useVault';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import {
  formatValue,
  getCollateralDisplayData,
  getCollateralSvg,
  getContractValue,
  getHealthFactorValue,
} from '@/utils/utils';
import useStaking from '@/hooks/useStaking';
import { FORMAT_CONFIGS, SYMBOLS } from '@/utils/constants';
import { Typography } from '@/components/atoms/data-display/typography/Typography';
import { Icon } from '@/components/atoms/data-display/icons/Icon';
import { CollateralInfo } from '@/utils/types';

const TheTroveDataSummary = () => {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-trove-overview-card"})
  const theme = useTheme();
  const { primary, error } = theme.palette;

  const {
    isVaultLoading,
    selectedTrove,
    totalEuro3Supply,
  } = useDashboardData();

  const { borrowRate } = useVault(selectedTrove)

  const { getUnpaidStableCoinGain } = useStaking(selectedTrove?.address!);

  const getVaultComposition = (collateralInfo: CollateralInfo[]) => {
    const moreThanOne = collateralInfo.length > 1;
    return collateralInfo?.map(item => {
      const size = moreThanOne ? 16 : 18;
      const fontSize = `${size}px`;
      const logoSize = moreThanOne ? '18px' : '24px';
      const gridLeft = moreThanOne ? 4 : 5;
      const gridCenter = moreThanOne ? 4 : 3;

      return (
        <Grid container justifyContent="space-between">
          <Grid
            item
            laptop={gridLeft}
            desktop={gridLeft}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Icon
              svg={getCollateralSvg(item.symbol)}
              width={logoSize}
              height={logoSize}
            />
            <Typography
              variant="subheading_regular"
              sx={{
                fontSize,
                lineHeight: `${size + 4}px`,
                whiteSpace: 'pre-wrap',
                ml: 1.5,
              }}
            >
              {formatValue(parseFloat(item.amount), { notation: 'compact' })}
            </Typography>
          </Grid>
          <Grid
            item
            laptop={gridCenter}
            desktop={gridCenter}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="subheading_regular"
              sx={{
                alignSelf: 'center',
                textAlign: 'center',
                fontSize,
                lineHeight: `${size + 4}px`,
                whiteSpace: 'pre-wrap',
              }}
            >
              {"-"}
            </Typography>
          </Grid>
          <Grid
            item
            laptop={4}
            desktop={4}
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="subheading_regular"
              sx={{
                fontSize,
                lineHeight: `${size + 4}px`,
                whiteSpace: 'pre-wrap',
              }}
            >
              {`${formatValue(parseFloat(item.valueInEur), FORMAT_CONFIGS.EURO)}`}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  }

  const {
    liquidationThreshold,
    redemptionThreshold,
    redemptionFactor,
    healthFactorValue,
    healthFactorDisplayData,
    totalDebt,
    totalCollateralData,
    vaultComposition,
  } = useMemo(() => {
    const {
      collateralInfo = [],
      tvl = '0',
      debt = '0',
      liquidationThreshold = '0',
      liquidationFactor = '0',
      redemptionThreshold = '0',
      redemptionFactor = '0',
    } = selectedTrove || {};
    getUnpaidStableCoinGain.refetch();
    const vaultComposition = getVaultComposition([...collateralInfo].reverse());
    const healthFactor = getHealthFactorValue(liquidationFactor);
    const healthFactorDisplayData = getCollateralDisplayData(healthFactor, parseFloat(redemptionFactor));

    return {
      totalCollateralData: parseFloat(tvl),
      totalDebt: parseFloat(debt),
      liquidationThreshold: parseFloat(liquidationThreshold),
      redemptionThreshold: parseFloat(redemptionThreshold),
      redemptionFactor: parseFloat(redemptionFactor),
      healthFactorValue: healthFactor,
      healthFactorDisplayData,
      vaultComposition,
    }
  }, [selectedTrove]);

  const availableCashbackData = useMemo(() => {
    const { isRefetching, data } = getUnpaidStableCoinGain || {}
    if (data && !isRefetching) {
      return getContractValue(getUnpaidStableCoinGain);
    }
    return 0;
  }, [getUnpaidStableCoinGain?.isRefetching, getUnpaidStableCoinGain?.data]);
  
  const borrowingRate = getContractValue(borrowRate);

  const symbol = SYMBOLS.EURO3;

  return (
    <Box>
      <FlexRow sx={{ mb: 3 }}>
        <TheVaultIndividualDataCard
          title={t("total-collateral")}
          isLoading={isVaultLoading}
          value={formatValue(totalCollateralData, FORMAT_CONFIGS.EURO)}
        />
        <TheVaultIndividualDataCard
          title={t("vault-composition")}
          isLoading={isVaultLoading}
          containerProps={{
            sx: {
              maxHeight: 130,
              overflowY: "auto",
            }
          }}
        >
          {vaultComposition}
        </TheVaultIndividualDataCard>
        <TheVaultIndividualDataCard
          title={t("total-debt")}
          // isLoading={isVaultLoading}
          isLoading={false}
          value={`${formatValue(totalDebt)} ${symbol}`}
        />
        <TheVaultIndividualDataCard
          title={t("available-cashback")}
          // isLoading={
          //   getUnpaidStableCoinGain?.isLoading || getUnpaidStableCoinGain?.isRefetching
          // }
          isLoading={false}
          value={`${formatValue(availableCashbackData)} ${symbol}`}
          containerProps={{
            sx: { mr: 0 }
          }}
        />
      </FlexRow>
      <FlexRow sx={{ mb: 6 }}>
        <TheVaultIndividualDataCard
          title={t("health-factor")}
          isLoading={isVaultLoading}
          value={formatValue(healthFactorValue)}
          valueProps={{
            sx: { color: healthFactorDisplayData.color },
          }}
          tooltip={`When HF hits 1 your Vault is liquidated. When HF > ${redemptionFactor} your collateral is protected from Redemptions`}
        />
        <TheVaultIndividualDataCard
          title={t("liquidation-threshold")}
          isLoading={isVaultLoading}
          value={formatValue(
            liquidationThreshold,
            FORMAT_CONFIGS.EURO,
          )}
          tooltip={`${t("liquidation-threshold-tooltip")}`}
        />
        <TheVaultIndividualDataCard
          title={`Debt Redemption Threshold`}
          isLoading={isVaultLoading}
          value={`${formatValue(redemptionThreshold)} ${symbol}`}
          tooltip={`${t("redemption-threshold-tooltip")}`}
        />
        <TheVaultIndividualDataCard
          title={t("fee")}
          isLoading={!borrowRate?.data}
          value={formatValue(borrowingRate, { style: 'percent' })}
          valueProps={{
            sx: { color: !borrowRate.data ? error.light : primary.main },
          }}
          tooltip={`${t("fee-tooltip")}`}
          containerProps={{
            sx: { mr: 0 }
          }}
        />
      </FlexRow>
    </Box>
  )
}

export default TheTroveDataSummary;
