import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BigNumber, ethers } from 'ethers';
import { FunctionComponent, SVGProps } from 'react';
import i18next from "i18next";

import { ReactComponent as ALBTSvg } from "@/assets/icons/albt.svg";
import { ReactComponent as BSvg } from "@/assets/icons/b.svg";
import { ReactComponent as DAISvg } from "@/assets/icons/dai.svg";
import { ReactComponent as ErrorSvg } from "@/assets/icons/error.svg";
import { ReactComponent as ETHSvg } from "@/assets/icons/eth.svg";
import { ReactComponent as MATICSvg } from "@/assets/icons/matic.svg";
import { ReactComponent as ShieldCheckedSvg } from "@/assets/icons/shield-checked.svg";
import { ReactComponent as USDCSvg } from "@/assets/icons/usdc.svg";
import { ReactComponent as QNTSvg } from "@/assets/icons/qnt.svg";
import { ReactComponent as PAXGSvg } from "@/assets/icons/paxg.svg";
import { ReactComponent as SUSHISvg } from "@/assets/icons/sushi.svg";
import { ReactComponent as LINKSvg } from "@/assets/icons/link.svg";
import { ReactComponent as UNISvg } from "@/assets/icons/uniswap.svg";
import { ReactComponent as USDTSvg } from "@/assets/icons/usdt.svg";

import { ContractAddresses, graphqlURI, DECIMAL_PRECISION, uiMcrList } from "./constants";
import { client } from "@/wagmi/client";
import { Collateral, SwapToken } from "./types";

export function getTroveFactoryAddress(): string {
  // @ts-ignore
  return ContractAddresses.TroveFactory[getActiveChainName()]
}

export function getBNQAddress(): string {
  // @ts-ignore
  return ContractAddresses.BNQ[getActiveChainName()]
}

export function getBEURAddress(): string {
  // @ts-ignore
  return ContractAddresses.BEUR[getActiveChainName()]
}

export function getBONQStakingAddress(): string {
  // @ts-ignore
  return ContractAddresses.BONQStaking[getActiveChainName()]
}

export function getTokenToPriceFeedAddress(): string {
  // @ts-ignore
  return ContractAddresses.TokenToPriceFeed[getActiveChainName()]
}

export function getStabilityPoolAddress(): string {
  // @ts-ignore
  return ContractAddresses.StabilityPool[getActiveChainName()]
}


export function getActiveChainName(): string | undefined {
  const chainId = client.data?.chain?.id
  const activeChains = client.chains ?? []
  const activeChain = [ ...( client.provider.chains || [] ), ...activeChains ].find((chain) => chain.id === chainId,)

  switch (activeChain?.name) {
    case "Polygon":
      return "polygon"
    case "Polygon Mumbai":
      return "mumbai"
    case "3A DAO Testnet":
      return "bonq_fork"
    default:
      return "polygon"
  }
}

export function getApolloClient() {
  // @ts-ignore
  const config = graphqlURI[getActiveChainName()];
  // @ts-ignore
  return new ApolloClient({
    cache: new InMemoryCache(),
    ...config
  });
}

export const MAX_BIGNUMBER_DECIMAL_PRECISION:number = 18
export const MAX_DECIMAL_PRECISION:number = 3

export const bigNumberToNumber = (bigNumber: BigNumber | string, precision: number = MAX_BIGNUMBER_DECIMAL_PRECISION): number =>
    Number(ethers.utils.formatUnits(bigNumber || "0", precision))

export function getCollateralSvg(symbol: string): FunctionComponent<SVGProps<SVGSVGElement>> {
  let svg:any
  switch (symbol) {
    case "MATIC":
    case "WMATIC":
      svg = MATICSvg
      break;
    case "ETH":
    case "WETH":
      svg = ETHSvg
      break;
    case "ALBT":
    case "WALBT":
      svg = ALBTSvg
      break;
    case "USDC":
      svg = USDCSvg
      break;
    case "USDC.e":
      svg = USDCSvg
      break;
    case "DAI":
      svg = DAISvg
      break;
    case "QNT":
      svg = QNTSvg
      break;
    case "PAXG":
      svg = PAXGSvg
      break;
    case "UNI":
      svg = UNISvg
      break;
    case "USDT":
      svg = USDTSvg
      break;
    case "LINK":
      svg = LINKSvg
      break;
    case "SUSHI":
      svg = SUSHISvg
      break;
    default:
      svg = BSvg
      break;
  }
  return svg
}

export function getCollateralMBCR(symbol: string): number {
  let minimumBCR: number
  switch (symbol) {
    case "MATIC":
    case "WMATIC":
    case "ETH":
    case "WETH":
      minimumBCR = 2
      break;
    case "ALBT":
    case "WALBT":
      minimumBCR = 5
      break;
    case "USDC":
    case "DAI":
      minimumBCR = 1.25
      break;
    default:
      minimumBCR = 2
      break;
  }

  return minimumBCR
}

export interface TroveRiskResult {
  level: string
  color: string
  svg?: FunctionComponent<SVGProps<SVGSVGElement>> 
}

export function getTroveRisk(collateralMBCR: number, collateralSafeguard: number, collateralRatio: number): TroveRiskResult {
  let level:string = "",
      color:string = "", 
      svg: FunctionComponent<SVGProps<SVGSVGElement>>
  if(collateralRatio < (collateralMBCR * (1 + collateralSafeguard))){
    level = "Higher"
    color = "error.light"
    svg = ErrorSvg
  } else {
    level = "Lower"
    color = "success.main"
    svg = ShieldCheckedSvg
  }

  return {
    level, 
    color, 
    svg
  }
}

export function getArrakisCollaterals(){
  const arrakisTokens = [
    {
      id: "0x388E289A1705fa7b8808AB13f0e0f865E2Ff94eE",
      svg: getCollateralSvg(""),
      name: "Arrakis",
      symbol: "USDC/BEUR LP Tokens",
      pricePoint: 0,
      mcr: 1.05,
      mbcr: 1.25
    },
    {
      id: "0xA1Dd21527c76BB1A3b667149e741A8B0f445FaE2",
      svg: getCollateralSvg(""),
      name: "Arrakis",
      symbol: "DAI/BEUR LP Tokens",
      pricePoint: 0,
      mcr: 1.05,
      mbcr: 1.25
    }
  ]
  
  return arrakisTokens
}

export function getUiMcr(symbol: string): string {
  // @ts-ignore
  const uiMcr = uiMcrList[symbol] || "500"
  return BigNumber.from(uiMcr).mul(DECIMAL_PRECISION).div(100).toString()
}

export const getContractValue = (data: any) => {
  if (BigNumber.isBigNumber(data)) {
    return bigNumberToNumber(BigNumber.from(data));
  }
  if (data?.isSuccess && BigNumber.isBigNumber(data?.data))
    return bigNumberToNumber(BigNumber.from(data?.data));
  return 0;
};

export const formatValue = (value: number, otherConfig?: any) => {
  return new Intl.NumberFormat(i18next.language, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...otherConfig,
  }).format(value);
};

export const isCollateralNative = (collateral: Collateral) => {
  return collateral.tokenName.includes('WMATIC');
};

export const isTokenNative = (token?: SwapToken) => {
  return token?.symbol === 'MATIC';
};

export const getHealthFactorValue = (healthFactor = '0') => {
  const healthFactorValue = parseFloat(healthFactor);

  return (healthFactorValue > 100) ? 100 : healthFactorValue;
};

export const limitDecimalPlaces = (amount: any, decimals?: number) => {
  let newAmount = amount;
  if (amount) {
    const splitAmount = `${amount}`.split('.', 2);
    if (splitAmount.length > 1) {
      newAmount = `${splitAmount[0]}.${splitAmount[1].slice(0, decimals || 18)}`;
    }
  }
  return newAmount;
}

export const getCollateralDisplayData = (collateralRatio: number, redemptionFactor : number) => {
  let color = 'success.main';

  if (collateralRatio <= 1.1) {
    color = 'error.main';
  } else if (collateralRatio > 1.1 && collateralRatio < redemptionFactor) {
    color = 'warning.main';
  }
  return { color };
};
