import { BigNumber } from 'ethers';
import { useState } from 'react';
import { erc20ABI, useAccount, useContractRead } from 'wagmi';
import { readContract, writeContract, fetchBalance } from '@wagmi/core';

import {
  bigNumberToNumber,
  getTroveFactoryAddress,
  isCollateralNative,
} from '@/utils/utils';
import { DECIMAL_PRECISION, TX_CONFIRMATIONS, statuses } from '@/utils/constants';
import { Collateral, Trove } from '@/utils/types';
import { useVaultFactory } from './useVaultFactory';
import { useAbi } from './useAbi';

interface CreateVaultValues {
  deposit: BigNumber;
  borrow: BigNumber;
  recipient?: string;
  nextTrove?: string;
  selectedCollateral: Collateral;
}
interface CreateVaultParams {
  params: CreateVaultValues;
}

const useCreateTrove = (tokenAddress: string | `0x${string}`) => {
  const troveFactoryAddress = getTroveFactoryAddress() as `0x${string}`;

  const { address } = useAccount();

  const { VaultFactory } = useVaultFactory();
  const { AbiFactory: TokenToPriceFeedFactory } = useAbi({ abiName: 'TokenToPriceFeed' });
  const { AbiFactory: VaultFactoryZapper } = useAbi({ abiName: 'VaultFactoryZapper' });

  const [status, setStatus] = useState(statuses.NONE);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [txHash, setTxHash] = useState<string>('');

  const reset = () => {
    setStatus(statuses.NONE);
  };

  const tokenAllowance = useContractRead({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [address as `0x${string}`, troveFactoryAddress]
  });

  const tokenDecimals = useContractRead({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'decimals',
  });

  const deposit = async (params: CreateVaultValues, vaultAddress: `0x${string}`) => {
    const precision = BigNumber.from(10).pow(tokenDecimals.data?.toString() || '0')
    params.deposit = params.deposit.mul(precision).div(DECIMAL_PRECISION)

    if (params.deposit.gt(tokenAllowance.data || '0')) {
      if (isCollateralNative(params.selectedCollateral)) {
        const addCollateralRes = await writeContract({
          address: VaultFactory!.address,
          abi: VaultFactory!.abi,
          functionName: 'addCollateralNative',
          args: [vaultAddress],
          mode: 'recklesslyUnprepared',
          overrides: {
            value: params.deposit
          }
        });
        setTxHash(addCollateralRes.hash);
      } else {
        const addCollateralRes = await writeContract({
          address: VaultFactory!.address,
          abi: VaultFactory!.abi,
          functionName: 'addCollateral',
          mode: 'recklesslyUnprepared',
          args: [vaultAddress, params.selectedCollateral.address, params.deposit],
        });
        setTxHash(addCollateralRes.hash);
      }
      return statuses.FINISHED;
    }
    return statuses.ERROR;
  };

  const borrow = async (params: CreateVaultValues, vaultAddress: `0x${string}`) => {
    const borrowRes = await writeContract({
      address: VaultFactory!.address,
      abi: VaultFactory!.abi,
      functionName: 'borrow',
      mode: 'recklesslyUnprepared',
      args: [vaultAddress, params.borrow, address],
    })
    return statuses.FINISHED;
  }

  const createTrove = async ({params}: CreateVaultParams) => {
    setIsLoading(true);
    const { deposit: depositAmount, borrow: borrowAmount, selectedCollateral } = params;
    setTxHash('');
    try {
      if (VaultFactoryZapper) {
        if (isCollateralNative(selectedCollateral)) {
          setStatus(statuses.SIGNING);
          const config: any = {
            address: VaultFactoryZapper.address!,
            abi: VaultFactoryZapper.abi,
            functionName: 'createVaultNative',
            mode: 'recklesslyUnprepared',
            args: [borrowAmount],
            overrides: {
              value: depositAmount,
            }
          };
          const createVaultRes = await writeContract({...config})
          setTxHash(createVaultRes.hash);
          await createVaultRes?.wait(TX_CONFIRMATIONS);
        } else {
          setStatus(statuses.APPROVING);
          const approve = await writeContract({
            address: selectedCollateral.address,
            abi: erc20ABI,
            functionName: "approve",
            mode: 'recklesslyUnprepared',
            args: [VaultFactoryZapper.address!, depositAmount],
          });
          setStatus(statuses.SIGNING);
          await approve?.wait(TX_CONFIRMATIONS);

          const config: any = {
            address: VaultFactoryZapper.address!,
            abi: VaultFactoryZapper.abi,
            functionName: 'createVault',
            mode: 'recklesslyUnprepared',
            args: [selectedCollateral.address, depositAmount, borrowAmount],
          };
          const createVaultRes = await writeContract({...config})
        
          await createVaultRes?.wait(TX_CONFIRMATIONS);
          setTxHash(createVaultRes.hash);
        }


        setStatus(statuses.FINISHED);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsError(true);
        setStatus(statuses.ERROR);
        return;
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false);
      setIsError(true);
      setStatus(statuses.ERROR);
    }
  };

  const getCollateralPrice = async (selectedCollateral: Collateral): Promise<number> => {
    try {
      if (TokenToPriceFeedFactory) {
        const collateralPriceRes: BigNumber = await readContract({
          address: TokenToPriceFeedFactory!.address!,
          abi: TokenToPriceFeedFactory!.abi,
          functionName: 'tokenPrice',
          args: [selectedCollateral.address]
        }) as BigNumber;
        return bigNumberToNumber(collateralPriceRes);
      }
      return 0;
    } catch (error) {
      return 0;
    }
  };

  const getCollateralBalance = async (selectedCollateral: Collateral): Promise<number> => {
    try {
      const config: any = {
        token: selectedCollateral.address,
        address,
      };
      const txnResult = await fetchBalance(config);
      return parseInt(txnResult.formatted);
    } catch (error) {
      return 0;
    }
  };

  return {
    createTrove,
    getCollateralPrice,
    getCollateralBalance,
    status,
    setStatus,
    isLoading,
    isError,
    reset,
    txHash,
  };
};

export { useCreateTrove };
