import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Decimal from "decimal.js";
import { BigNumber, constants, ethers } from 'ethers';
import { useAccount } from 'wagmi';

import { InputField } from '@/components/atoms/inputs/inputField/InputField';
import { Button } from '@/components/atoms/inputs/button/Button';
import TheTransactionModal, { ExplorerTx } from '@/components/compounds/common/TheTransactionModal';
import { statuses, SYMBOLS } from '@/utils/constants';
import { useTroveDebt } from '@/hooks/useTroveDebt';
import { CoinIcon } from '@/components/atoms/data-display/icons/Coin';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import { formatValue } from '@/utils/utils';

const { EURO3: symbol } = SYMBOLS;

const decimalConfig = {
  minimumFractionDigits: 0,
  notation: 'compact',
};

const TheTroveDebtCard = () => {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-trove-form.debt"})
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })
  const [borrowAmount, setBorrow] = useState<number | string | Decimal>('');
  const [repayAmount, setRepay] = useState<number | string | Decimal>('');
  const [isModalOpen, showModal] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });
  const { address: recipient } = useAccount();
  const {
    selectedTrove,
    euroBalance,
    refetchBalances,
    refetchTroveData,
  } = useDashboardData();

  const { repay, borrow, status, isLoading, reset, txHash } =
    useTroveDebt(selectedTrove?.address as `0x${string}`);

  const { maxRepay, maxBorrow } = useMemo(() => {
    const { borrowable = '0', debt = '0' } = selectedTrove || {};

    console.log("borrowable: ", borrowable);
    console.log("debt: ", debt);

    const balanceBn = euroBalance.value;
    const borrowableBn = ethers.utils.parseEther(borrowable);
    const debtBn = ethers.utils.parseEther(debt);

    const maxRepayAmount = balanceBn.gte(debtBn) ? debt : ethers.utils.formatEther(balanceBn);

    return {
      maxRepay: maxRepayAmount,
      maxBorrow: ethers.utils.formatEther(borrowableBn),
    }
  }, [euroBalance.formatted, selectedTrove]);

  const handleBorrow = () => {
    return borrow({
      amount: ethers.utils.parseEther(borrowAmount.toString()),
      recipient: recipient as string,
      newNextTrove: constants.AddressZero
    }
  )};

  const handleRepay = () =>
    repay({
      amount: ethers.utils.parseEther(repayAmount.toString()),
      newNextTrove: constants.AddressZero
    });

  const handleConfirm = () => {
    if (repayAmount || (borrowAmount && recipient)) {
      const processTx = repayAmount ? handleRepay : handleBorrow;
      setCallTx({ callTx: () => processTx() });
      showModal(true);
    }
  };

  useEffect(() => {
    if (status === statuses.FINISHED) {
      refetchBalances && refetchBalances();
      refetchTroveData && refetchTroveData();
      setRepay('');
      setBorrow('');
    }
  }, [status]);

  const onCloseModal = () => {
    showModal(false);
    reset();
  };

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
            [statuses.APPROVING]: {
              detail: `Allowance for ${borrowAmount || repayAmount} ${symbol}`
            },
            [statuses.SIGNING]: {
              title: `${borrowAmount ? "Increasing" : "Decreasing"} your debt`,
              detail: borrowAmount
                ? `Minting ${borrowAmount} ${symbol}`
                : `Repaying ${repayAmount} ${symbol}`
            },
            [statuses.FINISHED]: {
              detail: (
                <>
                  {modalT('finished.subtitle')} <ExplorerTx tx={txHash} label={txHash.slice(-6)} />
                </>
              ),
              confirmLabel: modalT('confirm.primary'),
            },
          }
        : {},
    [isModalOpen, txHash]
  )

  const disabledButton = useMemo(() =>
    (Number(borrowAmount) <= 0 && Number(repayAmount) <= 0) || isLoading,
    [borrowAmount, repayAmount, isLoading],
  );

  const updateBorrowValue = (val: any) => {
    setBorrow(val);
    setRepay('');
  };

  const updateRepayValue = (val: any) => {
    setRepay(val);
    setBorrow('');
  };

  return (
    <Box display="flex" flexDirection="column">
      {isModalOpen && callTx && (
        <TheTransactionModal
          status={status}
          isOpen={isModalOpen}
          onClose={onCloseModal}
          callTx={callTx}
          statusProps={statusProps}
        />
      )}
      <Box mt={4}>
        <InputField
          inputLabel={t("borrow.title")}
          caption={`${t("borrow.hint")} ${maxBorrow} ${symbol}`}
          captionIcon={<CoinIcon />}
          maxValue={maxBorrow}
          value={borrowAmount}
          setValue={updateBorrowValue}
          disabled={Number(repayAmount) > 0}
          disableDecimals
        />
      </Box>
      <Box mt={4}>
        <InputField
          inputLabel={t("repay.title")}
          caption={`${t("repay.hint")} ${maxRepay} ${symbol}`}
          captionIcon={<CoinIcon />}
          maxValue={maxRepay}
          value={repayAmount}
          setValue={updateRepayValue}
          disabled={Number(borrowAmount) > 0}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{
          width: '100%',
          my: 4,
        }}
        disabled={disabledButton}
        onClick={handleConfirm}
      >
        {t("confirm")}
      </Button>
    </Box>
  );
};

export default TheTroveDebtCard;
