import { Box, Grid, Link, Paper } from "@mui/material";

import { TheReferralLinkCard } from "./the-referral-link-card/TheReferralLinkCard";
import { TheActivatedReferralsCard } from "./the-activated-referrals-card/TheActivatedReferralsCard";
import { TheMintedEuro3Card } from "./the-minted-euro3-card/TheMintedEuro3Card";
import { TheNumberOfBotsPurchasedCard } from "./the-number-of-bots-purchased-card/TheNumberOfBotsPurchasedCard";
import { TheNftRewardCard } from "./the-nft-reward-card/TheNftRewardCard";
import { FlexRow } from "../../../atoms/containers/FlexRow";
import { TheRewardsCollectedCard } from "./the-rewards-collected-card/TheRewardsCollectedCard";
import { useContractRead } from "wagmi";
import { useState } from "react";
import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { Button } from "@/components/atoms/inputs/button/Button";
import { useTranslation } from "react-i18next";
import NFTImage from "@/assets/images/000.png"
import useRealCenturion from "@/hooks/useRealCenturion";
import { useAccount } from "wagmi";
import useIsCenturion from "@/hooks/useIsCenturion";
import { FlexColumn } from "@/components/atoms/containers/FlexColumn";
import { TheCenturionInfoCard } from "./TheCenturionInfoCard";
import { TwitterIcon } from "@/components/atoms/data-display/icons/Twitter";
import { TelegramIcon } from "@/components/atoms/data-display/icons/Telegram";
import { FOOTER_URLS } from "@/utils/constants";

export type ReferralProps = {
  centurion?: any;
};

function TheReferralsNoCenturion() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-referrals"})

  const { address } = useAccount();
  const { isCenturion } = useRealCenturion();

  /*const claimed = useContractRead({
      ...CenturionProgramContract,
      functionName: 'claimed',
      args: [address],
      watch: true
  })*/

  // TODO: to be changed
  const activatedReferrals = 5;
  const mintedEuro3 = '1.00 EURO3';
  const botsPurchased = 1;
  const rewardsCollected = '50 A3A';

  const dataCards = [
    {
      title: t('cards.activated-referrals'),
      tooltip: t('cards.activated-referrals-tooltip'),
      value: `${activatedReferrals}`,
    },
    {
      title: t('cards.minted-euro3'),
      tooltip: t('cards.minted-euro3-tooltip'),
      value: mintedEuro3,
    },
    {
      title: t('cards.number-of-bots-purchased'),
      tooltip: t('cards.number-of-bots-purchased-tooltip'),
      value: `${botsPurchased}`,
    },
    {
      title: t('cards.a3a-rewards-collected'),
      tooltip: t('cards.a3a-rewards-collected-tooltip'),
      value: rewardsCollected,
    },
  ];

  const footerIcons = [
    {
      icon: <TwitterIcon />,
      url: FOOTER_URLS.TWITTER_URL,
      hasBackground: true,
    },
    {
      icon: <TelegramIcon />,
      url: FOOTER_URLS.TELEGRAM_URL,
      hasBackground: false,
    },
  ]

  return (
    <Box px={5} py={4}>
      <FlexRow mb={1.5}>
        <TheNftRewardCard centurion={isCenturion} />
        <FlexColumn>
          <TheCenturionInfoCard isCenturion={isCenturion} />
          <Box display="flex" flexDirection="row" alignSelf="end">
            {footerIcons.map((footer, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 33,
                  height: 33,
                  border: '1px #000000 solid',
                  bgcolor: footer.hasBackground ? 'primary.main' : 'transparent',
                  borderRadius: '33px',
                  mt: 3,
                  ...(index !== footerIcons.length - 1 && {
                    mr: 1.5,
                  })
                }}>
                <Link target="_blank" rel="noreferrer" href={footer.url}>
                  {footer.icon}
                </Link>
              </Box>
            ))}
          </Box>
        </FlexColumn>
      </FlexRow>
    </Box>
  );
}

export function TheReferrals() {

  const { t } = useTranslation("translation", { keyPrefix: "the-referrals.no-centurion" })
  const { isCenturion } = useRealCenturion();

  return (
    <>
      {(isCenturion && (
      <Box px={5} py={4}>
        <Box flex={1} mb={5.75}>
          <TheReferralLinkCard centurion={isCenturion} />
        </Box>
        <FlexRow mb={5}>
          <Box flex={1}>
            <TheActivatedReferralsCard />
          </Box>
          <>
            <Box flex={1} ml={2}>
              <TheRewardsCollectedCard text="A3A Rewards collected" value="TBA" />
            </Box>
            <Box flex={1} ml={2}>
              <TheRewardsCollectedCard text="EURO3 Rewards collected" value="TBA" />
            </Box>
          </>
        </FlexRow>
        <FlexRow py={0.25}>
          <TheNftRewardCard centurion={isCenturion} />
        </FlexRow>
      </Box>
      ))}
      {(!isCenturion && (
        <TheReferralsNoCenturion />
      ))}
    </>
    );
}