import { useTheme } from '@mui/material';

type Props = {
  color?: string;
}

export const LinkIcon = ({ color = '' }: Props) => {
  const theme = useTheme();
  const { secondary } = theme.palette;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_254_20083)">
        <path
          d="M8.3335 10.8335C8.69137 11.3119 9.14796 11.7078 9.67229 11.9943C10.1966 12.2807 10.7764 12.4511 11.3724 12.4937C11.9683 12.5364 12.5665 12.4504 13.1263 12.2416C13.6861 12.0328 14.1944 11.706 14.6168 11.2835L17.1168 8.78347C17.8758 7.99763 18.2958 6.94512 18.2863 5.85263C18.2768 4.76014 17.8386 3.71509 17.0661 2.94256C16.2935 2.17002 15.2485 1.73182 14.156 1.72233C13.0635 1.71283 12.011 2.13281 11.2252 2.8918L9.79183 4.3168"
          stroke={color || secondary.main}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6668 9.16653C11.309 8.68809 10.8524 8.29221 10.328 8.00575C9.80371 7.71928 9.22391 7.54893 8.62796 7.50625C8.03201 7.46357 7.43384 7.54956 6.87405 7.75838C6.31425 7.9672 5.8059 8.29397 5.3835 8.71653L2.8835 11.2165C2.12451 12.0024 1.70453 13.0549 1.71402 14.1474C1.72352 15.2399 2.16172 16.2849 2.93426 17.0574C3.70679 17.83 4.75184 18.2682 5.84433 18.2777C6.93681 18.2872 7.98932 17.8672 8.77517 17.1082L10.2002 15.6832"
          stroke={color || secondary.main}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_254_20083">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};