type Props = {
  color?: string
}

export const YieldIcon = ({ color = 'white' }: Props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Icon"
        d="M2.25 14.75L6.26017 11.0001L9.12457 13.6786L15.4263 7.78582M11.1389 7.25H16V11.7956M3.5 21C2.11929 21 1 19.8807 1 18.5V3.5C1 2.11929 2.11929 1 3.5 1H18.5C19.8807 1 21 2.11929 21 3.5V18.5C21 19.8807 19.8807 21 18.5 21H3.5Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}