import { useEffect, useState } from 'react';
import { Alert, Box, Button, Fade, Snackbar } from "@mui/material";
import { DescriptionOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { Typography } from "../../../../atoms/data-display/typography/Typography";
import { FlexRowAlignCenter } from "../../../../atoms/containers/FlexRow";
import { TransparentCard } from "../../../../atoms/containers/Card";
import { InputField } from "../../../../atoms/inputs/inputField/InputField";
import { LinkIcon } from "../../../../atoms/data-display/icons/Link";
import { ReferralProps } from '../TheReferrals';
import { useAccount } from 'wagmi';

const generateReferralLink = (address: string) => {
  return `https://api.3adao.org/whitelist/gen?referer=${address}`;
}

export function TheReferralLinkCard({ centurion }: ReferralProps) {
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const { isConnected, address } = useAccount();

  useEffect(() => {
    if (isConnected) {
      const link = generateReferralLink(address as `0x${string}`);
      // fetch the link
      fetch(link).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setReferralLink(`https://3adao.org/ref/?referer=${data.referer}`)
          })
        }
      });
    }
  }, [isConnected, address]);

  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-referrals.the-referral-link-card"})

  // TODO: to be changed
  const [referralLink, setReferralLink] = useState<string>(`Loading...`);

  const onCopy = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(referralLink);
    } else {
      document.execCommand('copy', true, referralLink);
    }

    setShowCopyMessage(true);
  };

  return (
    <>
    <Snackbar
        open={showCopyMessage}
        autoHideDuration={4000} 
        onClose={() => {setShowCopyMessage(false)}}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        TransitionComponent={Fade}
        message = {
          <Alert onClose={() => {setShowCopyMessage(false)}} severity="success" sx={{ width: '100%' }}>
            <Typography variant="body_regular">
              {`Copied to Clipboard`}
            </Typography>
          </Alert>
        }
      />
    
    <TransparentCard sx={{ p: 4, pt: 2 }}>
      <FlexRowAlignCenter sx={{
        mb: 3
      }}>
        <Typography
          variant='h3'
          sx={{ fontWeight: 600 }}
        >
          {`${centurion ? "CENTURION - " : ''}${t("centurion-referral-link")}`}
        </Typography>
      </FlexRowAlignCenter>
      <FlexRowAlignCenter
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{
          flex: 1,
          mr: 3,
        }}>
          <InputField
            type="text"
            value={referralLink}
            setValue={setReferralLink}
            disabled
            startAdornment={<LinkIcon />}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'white',
              }
            }}
          />
        </Box>
        <Button
          onClick={onCopy}
          variant="contained"
          color="primary"
          sx={{
            alignSelf: 'flex-end',
            maxHeight: 56,
            py: 2,
            px: 2.75,
          }}
        >
          <DescriptionOutlined />
          <Typography variant="subheading_bold" sx={{
            ml: 1
          }}>
            {t("copy")}
            </Typography>
        </Button>
      </FlexRowAlignCenter>
    </TransparentCard>
    
    </>
  )
}
