import { Box } from "@mui/material";
import { useState } from "react";

import { TheBEURStakingStatistics } from "./The-BEUR-staking-statistics-card/TheBEURStakingStatistics";
import { ThePoolShareCard } from "./the-pool-share-card/ThePoolShareCard";
import { TheStakingForm } from "./the-staking-form/TheStakingForm";
import { TheStakingRewardsCard } from "./the-staking-rewards-card/TheStakingRewardCard";

export function TheStaking() {
  const [selectedTab, setSelectedTab] = useState<'euro3' | 'a3a'>('euro3');

  return (
    <>
      <Box sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        py: 3.5,
        px: 5,
      }}>
        <Box sx={{
          flex: 1,
          mr: 4,
        }}>
          <TheStakingForm type={selectedTab} setType={setSelectedTab} />
        </Box>
        <Box sx={{
          flex: 1,
        }}>
          <Box mb={2}>
            <TheStakingRewardsCard type={selectedTab} />
          </Box>
          <Box mb={2}>
            <ThePoolShareCard type={selectedTab} />
          </Box>
          <Box>
            <TheBEURStakingStatistics type={selectedTab} />
          </Box>
        </Box>
      </Box>
    </>
  );
}