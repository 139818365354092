import React, { Dispatch, SetStateAction } from 'react';
import {
  Box,
  BoxProps,
  ButtonProps,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Decimal from "decimal.js";
import { Typography } from '../../data-display/typography/Typography';
import { Button } from '../button/Button';
import { InfoIcon } from '../../data-display/icons/Info';
import ClearIcon from '@mui/icons-material/Clear';

type Props = TextFieldProps & {
  inputLabel?: string | null;
  caption?: string | null;
  descriptionComponent?: React.ReactNode;
  maxValue?: number | string;
  value?: any;
  setValue?: ((val: any) => void) | (Dispatch<SetStateAction<any>>);
  startAdornment?: React.ReactNode;
  captionIcon?: React.ReactNode;
  errorMessages?: string[];
  showAddMinusButtons?: boolean;
  disableDecimals?: boolean;
  decimalPoints?: number;
  counterButtonStyle?: SxProps;
};

type CounterProps = ButtonProps & {
  counterType: 'increment' | 'decrement';
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  minHeight: '56px',
  fontFamily: 'Public Sans',
  fontSize: '14px',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    color: theme.palette.text.primary,
    fontSize: 20,
    lineHeight: '24px',
    outline: 'none',
    maxHeight: 56,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(0.25),
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },
}));

export const CounterButton = ({ counterType, sx, ...props }: CounterProps) => {
  const theme = useTheme();
  const { common } = theme.palette;

  const isAdd = counterType === 'increment';

  return (
    <Button
      sx={{
        borderRadius: '12px',
        py: '2px',
        px: 0,
        maxWidth: 24,
        minWidth: 24,
        height: 16,
        bgcolor: 'rgba(255, 255, 255, 0.16)',
        color: common.white,
        ...sx,
      }}
      {...props}
    >
      {isAdd ? '+' : '-' }
    </Button>
  );
};

export const Divider = (props: BoxProps) => {
  const theme = useTheme();
  const { tertiary } = theme.palette;

  return (
    <Box
      sx={{
        height: '24px',
        width: '1px',
        bgcolor: tertiary.main,
        opacity: 0.32,
        borderRadius: '50px',
      }}
      {...props}
    />
  );
};

export function InputField ({
  caption,
  descriptionComponent = null,
  inputLabel,
  maxValue,
  value,
  setValue,
  startAdornment,
  captionIcon: CaptionIcon,
  placeholder = "Enter the amount",
  error,
  errorMessages,
  type = "number",
  showAddMinusButtons = true,
  disableDecimals = false,
  decimalPoints = 4,
  counterButtonStyle = {},
  ...rest
}: Props) {
  const theme = useTheme();
  const { text, primary } = theme.palette;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (setValue) setValue(value);
  };

  const onAdd = () => {
    const newValue = Number(value) + 1;
    if (maxValue && newValue <= maxValue) {
      if (setValue) setValue(new Decimal(newValue).toDP(decimalPoints));
    }
  };

  const onSubtract = () => {
    const newValue = Number(value) - 1 > 0? Number(value) - 1 : 0;
    if (maxValue && newValue <= maxValue) {
      if (setValue) setValue(new Decimal(newValue).toDP(decimalPoints));
    }
  };

  const onClear = () => {
    setValue?.("");
  }

  const onClickMax = () => {
    maxValue && setValue && setValue(maxValue?.toString());
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const exceptThisSymbols = ["e", "E", "+", "-"];
    if (disableDecimals) {
      exceptThisSymbols.push('.');
    }
    exceptThisSymbols.includes(e.key) && e.preventDefault()
  };

  return (
    <>
      <StyledTextField
        type={type}
        value={value}
        label={inputLabel}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onPaste={e => {
          if (disableDecimals) {
            e.preventDefault();
            return false;
          }
        }}
        {...rest}
        sx={{
          '& .MuiInputBase-input': {
            p: 2,
            ...(startAdornment && { pl: 0 }),
          },
          ...rest.sx
        }}
        inputProps={{
          sx: {
            '&::placeholder': {
              color: text.primary,
              opacity: 1,
            },
          },
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          ...(startAdornment && {
            startAdornment: (
              <InputAdornment position="start">
                {startAdornment}
              </InputAdornment>
            )
          }),
          ...((showAddMinusButtons && type === 'number') && {
            endAdornment: (
              <InputAdornment position="end">
                <Box display="flex" flexDirection="row" alignItems="center">
                  {value > 0 && (
                    <>
                  <Divider />
                  <IconButton
                    onClick={onClear}
                    disabled={rest.disabled}
                    >
                    <ClearIcon
                    sx = {{
                      color: theme.palette.text.disabled
                    }} />
                  </IconButton>
                  </>
                  )}
                  <Divider />
                  <Box display="flex" flexDirection="column" ml={2}>
                    <CounterButton
                      disabled={rest.disabled || value === maxValue}
                      counterType="increment"
                      onClick={onAdd}
                      sx={{ mb: 0.75, ...counterButtonStyle }}
                    />
                    <CounterButton
                      disabled={rest.disabled || !value || value === 0}
                      counterType="decrement"
                      onClick={onSubtract}
                      sx={counterButtonStyle}
                    />
                  </Box>
                  <Divider ml={2} />
                  <Button
                    onClick={onClickMax}
                    disabled={rest.disabled}
                    sx={{
                      ml: 2,
                      maxWidth: 30,
                      minWidth: 30,
                      maxHeight: 24,
                      p: 0,
                      color: primary.main,
                      '&:hover': {
                        backgroundColor: 'transparent',
                      }
                    }}
                  >Max</Button>
                </Box>
              </InputAdornment>
            )
          }),
          ...rest.InputProps
        }}
      />

      {descriptionComponent}

      {!!caption && (
        <Box display="flex" flexDirection="row" alignItems="center" mt={1.5}>
          {CaptionIcon ?? <InfoIcon />}
          <Typography variant="caption_regular" sx={{ ml: 0.5 }}>
            {caption}
          </Typography>
        </Box>
      )}
      {(!!error && !!errorMessages?.length) && (
        <Box mt={1}>
          {errorMessages?.map((message: string, index: number) => 
            <Typography
              key={index}
              variant="caption_regular"
              color="error.light"
              sx={{ display: "flex" }}
            >
              { message }
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
