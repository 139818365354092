import { useEffect, useMemo, useRef, useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import { styled } from '@mui/material/styles';
import { BigNumber } from "ethers";
import { useMachine } from "@xstate/react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useConstant from "use-constant";
import { useAccount } from "wagmi";

import { readTotalStakingRewardsActor } from "@/actors/staking-rewards-card/readTotalStakingRewards/actor";
import { Context } from "@/actors/staking-rewards-card/readTotalStakingRewards/types";
import InfoToolTip from "@/components/atoms/data-display/tooltip/InfoTooltip";
import { DisabledTypography, TextGradient, Typography } from "@/components/atoms/data-display/typography/Typography";
import { FlexRow, FlexRowAlignCenter, FlexRowSpaceBetween } from "@/components/atoms/containers/FlexRow";
import { useDashboardData } from "@/contexts/DashboardDataProvider";
import useStaking from "@/hooks/useStaking";
import { bigNumberToNumber } from "@/utils/utils";
import { Euro, stakingRewards } from "@/utils/constants";
import { StakingCardProps } from "@/utils/types";
import { getServiceSnapshot } from "@xstate/react/lib/utils";
import { FlexColumn } from "@/components/atoms/containers/FlexColumn";
import useStabilityPool from "@/hooks/useStabilityPool";

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '22px',
}));

const { a3a, euro3 } = stakingRewards;

export function TheTotalStakingRewards({ type }: StakingCardProps) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-staking.the-staking-rewards-card" })
  const [totalRewards, setTotalRewards] = useState({ data: 0, isLoading: true });
  const { address } = useAccount()
  const context: Context = {
    walletAddress: address,
  }
  const actor = useConstant(() => readTotalStakingRewardsActor.withContext(context))
  const [state] = useMachine(actor)
  const totalStakingRewards = useRef(0);
  if (state.matches("success")) {
    const { txnResult } = state.context as Context
    const { beurTotalRewards = '0', bnqTotalRewards = '0' } = txnResult || {}
    if (type === a3a) {
      totalStakingRewards.current = bigNumberToNumber(beurTotalRewards)
    } else {
      totalStakingRewards.current = bigNumberToNumber(bnqTotalRewards)
    }
  }
  const unit = type === a3a ? euro3.toUpperCase() : a3a.toUpperCase()

  {/* TODO: to change with actual value */ }
  const liquidation = 780.00;
  //liquidationRewards

  const { selectedTrove } = useDashboardData();
  const {
    liquidationReserve,
    DECIMAL_PRECISION,
    getStakingOverview
  } = useStaking(selectedTrove?.address as string);

  const {
    getStabilityPoolOverview
  } = useStabilityPool();

  const totalRewardsNum = useMemo(() => {
    if (totalRewards?.data) return totalRewards?.data
    return 0;
  }, [totalRewards]);

  const liquidationReserveNum = useMemo(() => {
    if (liquidationReserve?.data && DECIMAL_PRECISION?.data) {
      const decimalPrecision = DECIMAL_PRECISION?.data as string;
      return (liquidationReserve?.data as BigNumber)?.div(decimalPrecision)?.toNumber();
    }
    return 0;
  }, [liquidationReserve, DECIMAL_PRECISION]);


  useEffect(() => {
    async function fetchOverview() {
      setTotalRewards({ data: 0, isLoading: true })
      if (type === a3a) {
        const data = await getStakingOverview();
        setTotalRewards({ data: data?.totalEuro3RewardsHuman ?? 0, isLoading: !data })
      } else if (type === euro3) {
        const data = await getStabilityPoolOverview();
        setTotalRewards({ data: data?.totalA3ARewardsInEuroHuman ?? 0, isLoading: !data })
      }
    }

    fetchOverview();
  }, [type]);

  return (
    <FlexRowSpaceBetween>
      {/* {type === euro3 && (
        <FlexRow mr={4}>
          <FlexRowAlignCenter>
            <DisabledTypography variant='subheading_regular'>
              Liquidation Rewards
            </DisabledTypography>
            <InfoToolTip title="" />
          </FlexRowAlignCenter>
          <StyledText variant="subheading_regular">
            {`${Euro} ${liquidationReserveNum.toFixed(2)}`}
          </StyledText>
        </FlexRow>
      )} */}
      <FlexRow>
        <FlexRowAlignCenter>
          <DisabledTypography variant='subheading_regular'>
            {type === euro3 && (
              t(`${type}.total-rewards`)
            )}
            {type === a3a && (
              `Total rewards distributed`
            )}
          </DisabledTypography>
          <InfoToolTip title={t(`${type}.total-rewards-tooltip`)} />
        </FlexRowAlignCenter>
        {totalRewards?.isLoading ? (
          <FlexColumn>
            <LinearProgress color="primary" />
          </FlexColumn>
        ) : (
          <>
            {type === euro3 && (
              <Box>
                <StyledText variant="subheading_regular">
                  {`${Euro} ${new Intl.NumberFormat(i18next.language, { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    .format(totalRewardsNum)}`}
                </StyledText>
              </Box>
            )}
            {type === a3a && (
              <Box>
                <TextGradient
                  variant="subheading_bold"
                  sx={{ mr: 1 }}
                >
                  {`${Euro} ${new Intl.NumberFormat(i18next.language, { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    .format(totalRewardsNum)}`}
                </TextGradient>
              </Box>
            )}
          </>
        )}
      </FlexRow>
    </FlexRowSpaceBetween>
  )
}