import { useAccount, useContractRead } from "wagmi";
import { useMemo } from "react";
import { BigNumber, ethers } from "ethers";

import {
  getCollateralDisplayData,
  getContractValue,
} from '@/utils/utils';
import { useVaultFactory } from "./useVaultFactory";
import { useVaultFactoryHelper } from "./useVaultFactoryHelper";
import { useVaultBorrowRate } from "./useVaultBorrowRate";

const fixedFee: number = Number(process.env.REACT_APP_TROVE_FIXED_FEE);

const useVault = (vaultInfo?: any) => {

  let troveAddress: string = '';
  let collateralRatio: BigNumber = BigNumber.from(0),
      mcr: BigNumber = BigNumber.from(0),
      liquidationFactor: number = 0,
      liquidationPrice: number = 0;

  if (vaultInfo) {
    troveAddress = vaultInfo.address;
    collateralRatio = ethers.utils.parseEther(vaultInfo.healthFactor);
    mcr = collateralRatio;
    liquidationFactor = parseFloat(vaultInfo.liquidationFactor);
    liquidationPrice = parseFloat(vaultInfo.liquidationThreshold);
  }

  const { address } = useAccount();
  const { VaultFactory } = useVaultFactory();
  const { VaultFactoryHelper } = useVaultFactoryHelper();
  const { VaultBorrowRate } = useVaultBorrowRate();

  const totalCollateral = useContractRead({
    address: VaultFactoryHelper?.address,
    abi: VaultFactoryHelper?.abi,
    functionName: "getVaultTvl",
    args: [troveAddress]
  });

  const liquidationReserve = 1;

  const collateralDisplayData = useMemo(() => {
    // check if redemption factor is set
    let redemptionFactor = "0";
    if (vaultInfo && "redemptionFactor" in vaultInfo) redemptionFactor = vaultInfo.redemptionFactor;
    return getCollateralDisplayData(getContractValue(collateralRatio), parseFloat(redemptionFactor));

  },[collateralRatio]);

  const DECIMAL_PRECISION = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'DECIMAL_PRECISION',
    args: [],
  });

  const LIQUIDATION_RESERVE = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'LIQUIDATION_RESERVE',
    args: [],
  });

  const MAX_BORROWING_RATE = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'MAX_BORROWING_RATE',
    args: [],
  });

  const MAX_INT = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'MAX_INT',
    args: [],
  });

  const MAX_REDEMPTION_RATE = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'MAX_REDEMPTION_RATE',
    args: [],
  });

  const MAX_TOKENS_PER_VAULT = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'MAX_TOKENS_PER_VAULT',
    args: [],
  });

  const PERCENT = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'PERCENT',
    args: [],
  });

  const PERCENT10 = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'PERCENT10',
    args: [],
  });

  const PERCENT_05 = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'PERCENT_05',
    args: [],
  });

  const borrowRate = useContractRead({
    address: VaultBorrowRate?.address!,
    abi: VaultBorrowRate?.abi,
    functionName: 'getBorrowRate',
    args: [troveAddress],
  });

  const collateral = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'collateral',
    args: [troveAddress],
  });

  const totalDebt = useContractRead({
    address: VaultFactory?.address!,
    abi: VaultFactory?.abi,
    functionName: 'totalDebt',
    args: [],
  });

  return {
    totalCollateral,
    liquidationReserve,
    totalDebt,
    collateralRatio,
    collateralDisplayData,
    mcr,
    liquidationFactor,
    liquidationPrice,
    DECIMAL_PRECISION,
    LIQUIDATION_RESERVE,
    MAX_BORROWING_RATE,
    MAX_INT,
    MAX_REDEMPTION_RATE,
    MAX_TOKENS_PER_VAULT,
    PERCENT,
    PERCENT10,
    PERCENT_05,
    borrowRate,
    collateral,
  };
};

export { useVault };
