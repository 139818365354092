import { useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { writeContract, readContract } from '@wagmi/core';
import { useAccount, useContractRead, erc20ABI } from 'wagmi';
import Decimal from 'decimal.js';

import { DECIMAL_PRECISION, TX_CONFIRMATIONS, statuses } from '@/utils/constants';
import { useAbi } from './useAbi';
import { RedemptionRow } from '@/components/compounds/the-dashboard/the-redemption-liquidation/TheRedemptionTable';
import { useVaultFactory } from './useVaultFactory';

type RedeemParams = {
  vaultAddress: string;
  collateralAddress?: string;
  amount: number | string | BigNumber | Decimal;
}

type LiquidateParams = {
  vaultAddress: string;
  collateralAddress?: string;
}


function toFixedTrunc(x: string | number, n: number) {
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (n <= 0) return v[0];
  let f = v[1] || '';
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += '0';
  return `${v[0]}.${f}`
}

const useRedemptionLiquidation = () => {

  const { VaultFactory } = useVaultFactory();

  const { address } = useAccount();

  const { AbiFactory: AuctionManager } = useAbi({ abiName: 'AuctionManager' });

  const [status, setStatus] = useState(statuses.NONE);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [txHash, setTx] = useState("");

  const reset = () => {
    setStatus(statuses.NONE);
    setIsLoading(false);
    setIsError(false);
    setTx("");
  };

  const auctions = useContractRead({
    address: AuctionManager?.address!,
    abi: AuctionManager?.abi,
    functionName: 'auctions',
    args: [],
  });

  const getStableAddress = async () => {
    const stableAddress = await readContract({
      address: VaultFactory!.address as `0x${string}`,
      abi: VaultFactory!.abi,
      functionName: "stable",
      args: []
    }) as string;
    return stableAddress;
  };

  const redeem = async (row: RedemptionRow) => {
    try {
      setIsLoading(true);
      setStatus(statuses.APPROVING);
      if (VaultFactory && row.amount) {

        const collateralAmount = toFixedTrunc(Number(row.amount)*0.99 / Number(row.price.priceHuman), 2);
        const collateralAmountBn = ethers.utils.parseUnits(collateralAmount.toString(), 18); // todo: get decimals from token
        const stableAddress = await getStableAddress();
        const approveWrite = await writeContract({
          address: stableAddress,
          abi: erc20ABI,
          functionName: "approve",
          mode: 'recklesslyUnprepared',
          args: [VaultFactory.address, ethers.constants.MaxUint256],
        });
        await approveWrite?.wait(TX_CONFIRMATIONS);
        setStatus(statuses.SIGNING);
        const redeemWrite = await writeContract({
          address: VaultFactory.address,
          abi: VaultFactory.abi,
          functionName: "redeem",
          mode: 'recklesslyUnprepared',
          args: [row.address, row.collateralToken, collateralAmountBn, address],
        });
        await redeemWrite?.wait(TX_CONFIRMATIONS);
        setTx(redeemWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  const liquidate = async ({ collateralAddress, vaultAddress }: LiquidateParams) => {
    try {
      setIsLoading(true);
      setStatus(statuses.SIGNING);
      if (VaultFactory && address) {
        const liquidateWrite = await writeContract({
          address: VaultFactory.address!,
          abi: VaultFactory.abi,
          functionName: "liquidate",
          mode: 'recklesslyUnprepared',
          args: [
            vaultAddress,
          ],
        });
        setTx(liquidateWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  const winAuction = async () => {
    try {
      setIsLoading(true);
      setStatus(statuses.SIGNING);
      if (AuctionManager && address) {
        const newAuctionWrite = await writeContract({
          address: AuctionManager.address!,
          abi: AuctionManager.abi,
          functionName: "newAuction",
          mode: 'recklesslyUnprepared',
          args: [],
        });
        setTx(newAuctionWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  return {
    redeem,
    liquidate,
    winAuction,
    auctions,
    status,
    isLoading,
    isError,
    txHash,
    reset,
  };
};

export default useRedemptionLiquidation;
