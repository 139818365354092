type Props = {
  color?: string;
}

export const SmallInfoIcon = ({ color = '#919EAB' }: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.375 8.25V5.25H4.875V6H5.625V8.25H4.5V9H7.5V8.25H6.375Z"
      fill={color}
    />
    <path
      d="M6 3C5.88875 3 5.78 3.03299 5.68749 3.0948C5.59499 3.15661 5.5229 3.24446 5.48032 3.34724C5.43775 3.45003 5.42661 3.56313 5.44831 3.67224C5.47002 3.78136 5.52359 3.88158 5.60226 3.96025C5.68092 4.03892 5.78115 4.09249 5.89026 4.1142C5.99938 4.1359 6.11248 4.12476 6.21526 4.08219C6.31805 4.03961 6.4059 3.96751 6.4677 3.87501C6.52951 3.78251 6.5625 3.67376 6.5625 3.5625C6.5625 3.41332 6.50324 3.27024 6.39775 3.16476C6.29226 3.05927 6.14919 3 6 3Z"
      fill={color}
    />
    <path
      d="M6 11.25C4.96165 11.25 3.94662 10.9421 3.08326 10.3652C2.2199 9.78834 1.54699 8.9684 1.14963 8.00909C0.752275 7.04978 0.648307 5.99418 0.85088 4.97578C1.05345 3.95738 1.55347 3.02192 2.28769 2.28769C3.02192 1.55347 3.95738 1.05345 4.97578 0.85088C5.99418 0.648307 7.04978 0.752275 8.00909 1.14963C8.9684 1.54699 9.78834 2.2199 10.3652 3.08326C10.9421 3.94662 11.25 4.96165 11.25 6C11.25 7.39239 10.6969 8.72775 9.71231 9.71231C8.72775 10.6969 7.39239 11.25 6 11.25ZM6 1.5C5.10999 1.5 4.23996 1.76392 3.49994 2.25839C2.75992 2.75286 2.18314 3.45566 1.84254 4.27793C1.50195 5.10019 1.41284 6.00499 1.58647 6.87791C1.7601 7.75082 2.18869 8.55265 2.81802 9.18198C3.44736 9.81132 4.24918 10.2399 5.1221 10.4135C5.99501 10.5872 6.89981 10.4981 7.72208 10.1575C8.54435 9.81687 9.24715 9.24009 9.74162 8.50007C10.2361 7.76005 10.5 6.89002 10.5 6C10.5 4.80653 10.0259 3.66194 9.18198 2.81802C8.33807 1.97411 7.19348 1.5 6 1.5Z"
      fill={color}
    />
  </svg>
);

export const InfoIcon = ({ color = '#F9FAFB' }: Props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 11V7H6.5V8H7.5V11H6V12H10V11H8.5Z"
        fill={color}
      />
      <path
        d="M8 4C7.85167 4 7.70666 4.04399 7.58333 4.1264C7.45999 4.20881 7.36386 4.32595 7.30709 4.46299C7.25033 4.60004 7.23548 4.75084 7.26441 4.89632C7.29335 5.04181 7.36478 5.17544 7.46967 5.28033C7.57456 5.38522 7.7082 5.45665 7.85369 5.48559C7.99917 5.51453 8.14997 5.49968 8.28702 5.44291C8.42406 5.38615 8.54119 5.29002 8.62361 5.16668C8.70602 5.04334 8.75 4.89834 8.75 4.75C8.75 4.55109 8.67099 4.36033 8.53033 4.21967C8.38968 4.07902 8.19892 4 8 4Z"
        fill={color}
      />
      <path
        d="M8 15C6.61553 15 5.26216 14.5895 4.11101 13.8203C2.95987 13.0511 2.06266 11.9579 1.53285 10.6788C1.00303 9.3997 0.86441 7.99224 1.13451 6.63437C1.4046 5.2765 2.07129 4.02922 3.05026 3.05026C4.02922 2.07129 5.2765 1.4046 6.63437 1.13451C7.99224 0.86441 9.3997 1.00303 10.6788 1.53285C11.9579 2.06266 13.0511 2.95987 13.8203 4.11101C14.5895 5.26216 15 6.61553 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 2C6.81331 2 5.65328 2.3519 4.66658 3.01119C3.67989 3.67047 2.91085 4.60755 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2Z"
        fill={color}
      />
    </svg>
  )
}