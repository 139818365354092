import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DisabledTypography, Typography } from "../../../../atoms/data-display/typography/Typography";
import { FlexRowAlignCenter } from "../../../../atoms/containers/FlexRow";
import { TransparentCard } from "../../../../atoms/containers/Card";
import InfoToolTip from "../../../../atoms/data-display/tooltip/InfoTooltip";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";

export function TheActivatedReferralsCard() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-referrals.the-activated-referrals-card"})

  const [activatedReferrals, setActivatedReferrals] = useState(undefined);
  const { isConnected, address } = useAccount();

  useEffect(() => {
    if (isConnected) {
      // fetch the link
      fetch(`https://api.3adao.org/whitelist/count?referer=${address}`).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setActivatedReferrals(data.count);
          })
        }
      });
    }
  }, [isConnected, address]);

  return (
    <TransparentCard p={3}>
      <FlexRowAlignCenter mb={2}>
        <DisabledTypography variant='subheading_regular'>
          { t ("activated-referrals") }
        </DisabledTypography>
        <Box mt={0.25}>
          <InfoToolTip title={t ("activated-referrals")} />
        </Box>
      </FlexRowAlignCenter>
      <FlexRowAlignCenter>
        <Typography
          variant='subheading_regular'
          sx={{
            fontSize: 20,
            lineHeight: '24px',
          }}
        >
          {(activatedReferrals !== undefined)? activatedReferrals : "Loading..."}
        </Typography>
      </FlexRowAlignCenter>
    </TransparentCard>
  )
}
