import { Box, LinearProgress, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexRow, FlexRowAlignCenter } from '@/components/atoms/containers/FlexRow';
import TheIndividualDataCard from './TheIndividualDataCard';
import { formatValue } from '@/utils/utils';
import { FORMAT_CONFIGS, SYMBOLS } from '@/utils/constants';
import { FlexColumn } from '@/components/atoms/containers/FlexColumn';
import { Typography } from '@/components/atoms/data-display/typography/Typography';
import InfoToolTip from '@/components/atoms/data-display/tooltip/InfoTooltip';
import TheStatisticsVaultHF from './TheStatisticsVaultHF';
import useStatistics from '@/hooks/useStatistics';

const { EURO3, A3A } = SYMBOLS;
const { NO_MIN_DIGITS, MAX_DIGITS_3, PERCENTAGE_NO_MIN } = FORMAT_CONFIGS;

const TheStatisticsOverview = () => {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-statistics"})
  const theme = useTheme();
  const { primary, text } = theme.palette;

  const { isLoading, data: statisticsData } = useStatistics();
  const { data } = statisticsData?.[0] || {};

  const {
    circulatingEURO3,
    euro3InStabilityPool,
    A3AinCashbackPool,
    totalProtocolFees,
    totalVaultsCreated,
    avgCollateralPerVault,
    avgDebtPerVault,
    collateralBackingPerEuro3,
    maxDebtPerHour,
    euro3LeftToBorrowInCurrentHour,
    euro3HardCap,
    currentDebtCeilingPercentage,
    tvl,
  } = useMemo(() => { 
    const {
      circulatingEURO3 = 0,
      euro3InStabilityPool = 0,
      A3AinCashbackPool = 0,
      totalProtocolFees = 0,
      totalVaultsCreated = 0,
      avgCollateralPerVault = 0,
      avgDebtPerVault = 0,
      collateralBackingPerEuro3 = 0,
      maxDebtPerHour = 0,
      euro3LeftToBorrowInCurrentHour = 0,
      euro3HardCap = 0,
      currentDebtCeilingPercentage = 0,
      tvl = 0,
    } = data || {};
    return {
      circulatingEURO3: formatValue(Number(circulatingEURO3)),
      euro3InStabilityPool: formatValue(Number(euro3InStabilityPool)),
      A3AinCashbackPool: formatValue(Number(A3AinCashbackPool)),
      totalProtocolFees: formatValue(Number(totalProtocolFees)),
      totalVaultsCreated,
      avgCollateralPerVault: formatValue(Number(avgCollateralPerVault), FORMAT_CONFIGS.EURO),
      avgDebtPerVault: formatValue(Number(avgDebtPerVault), NO_MIN_DIGITS),
      collateralBackingPerEuro3: formatValue(Number(collateralBackingPerEuro3), FORMAT_CONFIGS.EURO),
      maxDebtPerHour: formatValue(Number(maxDebtPerHour)),
      euro3LeftToBorrowInCurrentHour: formatValue(Number(euro3LeftToBorrowInCurrentHour)),
      euro3HardCap: formatValue(Number(euro3HardCap)),
      currentDebtCeilingPercentage: formatValue(Number(currentDebtCeilingPercentage), NO_MIN_DIGITS),
      tvl: formatValue(Number(tvl)),
    }
  }, [data]);

  return (
    <Box>
      <FlexRow sx={{ mb: 2 }}>
        <TheIndividualDataCard
          title={t("euro3-circulation")}
          isLoading={isLoading}
          value={`${circulatingEURO3} ${EURO3}`}
          valueProps={{
            sx: { color: primary.main },
          }}
        />
          <TheIndividualDataCard
          title={"Total Value Locked"}
          isLoading={isLoading}
          value={`${tvl} €`}
          valueProps={{
            sx: { color: primary.main },
          }}
        />
        <TheIndividualDataCard
          title={t("stability-pool")}
          isLoading={isLoading}
          value={`${euro3InStabilityPool} ${EURO3}`}
          valueProps={{
            sx: { color: primary.main },
          }}
        />
        <TheIndividualDataCard
          title={t("total-protocol-fees")}
          isLoading={isLoading}
          value={`${totalProtocolFees} ${EURO3}`}
          containerProps={{
            sx: { mr: 0 }
          }}
          valueProps={{
            sx: { color: primary.main },
          }}
        />
      </FlexRow>
      <FlexRow sx={{ mb: 2 }}>
        <TheIndividualDataCard
          title={t("total-vaults-created")}
          isLoading={isLoading}
          value={`${totalVaultsCreated}`}
        />
        <TheIndividualDataCard
          title={t("avg-collateral-value")}
          isLoading={isLoading}
          value={avgCollateralPerVault}
        />
        <TheIndividualDataCard
          title={t("avg-debt-per-vault")}
          isLoading={isLoading}
          value={`${avgDebtPerVault} ${EURO3}`}
        />
        <TheIndividualDataCard
          title={t("each-euro3-backed")}
          isLoading={isLoading}
          value={collateralBackingPerEuro3}
          containerProps={{
            sx: { mr: 0 }
          }}
        />
      </FlexRow>
      <FlexRow sx={{ mb: 3 }}>
        <FlexColumn mr={2.375}>
          <FlexRow sx={{ mb: 2 }}>
            <TheIndividualDataCard
              title={t("max-euro3-borrow")}
              isLoading={isLoading}
              value={`${maxDebtPerHour} ${EURO3}`}
            />
            <TheIndividualDataCard
              title={t("total-euro3-borrow")}
              isLoading={isLoading}
              value={`${euro3LeftToBorrowInCurrentHour} ${EURO3}`}
              containerProps={{
                sx: { mr: 0 }
              }}
            />
          </FlexRow>
          <TheIndividualDataCard
            title={t("euro3-hard-cap")}
            isLoading={isLoading}
            containerProps={{
              sx: { mr: 0 }
            }}
          >
            <Typography
              variant="subheading_regular"
              sx={{
                fontSize: 20,
                lineHeight: '24px',
                mb: 2,
              }}
            >
              {`${euro3HardCap} ${EURO3}`}
            </Typography>
            <FlexRowAlignCenter>
              <Typography
                variant='body_regular'
                color={text.disabled}
              >
                {t("max-amount")}
              </Typography>
              <InfoToolTip title="Pool share" />
            </FlexRowAlignCenter>
            <FlexRowAlignCenter mt={1}>
              <Typography variant='caption_regular' sx={{ mr: 1 }}>
                {`${currentDebtCeilingPercentage}%`}
              </Typography>
              <LinearProgress
                variant="determinate"
                sx={{
                  flex: 1,
                  height: 8,
                  borderRadius: 6.25,
                  backgroundColor: 'rgba(250, 84, 28, 0.24)',
                  '& .MuiLinearProgress-barColorPrimary': {
                    borderRadius: 6.25,
                  },
                }}
                value={Number(currentDebtCeilingPercentage)}
              />
            </FlexRowAlignCenter>
          </TheIndividualDataCard>
        </FlexColumn>
        <TheStatisticsVaultHF />
      </FlexRow>
    </Box>
  )
}

export default TheStatisticsOverview;
