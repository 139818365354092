import { useState } from 'react';
import axios from 'axios';
import { BigNumber, BigNumberish } from 'ethers';
import { writeContract, readContract } from '@wagmi/core';
import { erc20ABI, useAccount, useContractRead } from 'wagmi';
import Decimal from 'decimal.js';

import { API_BASE_URL, ENDPOINTS, TX_CONFIRMATIONS, statuses } from '@/utils/constants';
import { OverviewResponse, ProtocolStatsResponse, StakingCashbackResponse, StakingFormCardProps } from '@/utils/types';
import { useAbi } from './useAbi';

type StakeParams = StakingFormCardProps & {
  amount: BigNumber | number | string | Decimal;
}

type StakeParamsWithTrove = StakeParams & {
  selectedTroveAddress?: `0x${string}`
}

const useStaking = (troveAddress: string) => {
  const { address } = useAccount();

  const { AbiFactory: A3AStakingFactory } = useAbi({ abiName: 'A3AStaking' });
  const { AbiFactory: StabilityPoolFactory } = useAbi({ abiName: 'StabilityPool' })
  const { AbiFactory: Euro3 } = useAbi({ abiName: 'MintableToken' });
  const { AbiFactory: A3A } = useAbi({ abiName: 'A3A' })

  const [status, setStatus] = useState(statuses.NONE);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [txHash, setTx] = useState("");

  const reset = () => {
    setStatus(statuses.NONE);
    setIsLoading(false);
    setIsError(false);
    setTx("");
  };

  const getDecimalPrecision = async () => {
    const decimalPrecision = await readContract({
      address: A3AStakingFactory!.address!,
      abi: A3AStakingFactory!.abi,
      functionName: "DECIMAL_PRECISION",
      args: []
    }) as BigNumberish;
    return decimalPrecision.toString();
  };

  const getStableAddress = async () => {
    const stableAddress = await readContract({
      address: A3AStakingFactory!.address!,
      abi: A3AStakingFactory!.abi,
      functionName: "stableCoin",
      args: []
    }) as string;
    return stableAddress;
  };

  const stake = async ({ amount, type }: StakeParams) => {
    try {
      setIsLoading(true);
      setStatus(statuses.APPROVING);
      const contract = type === 'a3a' ? A3AStakingFactory : StabilityPoolFactory;
      const decimalPrecision = await getDecimalPrecision();
      if (amount && contract && address && decimalPrecision) {
        const newAmount = BigNumber.from(new Decimal(amount as string).mul(decimalPrecision).toFixed(0));
        const stableAddress = type === 'a3a' ? A3A?.address! : Euro3?.address!;
        const {hash, wait} = await writeContract({
          address: stableAddress,
          abi: erc20ABI,
          functionName: "approve",
          mode: 'recklesslyUnprepared',
          args: [contract!.address!, newAmount],
        });
        setStatus(statuses.SIGNING);
        await wait(1);
        
        const stakeWrite = await writeContract({
          address: contract!.address!,
          abi: contract!.abi,
          functionName: type === 'a3a' ? "stake" : "deposit",
          mode: 'recklesslyUnprepared',
          args: [newAmount],
        });
        await stakeWrite?.wait(TX_CONFIRMATIONS);
        setTx(stakeWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  const unstake = async ({ amount, type }: StakeParams) => {
    try {
      setIsLoading(true);
      setStatus(statuses.SIGNING);
      const contract = type === 'a3a' ? A3AStakingFactory : StabilityPoolFactory;
      const decimalPrecision = await getDecimalPrecision();
      if (amount && contract && address && decimalPrecision) {
        const newAmount = BigNumber.from(new Decimal(amount as string).mul(decimalPrecision).toFixed(0));
        const unstakeWrite = await writeContract({
          address: contract!.address!,
          abi: contract!.abi,
          functionName: type === 'a3a' ? "unstake" : "withdraw",
          mode: 'recklesslyUnprepared',
          args: [newAmount],
        });
        setTx(unstakeWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  const totalRewards = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'getRewardsTotal',
    args: [],
    watch: true
  });

  const percent = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'PERCENT',
    args: [],
    watch: true
  });

  const a3aToken = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'a3aToken',
    args: [],
    watch: true
  });

  const stableCoin = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'stableCoin',
    args: [],
    watch: true
  });

  const stableCoinUserGains = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'stableCoinUserGains',
    args: [address],
    watch: true
  });

  const stakes = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'stakes',
    args: [address],
    watch: true
  });

  const totalStake = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'totalStake',
    args: [],
    watch: true
  });

  const totalA3AStaked = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'totalA3AStaked',
    args: [],
    watch: true
  });

  const liquidationReserve = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'LIQUIDATION_RESERVE',
    args: [],
    watch: true
  });

  const DECIMAL_PRECISION = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'DECIMAL_PRECISION',
    args: [],
    watch: true
  });

  const getUnpaidStableCoinGain = useContractRead({
    address: A3AStakingFactory?.address!,
    abi: A3AStakingFactory?.abi,
    functionName: 'getUnpaidStableCoinGain',
    args: [address],
    watch: true
  });

  const redeemReward = async ({ amount, type, selectedTroveAddress }: StakeParamsWithTrove) => {
    try {
      setIsLoading(true);
      setStatus(statuses.SIGNING);
      const contract = type === 'a3a' ? A3AStakingFactory : StabilityPoolFactory;
      const decimalPrecision = await getDecimalPrecision();
      if (amount && contract && address && decimalPrecision) {
        //const newAmount = (amount as BigNumber).mul(decimalPrecision);
        //console.log(newAmount)
        const redeemWrite = await writeContract({
          address: contract!.address!,
          abi: contract!.abi,
          functionName: "redeemReward",
          mode: 'recklesslyUnprepared',
          args: type === 'a3a' ? [amount, selectedTroveAddress] : [],
        });
        setTx(redeemWrite.hash);
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  const getProtocolStats = async (): Promise<ProtocolStatsResponse> => {
    try {
      const response = await axios.get(`${API_BASE_URL}${ENDPOINTS.PROTOCOL_STATS}`);
      return response.data;
    } catch (err) {
      return null;
    }
  }


  const getStakingOverview = async (): Promise<OverviewResponse> => {
    try {
      const response = await axios.get(`${API_BASE_URL}${ENDPOINTS.A3A_STAKING.OVERVIEW}`);
      return response.data;
    } catch (err) {
      return null;
    }
  }

  const getStakingCashback = async (address: `0x${string}`): Promise<StakingCashbackResponse> => {
    try {
      console.log(`${API_BASE_URL}${ENDPOINTS.A3A_STAKING.CASHBACKS}`);
      const response = await axios.get(`${API_BASE_URL}${ENDPOINTS.A3A_STAKING.CASHBACKS.replace(':address', address)}`);
      return response.data;
    } catch (err) {
      return null;
    }
  }

  return {
    stake,
    unstake,
    redeemReward,
    getProtocolStats,
    liquidationReserve,
    a3aToken,
    percent,
    stableCoin,
    stableCoinUserGains,
    stakes,
    totalA3AStaked,
    totalStake,
    totalRewards,
    status,
    isLoading,
    isError,
    txHash,
    reset,
    DECIMAL_PRECISION,
    getStakingOverview,
    getStakingCashback,
    getUnpaidStableCoinGain
  };
};

export default useStaking;
