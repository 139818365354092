import { useMediaQuery, useTheme } from '@mui/material';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { useAccount, useNetwork } from 'wagmi';
import { supportedChains } from './chains/supported';
import ChainNotSupported from './components/compounds/errors/chain-not-supported/ChainNotSupported';
import MediaNotSupported from './components/compounds/errors/media-not-supported/MediaNotSupported';
import NotFound from './components/compounds/errors/not-found/NotFound';
import { TheBots } from './components/compounds/the-dashboard/the-bots/TheBots';
import { TheBotsSettings } from './components/compounds/the-dashboard/the-bots/TheBotsSettings';
import { TheDocs } from './components/compounds/the-dashboard/the-docs/TheDocs';
import { TheCreateTrove } from './components/compounds/the-dashboard/the-create-trove/TheCreateTrove';
import { TheStaking } from './components/compounds/the-dashboard/the-staking/TheStaking';
import { TheTrove } from './components/compounds/the-dashboard/the-trove/TheTrove';
import { TheDashboard } from './components/compounds/the-dashboard/TheDashboard';
import { TheSignin } from './components/compounds/the-signin/TheSignin';
import TheRedemptionLiquidation from './components/compounds/the-dashboard/the-redemption-liquidation/TheRedemptionLiquidation';
import { TheReferrals } from './components/compounds/the-dashboard/the-referrals/TheReferrals';
import { TheLiquidityMining } from './components/compounds/the-dashboard/the-liquidity-mining/TheLiquidityMining';
import { TheStatistics } from './components/compounds/the-dashboard/the-statistics/TheStatisticsPage';
import TheYields from './components/compounds/the-dashboard/the-yields/TheYields';
import {
  ALLOW_NON_CENTURION_PATHS,
  CENTURION_NFT_CHECK,
  PATHS,
} from './utils/constants';
import { TheSwap } from './components/compounds/the-dashboard/the-swap/TheSwap';
import { generatePathName } from './utils/strings';
import NotCenturion from "@/components/compounds/errors/not-centurion/NotCenturion";
import useIsCenturion from "@/hooks/useIsCenturion";
import TheDisclaimer from './components/compounds/the-dashboard/the-yields/TheDisclaimer';
import { useMemo } from 'react';

const {
  HOME,
  VAULT,
  VAULT_CREATE,
  STAKING,
  LIQUIDITY_MINING,
  BOTS,
  BOTS_SETTINGS,
  REDEMPTION_LIQUIDATION,
  DOCS,
  REFERRALS,
  STATISTICS,
  YIELDS,
  YIELDS_DISCLAIMER,
  SWAP,
} = PATHS;

function App() {
  const { isConnected, address } = useAccount()

  const { chain } = useNetwork()
  const isChainSupported:boolean = supportedChains.some(({ id }) => id === chain?.id)

  const theme = useTheme();
  const isMediaSupported:boolean = useMediaQuery(theme.breakpoints.up("laptop"));

  const { isCenturion } = useIsCenturion(address)
  
  const isCenturionAllowed = useMemo(() => {
    const pathname = window.location.pathname;
    console.log({isCenturion})
    return (
      CENTURION_NFT_CHECK === "false" ||
      isCenturion ||
      ALLOW_NON_CENTURION_PATHS.includes(pathname)
    );
  }, [isCenturion, window.location.pathname]);

  const isAddressCenturion = useIsCenturion(address)

  return (
    <>
      <Router>
        <Routes>
          { isMediaSupported
            ? isConnected
              ? CENTURION_NFT_CHECK === "false" || isCenturionAllowed
                ? isChainSupported
                  ? <>
                      <Route element={<TheDashboard />} >
                        <Route path={YIELDS} element={<TheYields />} />
                        <Route path={VAULT_CREATE} element={<TheCreateTrove />} />
                        <Route path={VAULT} element={<TheTrove />} />
                        <Route path={STAKING} element={<TheStaking />} />
                        <Route path={LIQUIDITY_MINING} element={<TheLiquidityMining />} />
                        <Route path={BOTS} element={<TheBots />} />
                        <Route path={BOTS_SETTINGS} element={<TheBotsSettings />} />
                        <Route path={REDEMPTION_LIQUIDATION} element={<TheRedemptionLiquidation />} />
                        <Route path={REFERRALS} element={<TheReferrals />} />
                        <Route path={STATISTICS} element={<TheStatistics />} />
                        <Route path={YIELDS_DISCLAIMER} element={<TheDisclaimer />} />
                        <Route path={SWAP} element={<TheSwap />} />
                        <Route path={HOME} element={<Navigate to={generatePathName(VAULT)} replace />} />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                    </>
                  : <Route path="*" element={<ChainNotSupported />} />
                : 
                <>
                        <Route element={<TheDashboard />} >
                        <Route path={YIELDS} element={<TheYields />} />
                        <Route path={VAULT_CREATE} element={<NotCenturion />} />
                        <Route path={VAULT} element={<NotCenturion />} />
                        <Route path={STAKING} element={<TheStaking />} />
                        <Route path={LIQUIDITY_MINING} element={<TheLiquidityMining />} />
                        <Route path={BOTS} element={<TheBots />} />
                        <Route path={BOTS_SETTINGS} element={<TheBotsSettings />} />
                        <Route path={REDEMPTION_LIQUIDATION} element={<TheRedemptionLiquidation />} />
                        <Route path={REFERRALS} element={<TheReferrals />} />
                        <Route path={STATISTICS} element={<TheStatistics />} />
                        <Route path={HOME} element={<Navigate to={generatePathName(YIELDS)} replace />} />
                        <Route path={YIELDS_DISCLAIMER} element={<TheDisclaimer />} />
                        <Route path={SWAP} element={<TheSwap />} />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                </>
              : <Route path="*" element={<TheSignin />} />
            : <Route path="*" element={<MediaNotSupported />} />
          }
        </Routes>
      </Router>
    </>
  );
}

export default App;
