import { Box } from '@mui/material';

import { Typography } from '@/components/atoms/data-display/typography/Typography';

function MediaNotSupported() {
  console.log('media not supported')
  return (
    <Box sx={{
      flex: 1,
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      bgcolor: 'common.black',
    }}>
      <Typography
        variant="h2"
        sx={{
          color: 'primary.main',
          mt: 30,
        }}
      >
        No media supported
      </Typography>
    </Box>
  );
}

export default MediaNotSupported