import {Chain, chain} from "wagmi";

/*
const bonqFork: Chain = {
    id: parseInt(process.env.REACT_APP_BONQ_FORK_CHAIN_ID || '0'),
    name: '3A DAO Testnet',
    network: 'bonq_fork',
    rpcUrls: {
        public: process.env.REACT_APP_BONQ_FORK_RPC || '',
        default: process.env.REACT_APP_BONQ_FORK_RPC || '',
    },
}
*/

const polygon: Chain = {
    id: parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID || '0'),
    name: 'Polygon',
    network: 'polygon',
    rpcUrls: {
        public: process.env.REACT_APP_POLYGON_RPC || '',
        default: process.env.REACT_APP_POLYGON_RPC || '',
    },
}

export const supportedChains = [polygon];
