type Props = {
  color?: string
}

export const TelegramIcon = ({ color = '#FA541C' }: Props) => {
  return (
    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 0C7.892 0 0.5 7.392 0.5 16.5C0.5 25.608 7.892 33 17 33C26.108 33 33.5 25.608 33.5 16.5C33.5 7.392 26.108 0 17 0ZM24.656 11.22C24.4085 13.827 23.336 20.163 22.7915 23.0835C22.5605 24.321 22.0985 24.7335 21.6695 24.783C20.7125 24.8655 19.9865 24.156 19.0625 23.5455C17.6105 22.5885 16.7855 21.9945 15.383 21.0705C13.7495 19.998 14.8055 19.404 15.746 18.447C15.9935 18.1995 20.2175 14.355 20.3 14.0085C20.3115 13.956 20.3099 13.9015 20.2956 13.8498C20.2812 13.798 20.2544 13.7506 20.2175 13.7115C20.1185 13.629 19.9865 13.662 19.871 13.6785C19.7225 13.7115 17.4125 15.246 12.908 18.282C12.248 18.7275 11.654 18.9585 11.126 18.942C10.532 18.9255 9.41 18.612 8.5685 18.3315C7.529 18.0015 6.7205 17.82 6.7865 17.2425C6.8195 16.9455 7.232 16.6485 8.0075 16.335C12.8255 14.2395 16.0265 12.8535 17.627 12.1935C22.214 10.2795 23.1545 9.9495 23.7815 9.9495C23.9135 9.9495 24.227 9.9825 24.425 10.1475C24.59 10.2795 24.6395 10.461 24.656 10.593C24.6395 10.692 24.6725 10.989 24.656 11.22Z"
        fill={color}
      />
    </svg>
  )
}