import { Box, LinearProgress } from "@mui/material";
import i18next from "i18next";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import useStabilityPool from "@/hooks/useStabilityPool";
import { getContractValue } from "@/utils/utils";
import { StakingCardProps } from "@/utils/types";
import useStaking from "@/hooks/useStaking";
import { useDashboardData } from "@/contexts/DashboardDataProvider";

export function TheTotalBEURInPool({ type }: StakingCardProps) {
  const { selectedTrove } = useDashboardData();
  const { totalDeposit } = useStabilityPool();
  const { totalA3AStaked } = useStaking(selectedTrove?.address as string);

  return (
    <>
      <Box sx={{ minHeight: "30px" }}>
        <Typography variant='body_regular'>
          {new Intl.NumberFormat(i18next.language, { style: "decimal", minimumFractionDigits: 2 })
            .format(getContractValue(type === 'euro3' ? totalDeposit: totalA3AStaked))}
        </Typography>
        {totalDeposit.isLoading && <LinearProgress color="primary" />}
      </Box>
    </>
  )
}
