import { Divider, Drawer, Grid } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import { ReactComponent as BackArrowIcon } from "../../../../assets/icons/back-arrow.svg"
import { ReactComponent as CoinbaseWalletSvg } from "../../../../assets/icons/coinbase.svg"
import { ReactComponent as MetamaskSvg } from "../../../../assets/icons/metamask.svg"
import { ReactComponent as WalletConnectSvg } from "../../../../assets/icons/wallet-connect.svg"
import { Icon } from '../../../atoms/data-display/icons/Icon'
import { Typography } from '../../../atoms/data-display/typography/Typography'
import { Button } from '../../../atoms/inputs/button/Button'
import { WalletList } from '../../walletlist/WalletList'

export function TheKeyStoreSideDrawer() {
  const { t } = useTranslation('translation', { keyPrefix: 'the-dashboard.the-key-store-side-drawer'})
  const { connector, address } = useAccount()

  let svg: any
  // Wagmi connector ids
  switch (connector?.id) {
    case "metaMask":
      svg = MetamaskSvg
      break;
    case "walletConnect":
      svg = WalletConnectSvg
      break;
    case "coinbaseWallet":
      svg = CoinbaseWalletSvg
      break;
    default:
      break;
  }
  
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onClose = () => setIsOpen(false)
  
  const onToggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Button
        variant="contained"
        color="neutral"
        disabled={!connector?.ready}
        onClick={onToggle}
        sx={{
          maxWidth: 150,
          border: "1.5px solid",
          borderColor: 'rgba(255, 255, 255, 0.12)',
          borderRadius: "80px",
          backgroundColor: 'rgba(0, 0, 0, 0.16)',
          px: '16px',
          py: '10px',
        }}
      >
        <Icon svg={svg} width="24px" height="24px" />
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            ml: 1,
            maxWidth: 86,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}>
          {address}
        </Typography>
      </Button>

      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={onClose}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
          alignContent="space-between"
          sx={{
            height:"100vh"
          }}
        >
          <Grid
            item 
            laptop={12} desktop={12}
          >
            <Typography variant='h1'>
              { t ('the-key-store') }
            </Typography>
          </Grid>

          <Grid
            item 
            laptop={12} desktop={12}
            sx={{
              height:"60vh"
            }}
          >
            <Typography
              variant='h3'
              sx={{
                pb: "48px"
              }}
            >
              { t ('choose-new-wallet') }
            </Typography>

            <WalletList />
          </Grid>

          <Grid
            item 
            laptop={12} desktop={12}
          >
            <Button
              variant="text"
              color="neutral"
              sx={{
                ml: "-16px",
              }}
              onClick={onToggle}
            >
              <Icon svg={BackArrowIcon} />
              <Typography
                variant='h2'
                sx={{
                  pl: "16px"
                }}
              >
                { t ('back') }
              </Typography>
            </Button>
            <Divider />
            
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
}
