import { Typography } from '@/components/atoms/data-display/typography/Typography';
import { Box, Tab, Tabs, TabsProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

export type Tab = {
  hash: string;
  name: string;
  content?: ReactNode;
  isComingSoon?: boolean;
};

type Props = TabsProps & {
  tabs: Tab[];
};

export const TheTabs = (props: Props) => {
  const theme = useTheme();
  const { primary } = theme.palette;

  return (
    <Box>
      <Tabs
        value={props.value}
        onChange={props.onChange}
        aria-label="tabs"
        textColor="primary"
        sx={{
          '& .MuiTabs-indicator': {
            borderColor: primary.main,
          }
        }}
      >
        {props.tabs.map((tab, index) => (
          <Tab
            key={`${tab.name}-index`}
            label={tab.name}
            id={`tab-${index}`}
            sx={{
              fontFamily: theme.typography.body_semibold,
              '&.Mui-selected': {
                color: theme.palette.text.primary,
              },
              mr: 2,
            }}
          />
        ))}
      </Tabs>
      {props.tabs.map((tab, index) => (
        <TabPanel value={props.value} index={index} key={tab.hash}>
          {tab?.isComingSoon ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              minHeight="60vh"
            >
              <Typography variant='h2' color="primary.main">
                Coming Soon!
              </Typography>
            </Box>
          ) : tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};
