import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import Decimal from "decimal.js";

import { InputField } from '@/components/atoms/inputs/inputField/InputField';
import { Button } from '@/components/atoms/inputs/button/Button';
import { EuroIcon } from '@/components/atoms/data-display/icons/Euro';
import { CoinIcon } from '@/components/atoms/data-display/icons/Coin';
import { StakingFormCardProps } from '@/utils/types';
import { Typography } from '@/components/atoms/data-display/typography/Typography';
import useStaking from '@/hooks/useStaking';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import { statuses } from '@/utils/constants';
import TheTransactionModal, { ExplorerTx } from '@/components/compounds/common/TheTransactionModal';
import useStabilityPool from '@/hooks/useStabilityPool';
import { formatValue } from '@/utils/utils';

const maxConfig = {
  minimumFractionDigits: 0,
  notation: 'compact',
};

const TheStakeFormCard = ({ type }: StakingFormCardProps) => {
  const { t } = useTranslation("translation", { keyPrefix: `the-dashboard.the-staking.the-staking-rewards-card.${type}`})
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })
  const { address } = useAccount()

  const { selectedTrove, euroBalance, a3aBalance, refetchBalances } = useDashboardData();

  const [stake, setStake] = useState<number | string | Decimal>('');
  const [unstake, setUnstake] = useState<number | string | Decimal>('');
  const [isModalOpen, showModal] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });

  
  const {
    stake: onStake,
    unstake: onUnstake,
    status,
    isLoading,
    reset,
    txHash,
    stakes :stakes,
    DECIMAL_PRECISION
  } = useStaking(selectedTrove?.address as string);

  const {
    getWithdrawableDeposit,
    DECIMAL_PRECISION: stabilityDecimalPrecision
  } = useStabilityPool();

  const maxUnstake = type === 'a3a' ? Number(stakes?.data as number/(DECIMAL_PRECISION?.data as number)) : Number(getWithdrawableDeposit?.data as number/(stabilityDecimalPrecision?.data as number))
  const maxStake = useMemo(() => {
    return type === 'euro3'
      ? Math.floor(euroBalance?.valueNumber || 0)
      : Math.floor(a3aBalance?.valueNumber || 0);
  }, [euroBalance?.valueNumber, a3aBalance?.valueNumber]);
  
  const handleStake = () => onStake({ amount: stake, type });
  const handleUnstake = () => onUnstake({ amount: unstake, type });

  const handleConfirm = () => {
    if (stake || unstake) {
      const processTx = stake ? handleStake : handleUnstake;
      setCallTx({
        callTx: () =>
          processTx().finally(() => {
            setStake('');
            setUnstake('');
          })
      });
      showModal(true);
    }
  };

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
            [statuses.APPROVING]: {
              detail: `Allowance for ${stake || unstake} ${type.toUpperCase()}`
            },
            [statuses.SIGNING]: {
              title: `${stake ? "Staking" : "Unstaking"}`,
              detail: stake
                ? `Staking ${stake} ${type.toUpperCase()}`
                : `Unstaking ${unstake} ${type.toUpperCase()}`
            },
            [statuses.FINISHED]: {
              detail: (
                <>
                  {modalT('finished.subtitle')}<br /><ExplorerTx tx={txHash} label={txHash.slice(-6)} />
                </>
              ),
              confirmLabel: modalT('confirm.primary'),
            },
          }
        : {},
    [isModalOpen, txHash]
  );

  const disabledButton = useMemo(() =>
    (Number(stake) <= 0 && Number(unstake) <= 0) || isLoading,
    [stake, unstake, isLoading],
  );

  const updateStakeValue = (val: any) => {
    const max = maxStake || 0;
    setStake(Number(val) > max ? max : val);
    setUnstake('');
  };

  const updateUnstakeValue = (val: any) => {
    const max = maxUnstake || 0;
    setUnstake(Number(val) > max ? max : val);
    setStake('');
  };

  return (
    <Box display="flex" flexDirection="column">
      {isModalOpen && callTx && (
        <TheTransactionModal
          status={status}
          isOpen={isModalOpen}
          onClose={() => {
            showModal(false);
            refetchBalances?.();
            reset();
          }}
          callTx={callTx}
          statusProps={statusProps}
        />
      )}
      <Typography
        variant="caption_regular"
        sx={{
          mt: 2.625,
          color: 'text.disabled',
        }}
      >
        {type === 'euro3' ? "Stake EURO3 and earn A3A liquidation rewards, all while supporting the protocol's stability." : "Stake A3A and start earning protocol fees, paid out in EURO3."}
      </Typography>
      <Box mt={2.625}>
        <InputField
          label={type === 'euro3' ? 'Stake' : 'Stake (A3A)'}
          caption={`Max deposit: ${formatValue(maxStake, maxConfig)} ${type.toUpperCase()}`}
          maxValue={maxStake}
          value={stake}
          setValue={updateStakeValue}
          placeholder="Enter the amount"
          captionIcon={<CoinIcon />}
          disabled={!(!unstake) || isLoading}
          startAdornment={type === 'euro3' ? <EuroIcon /> : "A3A"}
          disableDecimals
        />
      </Box>
      <Box mt={4}>
        <InputField
          label={type === 'euro3' ? 'Unstake' : 'Unstake (A3A)'}
          caption={`Max withdraw: ${formatValue(maxUnstake || 0, maxConfig)} ${type.toUpperCase()}`}
          maxValue={ maxUnstake }
          value={unstake}
          setValue={updateUnstakeValue}
          placeholder="Enter the amount"
          captionIcon={<CoinIcon />}
          disabled={!(!stake) || isLoading}
          startAdornment={type === 'euro3' ? <EuroIcon /> : "A3A"}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{
          alignSelf: 'flex-end',
          width: '100%',
          height: '48px',
          mt: 3,
        }}
        disabled={disabledButton}
        onClick={handleConfirm}
      >
        {t("confirm")}
      </Button>
    </Box>
  );
};

export default TheStakeFormCard;
