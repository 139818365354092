import { Box } from '@mui/material';

import { FlexRowSpaceBetween } from '@/components/atoms/containers/FlexRow';
import { FlexColumn } from '@/components/atoms/containers/FlexColumn';
import { TheLiquidityMiningPoolShareCard } from './the-pool-share-card/ThePoolShareCard';
import { TheLiquidityMiningForm } from './the-liquidity-ming-form/TheLiquidityMiningForm';
import { TheAllLiquidityCard } from './the-all-liquidity-card/TheAllLiquidityCard';
import useLiquidityMining from '@/hooks/useLiquidityMining';
import { ItemProp } from '@/components/atoms/inputs/select/Dropdown';
import { LiquidityPool } from '@/utils/types';
import { useEffect, useState } from 'react';

export function TheLiquidityMining() {
  const [selectedTab, setSelectedTab] = useState<'liquidity' | 'staking'>('liquidity');

  const {
    getLiquidityOverview
  } = useLiquidityMining();

  const [pools, SetPools] = useState<LiquidityPool[]>([]);
  const [liquidityPairs, SetLiquidityPairs] = useState<ItemProp[]>([]);

  const fetchOverview = async () => {
    try{
      const data = await getLiquidityOverview();
      const pools = data?.pools?.map(pool => { return {label: `${pool!.lpTokenSymbol}`, value: `${pool!.poolId}`} }) ?? [];
      SetPools(data?.pools??[]);
      SetLiquidityPairs(pools);
    } catch (error) {
      console.error('Error fetching overview:', error);
    }
  };

  useEffect(() => {
    fetchOverview();
  }, []);
  
  return (
    <Box px={5} py={5.5}>
      <FlexRowSpaceBetween mb={8}>
        <FlexColumn>
          <Box sx={{ maxWidth: 516 }}>
            <TheLiquidityMiningForm type={selectedTab} setType={setSelectedTab} />
          </Box>
        </FlexColumn>
        <FlexColumn>
          <TheAllLiquidityCard sx={{ mb: 3 }} />
          {pools?.length ? 
            pools.map((item, index) => (
              <TheLiquidityMiningPoolShareCard key={item?.poolId} sx={{ mb: 3 }} pool={item} />
            ))
            :
            <></>
          }
        </FlexColumn>
      </FlexRowSpaceBetween>
    </Box>
  );
}