import { useMemo } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { TheKeyStoreSideDrawer } from "../the-key-store-side-drawer/TheKeyStoreSideDrawer";
import { TheBEURBalance } from "./the-BEUR-balance/TheBEURBalance";
import { TheBNQBalance } from "./the-BNQ-balance/TheBNQBalance";
import { Dropdown } from "@/components/atoms/inputs/select/Dropdown";
import { FlexRow } from "@/components/atoms/containers/FlexRow";
import { Trove } from "@/utils/types";
import { useDashboardData } from "@/contexts/DashboardDataProvider";
import { PATHS } from "@/utils/constants";
import { Icon } from "@/components/atoms/data-display/icons/Icon";

import { ReactComponent as SwapIcon } from '@/assets/icons/swap.svg';
import Logo from '@/assets/images/3alogo.png';

const StyledLogo = styled('img')(({ theme }) => ({
  width: 24,
  height: 24,
  lineHeight: 'auto',
  marginRight: '8px',
}));

type VaultNavProps = {
  troves: Trove[];
};

const VaultNav = ({ troves = [] }: VaultNavProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedTrove, setSelectedTrove } = useDashboardData();

  const vaults = useMemo(() => {
    if (!troves?.length) {
      return [{
        label: 'Vault 1',
        value: 'vault1',
        svg: null,
      }];
    }

    return troves?.map(trove => ({
      label: trove?.vaultName,
      value: trove?.address,
      svg: null,
    }));
  }, [troves]);

  const showAddButton = useMemo(() => !location.pathname.includes(PATHS.VAULT_CREATE), [location]);

  const onSelectVault = (selectedVault: string) => {
    setSelectedTrove(selectedVault);
    if (location.pathname.includes(PATHS.VAULT_CREATE)) {
      navigate(PATHS.VAULT);
    }
  };

  const onClickAdd = () => {
    navigate(PATHS.VAULT_CREATE);
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
    >
      <Dropdown
        defaultItem={vaults[0]}
        items={vaults}
        onSelectChanged={onSelectVault}
        value={selectedTrove?.address || ''}
        sx={{
          maxHeight: 48,
          width: 270,
          '& .MuiTypography-root': {
            color: 'primary.main',
          },
          '& .MuiSelect-select': {
            py: 1.5,
          },
        }}
      />
      {!!showAddButton && (
        <Button
          variant="contained"
          color="primary"
          onClick={onClickAdd}
          sx={{
            maxHeight: 48,
            minWidth: 144,
            px: 2.75,
            py: 1.375,
            fontSize: 15,
            lineHeight: '26px',
            fontWeight: 700,
            ml: 2,
          }}
        >
          <AddCircleOutlineOutlined sx={{ mr: 1 }} />
          Add vault
        </Button>
      )}
    </Box>
  );
};

export function TheNavbar() {
  const { t } = useTranslation('translation', { keyPrefix: 'the-dashboard.the-navbar'})
  const location = useLocation();
  const navigate = useNavigate();

  const { troveData } = useDashboardData();

  let heading: string = ""
  switch (location.pathname.substring(1)) {
    case PATHS.VAULT_CREATE:
      heading = t("create-trove")
      break;
    case PATHS.VAULT:
      heading = t("trove")
      break;
    case PATHS.STAKING:
      heading = t("staking")
      break;
    case PATHS.BOTS:
      heading = t("bots")
      break;
    case PATHS.DOCS:
      heading = t("documentation")
      break;
    default:
      break;
  }
  
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.48)',
          border: '1px solid rgba(255, 255, 255, 0.12)',
          minHeight: 80,
          px: 3,
          py: 2,
        }}>
        {
          (location.pathname.includes(PATHS.VAULT) && troveData?.troves?.length)
            ? <VaultNav troves={troveData?.troves} />
            : <FlexRow />
        }
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            onClick={() => navigate(PATHS.SWAP)}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              borderRadius: 18,
              mr: 2,
              maxHeight: 42,
            }}
          >
            <Icon svg={SwapIcon} width="18px" height="18px" />
            <Typography
              variant="body_regular"
              sx={{ lineHeight: '22px', ml: 1 }}
            >
              Swap
            </Typography>
          </Button>
          <Box
            display="flex"
            sx={{
              px: '16px',
              py: '10px',
              minHeight: "44px",
              border: "1.5px solid",
              borderColor: 'rgba(255, 255, 255, 0.12)',
              borderRadius: "80px",
              backgroundColor: 'rgba(0, 0, 0, 0.16)',
              marginRight: 2,
              alignItems: 'center',
            }}
          >
            <StyledLogo alt="3A Logo" src={Logo} />
            <Box sx={{ marginRight: 1 }}>
              <TheBEURBalance />
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.12)',
                height: 24,
              }}
            />
            <Box sx={{ marginLeft: 1 }}>
              <TheBNQBalance />
            </Box>
          </Box>
          <Box>
            <TheKeyStoreSideDrawer />
          </Box>
        </Box>
      </Box>
    </>
  );
}