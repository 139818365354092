import { Box, Tab, Tabs } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { EmptyVault } from "@/components/atoms/data-display/icons/EmptyVault";
import { StakingIcon } from "@/components/atoms/data-display/icons/Staking";
import { RobotIcon } from "@/components/atoms/data-display/icons/Robot";
import { DocsIcon } from "@/components/atoms/data-display/icons/Docs";
import { ChartIcon } from "@/components/atoms/data-display/icons/Chart";
import { ReferralsIcon } from "@/components/atoms/data-display/icons/Referrals";
import { LiquidityMiningIcon } from "@/components/atoms/data-display/icons/LiquidityMining";
import { Statistics } from "@/components/atoms/data-display/icons/Statistics";
import { YieldIcon } from "@/components/atoms/data-display/icons/Yield";
import { ALLOW_NON_CENTURION_PATHS, CENTURION_NFT_CHECK, PATHS } from "@/utils/constants";
import { generatePathName } from "@/utils/strings";

import Logo from '@/assets/images/3alogo.png';

const {
  YIELDS,
  VAULT,
  STAKING,
  LIQUIDITY_MINING,
  BOTS,
  REDEMPTION_LIQUIDATION,
  DOCS,
  REFERRALS,
  STATISTICS,
} = PATHS;

const StyledLogo = styled('img')(({ theme }) => ({
  width: 48,
  height: 48,
  lineHeight: 'auto',
  cursor: 'pointer',
  marginLeft: '8px',
  marginBottom: '40px',
}));

export function TheSidebar() {
  const { t } = useTranslation('translation', { keyPrefix: 'the-dashboard.the-sidebar'})
  const location = useLocation();

  const [value, setValue] = useState<string | boolean>(VAULT);

  const menus = [
    {
      to: generatePathName(YIELDS),
      value: YIELDS,
      icon: YieldIcon,
      label: 'Yields',
      ariaLabel: 'Yields',
    },
    {
      to: generatePathName(VAULT),
      value: VAULT,
      icon: EmptyVault,
      label: 'Vault',
      ariaLabel: 'Vault',
    },
    {
      to: generatePathName(STAKING),
      value: STAKING,
      icon: StakingIcon,
      label: t ("staking"),
      ariaLabel: 'Staking',
    },
  {
      to: generatePathName(REDEMPTION_LIQUIDATION),
      value: REDEMPTION_LIQUIDATION,
      icon: ChartIcon,
      label: 'R/L',
      ariaLabel: 'Redemption',
    },
    /*{
      to: generatePathName(BOTS),
      value: BOTS,
      icon: RobotIcon,
      label: t ("bots"),
      ariaLabel: 'Bots',
    },
    */
    {
      to: generatePathName(REFERRALS),
      value: REFERRALS,
      icon: ReferralsIcon,
      label: t ("referrals"),
      ariaLabel: 'Referrals',
    },
    {
      to: generatePathName(STATISTICS),
      value: STATISTICS,
      icon: Statistics,
      label: t("statistics"),
      ariaLabel: t("statistics"),
    },
    {
      to: DOCS,
      value: DOCS,
      icon: DocsIcon,
      label: t ("docs"),
      ariaLabel: 'Docs',
      external: true
    },
  ];

  useEffect(() => {
    const initValue: string =  location.pathname === "/" ? "vault" : location.pathname.split("/")[1]
    const values = menus.map(menu => menu.value);
    if (values.includes(initValue)) {
      setValue(initValue);
    } else {
      setValue(false);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: "100%",
          py: 4,
          px: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.48)',
          backdropFilter: 'blur(12px)',
          borderRadius: 0,
          borderRight: '1px solid rgba(255, 255, 255, 0.12)',
        }}
      >
          <Link to={generatePathName(VAULT)} style={{ textDecoration: 'none' }} >
            <StyledLogo alt="3A Logo x" src={Logo} />
          </Link>
          <Tabs 
            orientation="vertical"
            value={value}
            onChange={handleChange}
            sx={{
              justifyContent: 'flex-start',
            }}
            TabIndicatorProps={{
              sx: {
                backgroundColor: 'transparent',
                width: '100%',
                borderRadius: '8px',
                background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 16.37%, rgba(255, 255, 255, 0) 101.79%)',
                borderInline: '1px solid rgba(250, 84, 28, 0.4)',
                pointerEvents: 'none',
              }
            }}
          >
            {menus.map(({ to, value: menuValue, icon: Icon, label, ariaLabel, external = false }) => {
              const isSelected = value === menuValue;
              const color = isSelected ? '#FA541C' : '#FFF';
              return (
                <Tab
                  key={label}
                  component={Link} to={to}
                  value={menuValue}
                  icon={<Icon color={color} />}
                  iconPosition="start"
                  rel="noreferrer"
                  onClick={(event) => {
                    if(external){
                      window.open(to, '_blank')
                      event.preventDefault();
                    }
                  }}
                  label={
                    <Typography
                      variant="body_regular"
                      color={color}
                      sx={{
                        marginLeft: '12px',
                      }}
                    >
                      {label}
                    </Typography>
                  }
                  aria-label={ariaLabel}
                  sx={{
                    justifyContent: 'flex-start',
                    padding: '13px 25px',
                    minHeight: '13px',
                    mb: 2,
                  }}
                />
              )
            })}
          </Tabs>
      </Box>
    </>
  );
}