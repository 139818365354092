import { BoxProps } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DisabledTypography, Typography } from "../../../../atoms/data-display/typography/Typography";
import { FlexRow } from "../../../../atoms/containers/FlexRow";
import { TransparentCard } from "../../../../atoms/containers/Card";
import InfoToolTip from '../../../../atoms/data-display/tooltip/InfoTooltip';

type Props = BoxProps & {
  titleBoxProps?: BoxProps;
  text?: string;
  value?: string;
};

export function TheRewardsCollectedCard(props: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-referrals.the-nft-rewards-card"})
  const { text , value } = props;

  console.log(props);
  // TODO: to be changed
  const rewardsCollected = 'TBA';

  return (
    <TransparentCard p={3} {...props}>
      <FlexRow mb={2} {...props.titleBoxProps}>
        <DisabledTypography variant='body_regular'>
          {text}
        </DisabledTypography>
        <InfoToolTip title={ t ("a3a-rewards-collected") } />
      </FlexRow>
      <Typography
        variant='subheading_regular'
        sx={{
          fontSize: 20,
          lineHeight: '24px',
        }}
      >
        {value}
      </Typography>
    </TransparentCard>
  )
}
