import {
  Button,
  Grid,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { BodyCell, Header } from '@/components/compounds/common/TheTable';
import { TextGradient, Typography } from '@/components/atoms/data-display/typography/Typography';
import { FlexRow } from '@/components/atoms/containers/FlexRow';
import { API_BASE_URL, ENDPOINTS, FORMAT_CONFIGS } from '@/utils/constants';
import { FlexColumn } from '@/components/atoms/containers/FlexColumn';
import { formatValue } from '@/utils/utils';
import { Icon } from '@/components/atoms/data-display/icons/Icon';

import { ReactComponent as ExternalLinkIcon } from '@/assets/icons/ic_external_link_outline.svg';
import { YieldOverviewResponse, YieldRow } from '@/utils/types';

const { NO_MIN_DIGITS, EURO } = FORMAT_CONFIGS;

const TheDisclaimer = () => {
  const [rows, setRows] = useState<YieldRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(`${API_BASE_URL}${ENDPOINTS.YIELDS.OVERVIEW}`);
        if (res.data) {
          const data: YieldOverviewResponse = res.data;
          const yields = Object.keys(data)
            .map(key => data[key])
            .flat(1)
            .sort((a, b) => Number(a.apr) - Number(b.apr));
          setIsLoading(false);
          setRows(yields);
        } else {
          setIsLoading(false);
          setRows([]);
        }
      } catch (err) {
        setIsLoading(false);
        setRows([]);
      }
    };

    fetchData();
  }, []);

  return (
    <FlexColumn px={11.5} pt={10} alignItems="center">
      <Typography variant="body_regular">
      <b><i>Disclaimer (1inch):</i></b><br /><br />
      Users participating in token swapping should be aware that 3A relies on 1inch third-party solution for this service. While we strive to provide a seamless and secure experience, it is important to acknowledge the involvement of an external provider in the token swapping process.<br /><br />

      Our token swapping functionality is dependent on 1inch technology for the execution of these transactions. Any issues related to the performance, security, or availability of the third-party solution are subject to the terms and conditions of the service provider.<br /><br />
      Users are responsible for understanding and accepting the terms and risks associated with the third-party solution used for token swapping. This includes potential disruptions, vulnerabilities, or changes in the third-party service that may impact the overall user experience.<br /><br />
      While we make efforts to ensure a reliable token swapping process, we cannot guarantee the continuous and uninterrupted performance of the third-party solution. Users should be aware that external factors, including market conditions and technical issues on the third-party platform, may affect the overall performance.<br /><br />
      3A platform shall not be held liable for any direct or indirect consequences, financial losses, or damages incurred by users in connection with their use of the third-party solution for token swapping.<br /><br />
      By choosing to use our platform for token swapping, users acknowledge the involvement of a third-party solution and accept the associated risks. We recommend users stay informed about updates and any changes made by the third-party provider to ensure a more secure token swapping experience.<br /><br /><br />

      <b><i>Disclaimer (yields)</i></b><br />
      Users participating in farming should be aware that our service relies on external providers. While we strive to offer a seamless and rewarding experience, it is important to recognize the integration of third-party solutions in the yield generation process.<br /><br />
      Our yield generation service utilizes third-party solutions, and the terms, performance, and security of this service are subject to the policies of the external providers. Any issues arising from the third-party solutions are beyond our direct control.<br /><br />
      Users are responsible for understanding and accepting the terms, risks, and conditions associated with the third-party solutions used for yield generation. This includes potential variations in yields, alterations to the third-party providers' terms, or interruptions in the services they offer.<br /><br />
      While we strive for a reliable yield generation experience, we cannot guarantee the continuous and uninterrupted performance of the third-party solutions. Users should be aware that external factors, market conditions, and technical issues with the third-party providers may impact overall performance.<br /><br />
      Our platform shall not be held liable for any direct or indirect consequences, financial losses, or damages incurred by users in connection with their use of third-party solutions for yield generation.<br /><br />
      By choosing to use our platform for yield generation users acknowledge the involvement of external providers and accept the associated risks. We recommend users stay informed about updates and any changes made by the third-party providers to ensure a more secure and prosperous yield generation experience.<br /><br /><br />

      <b><u>Short Versions</u></b><br /><br />

      <b><i>1inch</i></b><br /><br />
      Users participating in token swapping should be aware that 3A relies on 1inch third-party solution for this service. While we strive to provide a seamless and secured experience, it is important to acknowledge the involvement of an external provider in the token swapping process.  Any issues related to the performance, security, or availability of the third-party solution are subject to the terms and conditions of the service provider. 3A  shall not be held liable for any direct or indirect financial losses  incurred by users in connection with their use of the third-party solution for token swapping.<br /><br />
      
      <b><i>Yields</i></b><br /><br />
      Users participating in farming should be aware that our service relies on external providers. While we strive to offer a seamless and secured  experience, it is important to recognize the integration of third-party solutions in the yield generation process. Any issues related to the performance, security, or availability of the third-party solution are subject to the terms and conditions of the service provider.<br /><br />
      3A  shall not be held liable for any direct or indirect financial losses  incurred by users in connection with their use of third-party solutions for yield generation.<br /><br /><br />
      </Typography>
    </FlexColumn>
  );
};

export default TheDisclaimer;
