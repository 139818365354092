import { Box, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexRowAlignCenter } from '@/components/atoms/containers/FlexRow';
import { FlexColumn } from '@/components/atoms/containers/FlexColumn';
import { Typography } from '@/components/atoms/data-display/typography/Typography';
import { VaultHfGraph } from '@/components/atoms/data-display/icons/VaultHfGraph';
import useStatistics from '@/hooks/useStatistics';
import { formatValue, getCollateralDisplayData } from '@/utils/utils';
import { FORMAT_CONFIGS } from '@/utils/constants';

const { NO_MIN_DIGITS } = FORMAT_CONFIGS;
const TheStatisticsVaultHF = () => {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-statistics"})
  const theme = useTheme();
  const { text } = theme.palette;

  const { data: statisticsData } = useStatistics();
  const { data } = statisticsData?.[0] || {};

  const {
    healthFactor,
    healthFactorLimit,
    vaultsUnderHealthFactorLimit,
  } = useMemo(() => {
    const { healthFactor, vaultsUnderHealthFactorLimit = [] } = data || {};

    const { min = 0, max = 0, avg = 0, redemptionLimit = 0 } = healthFactor || {};

    const hfLimit = redemptionLimit;
    return {
      healthFactor: {
        min: Number(parseFloat(min.toString()).toFixed(2)),
        max: Number(parseFloat(max.toString()).toFixed(2)),
        avg: Number(parseFloat(avg.toString()).toFixed(2)),
        redemptionLimit: Number(parseFloat(redemptionLimit.toString()).toFixed(2)),
      },
      healthFactorLimit: formatValue(hfLimit, NO_MIN_DIGITS),
      vaultsUnderHealthFactorLimit,
    }
  }, [data]);

  return (
    <FlexColumn>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
          borderRadius: '16px',
          px: 3,
          pt: 0.375,
          justifyContent: 'space-between',
        }}
      >
        <FlexRowAlignCenter py={1.5}>
          <Typography variant='body_semibold' color={text.disabled}>
            {t("min-hf")}
          </Typography>
          <Typography 
            variant='body_semibold'
            sx={{
              color: getCollateralDisplayData(healthFactor.min, healthFactor.redemptionLimit).color,
              ml: 2.75,
            }}
          >
            {healthFactor?.min}
          </Typography>
        </FlexRowAlignCenter>
        <FlexRowAlignCenter py={1.5}>
          <Typography variant='body_semibold' color={text.disabled}>
            {t("max-hf")}
          </Typography>
          <Typography 
            variant='body_semibold'
            sx={{
              color: getCollateralDisplayData(healthFactor.max, healthFactor.redemptionLimit).color,
              ml: 2.75,
            }}
          >
            {healthFactor?.max}
          </Typography>
        </FlexRowAlignCenter>
        <FlexRowAlignCenter py={1.5}>
          <Typography variant='body_semibold' color={text.disabled}>
            {t("avg-hf")}
          </Typography>
          <Typography 
            variant='body_semibold'
            sx={{
              color: getCollateralDisplayData(healthFactor.avg, healthFactor.redemptionLimit).color,
              ml: 4.75,
            }}
          >
            {healthFactor?.avg}
          </Typography>
        </FlexRowAlignCenter>
      </Box>
      <FlexColumn
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
          borderRadius: '16px',
          px: 3,
          pt: 0.375,
          pb: 1.5,
          mt: 2.375,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pt: 1.5,
          }}
        >
          <VaultHfGraph />
          <Typography 
            variant='body_regular'
            color="error.main"
            sx={{ ml: 1.5 }}
          >
            {`Redeemable Vaults < ${healthFactor.redemptionLimit} HF`}
          </Typography>
        </Box>
        <FlexColumn sx={{
          bgcolor: 'rgba(235, 47, 6, 0.13)',
          borderRadius: '8px',
          maxHeight: 169,
          mt: 1.5,
          py: 3,
          pl: 2.5,
          pr: 1.5,
          overflowY: 'auto',
        }}>
          <ol style={{
            paddingLeft: 20,
            margin: 0,
          }}>
          {vaultsUnderHealthFactorLimit.map(vault => (
            <Typography variant='body_regular' color='#606060'>
              <li>
                {vault.address}
              </li>
            </Typography>
          ))}
          </ol>
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  )
}

export default TheStatisticsVaultHF;
