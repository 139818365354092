import { Box, BoxProps, Modal, ModalProps, useTheme } from '@mui/material';

import { Typography } from '../../atoms/data-display/typography/Typography';

export type TheModalProps = ModalProps & {
  title?: string;
  containerProps?: BoxProps;
};

const TheModal = ({
  title,
  children,
  containerProps,
  ...rest
}: TheModalProps) => {
  const theme = useTheme();

  return (
    <Modal
      {...rest}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(30px)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        ...rest.sx
      }}
    >
      <Box
        {...containerProps}
        sx={{
          p: 6,
          height: 394,
          width: 516,
          bgcolor: theme.palette.common.black,
          borderRadius: '16px',
          border: '1px solid rgba(255, 255, 255, 0.16)',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '-24px 24px 24px -8px rgba(145, 158, 171, 0.24)',
          ...containerProps?.sx,
        }}
      >
        {title ? (
          <Typography variant="h1" sx={{
            lineHeight: '48px',
            mb: 4,
            textAlign: 'center',
          }}>
            {title}
          </Typography>
        ) : null}
        {children}
      </Box>
    </Modal>
  );
};

export default TheModal;
