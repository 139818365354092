import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from "@mui/material";
import { styled } from '@mui/material/styles';

type TypographyProps = Pick<
  MuiTypographyProps,
  | "variant"
  | "color"
  | "align"
  | "gutterBottom"
  | "noWrap"
  | "paragraph"
  | "sx"
  | "display"
  | "onClick"
  | "children"
>;

export const Typography = ({ ...rest }: TypographyProps) => (
  <MuiTypography {...rest} />
);

export const DisabledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  pr: theme.spacing(0.5),
  fontSize: 12,
  lineHeight: '18px',
}));

export const TextGradient = styled(Typography)(({ theme }) => ({
  backgroundImage: 'linear-gradient(135deg, #1CFA75, #FA541C)',
  backgroundSize: '100%',
  backgroundRepeat: 'repeat',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '24px',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  MozBackgroundClip: 'text',
  MozTextFillColor: 'transparent',
}));
