import SvgIcon from "@mui/material/SvgIcon";
import { FunctionComponent, SVGProps } from "react";

type IconProps = {
  svg: FunctionComponent<SVGProps<SVGSVGElement>>
  width?: string
  height?: string
}

export const Icon = ({ svg, width, height }: IconProps) => {
  return (
    <SvgIcon 
      sx={{
        width: width ? width : "32px",
        height: height ? height: "32px"
      }}
      component={svg} 
      inheritViewBox
    />
  )
}