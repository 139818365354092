import { configureChains, createClient } from "wagmi";
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from "wagmi/providers/public";
import { supportedChains } from "../chains/supported";

// Configure chains for connectors to support
const { chains, provider } = configureChains(
  supportedChains, 
  [publicProvider()]
);

export const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ 
      chains 
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "Bonq",
      },
    })
  ],
  provider
})