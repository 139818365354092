import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps
} from "@mui/material";

type TooltipProps = Pick<
  MuiTooltipProps,
  | "title"
  | "placement"
  | "sx"
  | "children"
  | "arrow"
>;

export const Tooltip = ({ ...rest }: TooltipProps) => <MuiTooltip { ...rest} /> 