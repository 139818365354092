import { Box } from '@mui/material';
import { constants as ethConstants, ethers } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Decimal from "decimal.js";
import { useAccount } from 'wagmi';

import { InputField } from '@/components/atoms/inputs/inputField/InputField';
import { Button } from '@/components/atoms/inputs/button/Button';
import { formatValue, getCollateralSvg, limitDecimalPlaces } from '@/utils/utils';
import { statuses } from '@/utils/constants';
import TheTransactionModal, { ExplorerTx } from '@/components/compounds/common/TheTransactionModal';
import { useTroveCollateral } from '@/hooks/useTroveCollateral';
import { CoinIcon } from '@/components/atoms/data-display/icons/Coin';
import { Dropdown, ItemProp } from '@/components/atoms/inputs/select/Dropdown';
import { useDashboardData } from '@/contexts/DashboardDataProvider';
import { Collateral } from '@/utils/types';

const MAX_DECIMAL_PLACES = 3;

const TheTroveCollateralCard = () => {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-trove-form.collateral"})
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })
  const [deposit, setDeposit] = useState<number | string | Decimal>('');
  const [withdraw, setWithdraw] = useState<number | string | Decimal>('');
  const [isModalOpen, showModal] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });
  const [selectedCollateral, setSelectedCollateral] = useState<Collateral>();

  const { address: recipient = '' } = useAccount();
  const {
    selectedTrove,
    troveData,
    refetchBalances,
    refetchTroveData,
  } = useDashboardData();
  
  const collateralItems: ItemProp[] = [];

  for (let i = 0; i < troveData.collaterals.length; i++) {
    const token = troveData.collaterals[i];
    collateralItems.push({
      label: token.tokenName,
      value: token.address,
      svg: getCollateralSvg(token.tokenName),
    })
  };

  const {
    tokenBalance: maxDeposit,
    increaseCollateral,
    decreaseCollateral,
    status,
    isLoading,
    reset,
    txHash,
  } = useTroveCollateral(selectedTrove?.address as `0x${string}`, selectedCollateral);

  const { tokenName: symbol = '' } = selectedCollateral || {};

  const depositCaption = useMemo(() => {
    return `${t("deposit.hint")} ${maxDeposit?.valueDisplay || '0.00'} ${symbol}`;
  }, [maxDeposit, symbol]);

  const maxDepositNumber = useMemo(
    () => (maxDeposit?.valueNumber || 0),
    [maxDeposit],
  );

  const maxWithdraw = useMemo(() => {
    
    const collateralInfo = selectedTrove?.collateralInfo.find(
      item => item.address.toLowerCase() === selectedCollateral?.address?.toLowerCase()
    );
    const maxHuman = collateralInfo?.maxWithdrawable || "0";
    return maxHuman;
  }, [selectedTrove, selectedCollateral]);

  const withdrawCaption = useMemo(() => {
    return `${t("withdraw.hint")} ${maxWithdraw} ${symbol}`;
  }, [maxWithdraw, symbol]);

  const handleDeposit = () =>
    increaseCollateral({
      amount: ethers.utils.parseUnits(`${deposit}`, selectedCollateral?.decimals),
      selectedCollateral: selectedCollateral!,
    });

  const handleWithdraw = () =>
    decreaseCollateral({
      amount: ethers.utils.parseUnits(`${withdraw}`, selectedCollateral?.decimals),
      newNextTrove: ethConstants.AddressZero,
      recipient: recipient as string,
      selectedCollateral: selectedCollateral!,
    });

  const handleConfirm = () => {
    if (deposit || (withdraw && recipient)) {
      const processTx = deposit ? handleDeposit : handleWithdraw
      setCallTx({ callTx: () => processTx() });
      showModal(true);
    }
  };

  useEffect(() => {
    if (status === statuses.FINISHED) {
      refetchBalances && refetchBalances();
      refetchTroveData && refetchTroveData();
      setDeposit('');
      setWithdraw('');
    }
  }, [status]);

  const onCloseModal = () => {
    showModal(false);
    reset();
  };

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
            [statuses.APPROVING]: {
              detail: `Allowance for ${deposit || withdraw} ${symbol}`
            },
            [statuses.SIGNING]: {
              title: `${deposit ? "Increasing" : "Decreasing"} your collateral`,
              detail: deposit
                ? `Depositing ${deposit} ${symbol} into your vault`
                : `Withdrawing ${withdraw} ${symbol} from your vault`
            },
            [statuses.FINISHED]: {
              detail: (
                <>
                  {modalT('finished.subtitle')} <ExplorerTx tx={txHash} label={txHash.slice(-6)} />
                </>
              ),
              confirmLabel: modalT('confirm.primary'),
            },
          }
        : {},
    [isModalOpen, txHash, symbol]
  )

  const disabledDeposit = useMemo(() => {
    const hasWithdrawValue = Number(withdraw) > 0;
    return !selectedCollateral?.address || (!!selectedCollateral.address && hasWithdrawValue);
  }, [selectedCollateral, withdraw]);

  const disabledWithdraw = useMemo(() => {
    const hasDepositValue = Number(deposit) > 0;
    return !selectedCollateral?.address || (!!selectedCollateral.address && hasDepositValue);
  }, [selectedCollateral, deposit]);

  const disabledButton = useMemo(
    () => (Number(deposit) <= 0 && Number(withdraw) <= 0) || isLoading,
    [deposit, withdraw, isLoading],
  );

  const updateDepositValue = (val: any) => {
    const newDeposit = Number(val) > maxDepositNumber ? maxDepositNumber : val;
    const value = limitDecimalPlaces(newDeposit, MAX_DECIMAL_PLACES);
    setDeposit(value);
    setWithdraw('');
  };

  const updateWithdrawValue = (val: any) => {
    setWithdraw(val);
    setDeposit('');
  };

  return (
    <Box display="flex" flexDirection="column">
      {isModalOpen && callTx && (
        <TheTransactionModal
          status={status}
          isOpen={isModalOpen}
          onClose={onCloseModal}
          callTx={callTx}
          statusProps={statusProps}
        />
      )}
      <Box mt={5} mb={3}>
        <Dropdown
          defaultItem={{
            label: t("choose-collateral"),
            value: null,
            svg: null,
          }}
          placeholder={`${t("choose-collateral")}`}
          items={collateralItems}
          onSelectChanged={(value) => {
            const selected = troveData.collaterals.find(item => item.address === value);
            setSelectedCollateral(selected);
            setDeposit('');
            setWithdraw('');
          }}
          label="Collateral"
        />
      </Box>
      <Box mb={3}>
        <InputField
          inputLabel={`${t("deposit.title")} ${symbol}`}
          caption={depositCaption}
          captionIcon={<CoinIcon />}
          maxValue={maxDepositNumber}
          value={deposit}
          setValue={updateDepositValue}
          disabled={disabledDeposit}
          decimalPoints={MAX_DECIMAL_PLACES}
        />
      </Box>
      <Box mb={3}>
        <InputField
          inputLabel={`${t("withdraw.title")} ${symbol}`}
          caption={withdrawCaption}
          captionIcon={<CoinIcon />}
          maxValue={maxWithdraw}
          value={withdraw}
          setValue={updateWithdrawValue}
          disabled={disabledWithdraw}
          decimalPoints={MAX_DECIMAL_PLACES}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ width: '100%' }}
        disabled={disabledButton}
        onClick={handleConfirm}
      >
        {t("confirm")}
      </Button>
    </Box>
  );
};

export default TheTroveCollateralCard;
