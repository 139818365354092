import { LinearProgress, Link, Tooltip } from "@mui/material";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { SYMBOLS } from "@/utils/constants";
import { useDashboardData } from "@/contexts/DashboardDataProvider";
import { requestAddToken } from "@/wagmi/utils";
import { useTranslation } from "react-i18next";
import { useAbi } from "@/hooks/useAbi";

export function TheBEURBalance() {
  const { t } = useTranslation("translation", { keyPrefix: "nav"})
  const { euroBalance } = useDashboardData();
  const {AbiFactory: Euro3TokenFactory} = useAbi({abiName: 'MintableToken'})

  return (
    <>
      <Tooltip title={t( 'euro3-tooltip-description')}>
        <Link 
          component="button"
          color="inherit"
          onClick={async () => {
            if(Euro3TokenFactory?.address){
              requestAddToken('EURO3', Euro3TokenFactory.address!)
            }
          }}>
          <Typography variant="body_regular">
            {`${euroBalance.valueDisplay} ${SYMBOLS.EURO3}`}
          </Typography>
        </Link>
      </Tooltip>
      {euroBalance.isLoading && <LinearProgress color="primary" />}
    </>
  );
}