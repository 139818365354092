type Props = {
  color?: string
}

export const Statistics = ({ color = 'white' }: Props) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 7.66608H5L7.04044 1L11.4382 15L12.9903 7.66608H17"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}