import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DisabledTypography, Typography } from "@/components/atoms/data-display/typography/Typography";
import InfoToolTip from "@/components/atoms/data-display/tooltip/InfoTooltip";
import { FlexRowAlignCenter } from "@/components/atoms/containers/FlexRow";
import { TransparentCard } from "@/components/atoms/containers/Card";
import { StakingCardProps } from "@/utils/types";
import { TheTotalBEURInPool } from "./the-total-BEUR-in-pool/TheTotalBEURInPool";
import { TheTotalStakers } from "./the-total-stakers/TheTotalStakers";
import { TheUserBEURStake } from "./the-user-BEUR-stake/TheUserBEURStake";

export function TheBEURStakingStatistics({ type }: StakingCardProps) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-staking.the-BEUR-staking-statistics-card"})
  const uType = type.toUpperCase();

  return (
    <TransparentCard
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box mb={2}>
        <Typography variant='h3'>
          { t ("stability-pool-staking-statistics") }
        </Typography>
      </Box>

      <FlexRowAlignCenter>
        <FlexRowAlignCenter>
          <DisabledTypography variant='subheading_regular'>
            {`Total ${uType} tokens in the pool`}
          </DisabledTypography>
          <InfoToolTip
            title={`The sum of all ${uType} contributions to the Stability Pool.`}
          />
        </FlexRowAlignCenter>
        <TheTotalBEURInPool type={type}/>
      </FlexRowAlignCenter>

      {/*<FlexRowAlignCenter>
        <FlexRowAlignCenter>
          <DisabledTypography variant='subheading_regular'>
            { t ("total-number-of-stakers")}
          </DisabledTypography>
          <InfoToolTip
            title={ t ("the-number-of-unique-addresses-who-have-provided-liquidity-to-the-Stability-Pool.") }
          />
        </FlexRowAlignCenter>
        <TheTotalStakers />
    </FlexRowAlignCenter>*/}

      <FlexRowAlignCenter>
        <FlexRowAlignCenter>
          <DisabledTypography variant='subheading_regular'>
            {`My ${uType} stake`}
          </DisabledTypography>
          <InfoToolTip
            title={`The amount of ${uType} you have staked with the currently connected wallet.`}
          />
        </FlexRowAlignCenter>
        <TheUserBEURStake  type={type}/>
      </FlexRowAlignCenter>
    </TransparentCard>
  )
}
