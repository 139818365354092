import { Button, Divider, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { TransparentCard } from "@/components/atoms/containers/Card";
import { REFERRAL_LINKS } from "@/utils/constants";

type DataCardProps = {
  isCenturion: boolean
}

export function TheCenturionInfoCard({ isCenturion }: DataCardProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: `the-dashboard.the-referrals.the-centurion-info-card.${isCenturion ? 'centurion' : 'non-centurion'}`,
  });

  const infoData = [
    {
      title: 'Voting Power',
      description: '1.2x multiplier on your voting power.',
      hasDivider: true,
    },
    {
      title: 'Early Access',
      description: 'to new products and features within the 3A DAO ecosystem.',
      hasDivider: true,
    },
    {
      title: 'Referral Rewards:',
      description: '1.5x multiplier on your referrals when calculating A3A rewards',
      hasDivider: false,
    },
    {
      title: 'Exclusive Referral:',
      description: 'Specific premium products may have referral rewards only for Centurions.',
      hasDivider: false,
    },
  ];

  return (
    <TransparentCard pt={1.5} pl={40} pr={3.25} mb={3} minHeight={620}>
      <Typography
        variant="subheading_regular"
        sx={{
          color: 'primary.main',
          fontSize: 24,
          fontWeight: 600,
          lineHeight: '36px',
          whiteSpace: 'pre-wrap',
          mb: 0.75,
        }}
      >
        Become a Centurion
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        rowSpacing={3}
        columnSpacing={7}
      >
        {infoData.map(info => (
          <Grid item laptop={6} desktop={6}>
            <Typography variant="body_bold">
              {`${info.title} `}
              <Typography variant="body_regular">
                {info.description}
              </Typography>
            </Typography>
            {info.hasDivider && (
              <Divider sx={{ mt: 3, bgcolor: 'rgba(145, 158, 171, 0.32)' }} />
            )}
          </Grid>
        ))}
        {!isCenturion && (
          <>
            <Grid item laptop={6} desktop={6}>
              <Button
                variant="contained"
                color="warning"
                href={REFERRAL_LINKS.ZEALY_URL}
                target="_blank"
                rel="noreferrer"
                sx={{
                  color: 'common.black',
                  fontWeight: 700,
                  minWidth: 185,
                }}
              >
                Join Zealy Giveaways
              </Button>
            </Grid>
            <Grid item laptop={6} desktop={6}>
              <Button
                variant="contained"
                color="warning"
                href={REFERRAL_LINKS.OPENSEA_URL}
                target="_blank"
                rel="noreferrer"
                sx={{
                  color: 'common.black',
                  fontWeight: 700,
                  minWidth: 185,
                }}
              >
                Buy On Opensea
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </TransparentCard>
  )
}
