import { erc20ABI, useAccount } from 'wagmi';
import { readContract, writeContract, fetchBalance } from '@wagmi/core';
import { BigNumber, BigNumberish } from 'ethers';
import { useEffect, useState } from 'react';

import { TX_CONFIRMATIONS, statuses } from '@/utils/constants';
import { formatValue, isCollateralNative } from '@/utils/utils';
import { Balance, Collateral } from '@/utils/types';
import { useVaultFactory } from './useVaultFactory';

interface IncreaseCollateralParams {
  amount: BigNumber;
  selectedCollateral: Collateral;
}

interface DecreaseCollateralParams {
  amount: BigNumber;
  newNextTrove: string;
  selectedCollateral: Collateral;
  recipient: string;
}

const useTroveCollateral = (vaultAddress: `0x${string}`, selectedCollateral?: Collateral) => {
  const { address } = useAccount();
  const { VaultFactory } = useVaultFactory();

  const [status, setStatus] = useState(statuses.NONE);
  const [tokenBalance, setTokenBalance] = useState<Balance | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [txHash, setTx] = useState("");

  const reset = () => {
    setStatus(statuses.NONE);
    setIsLoading(false);
    setIsError(false);
    setTx("");
  };

  const getDecimalPrecision = async () => {
    const decimalPrecision = await readContract({
      address: VaultFactory!.address,
      abi: VaultFactory!.abi,
      functionName: "DECIMAL_PRECISION",
      args: []
    }) as BigNumberish;
    return decimalPrecision.toString();
  };

  const getStableAddress = async () => {
    const stableAddress = await readContract({
      address: VaultFactory!.address,
      abi: VaultFactory!.abi,
      functionName: "stable",
      args: []
    }) as string;
    return stableAddress;
  };

  const getCollateralTokenBalance = async () => {
    try {
      const config: any = {
        address,
        ...(!isCollateralNative(selectedCollateral!) && {
          token: selectedCollateral?.address,
        }),
      }
      const response = await fetchBalance(config);
      const valueNumber = parseFloat(response.formatted || '0');
      setTokenBalance({
        ...response,
        valueNumber,
        valueDisplay: formatValue(valueNumber, { notation: 'compact' }),
      });
    } catch (error) {
      setTokenBalance(null);
    }
  };

  const addCollateralNative = async ({amount}: IncreaseCollateralParams) => {
    if (VaultFactory) {
      const addCollateralNativeWrite = await writeContract({
        address: VaultFactory.address,
        abi: VaultFactory.abi,
        functionName: "addCollateralNative",
        mode: 'recklesslyUnprepared',
        args: [vaultAddress],
        overrides: { value: amount as BigNumber },
      });

      await addCollateralNativeWrite?.wait(TX_CONFIRMATIONS);
      setTx(addCollateralNativeWrite.hash);
    } else {
      setIsError(true);
      setStatus(statuses.ERROR);
    }
  }

  const addCollateral = async ({amount, selectedCollateral}: IncreaseCollateralParams) => {
    if (address && VaultFactory) {
      const addCollateralWrite = await writeContract({
        address: VaultFactory.address,
        abi: VaultFactory.abi,
        functionName: "addCollateral",
        mode: 'recklesslyUnprepared',
        args: [vaultAddress, selectedCollateral.address, amount],
      });
      await addCollateralWrite?.wait(TX_CONFIRMATIONS);
      setTx(addCollateralWrite.hash);
    } else {
      setIsError(true);
      setStatus(statuses.ERROR);
    }
  }

  const increaseCollateral = async ({amount, selectedCollateral}: IncreaseCollateralParams) => {
    try {
      setIsLoading(true);
      if (amount && VaultFactory) {
        if (isCollateralNative(selectedCollateral)) {
          setStatus(statuses.SIGNING);
          await addCollateralNative({ amount, selectedCollateral });
        } else {
          setStatus(statuses.APPROVING);
          const approve = await writeContract({
            address: selectedCollateral.address,
            abi: erc20ABI,
            functionName: "approve",
            mode: 'recklesslyUnprepared',
            args: [VaultFactory.address, amount],
          });
          setStatus(statuses.SIGNING);
          await approve?.wait(TX_CONFIRMATIONS);
          await addCollateral({ amount, selectedCollateral });
        }
        getCollateralTokenBalance();
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  const removeCollateralNative = async ({amount, selectedCollateral, recipient}: DecreaseCollateralParams) => {
    const removeCollateralNativeWrite = await writeContract({
      address: VaultFactory!.address,
      abi: VaultFactory!.abi,
      functionName: "removeCollateralNative",
      mode: 'recklesslyUnprepared',
      args: [vaultAddress, amount, recipient],
    });
    await removeCollateralNativeWrite?.wait(TX_CONFIRMATIONS);
    setTx(removeCollateralNativeWrite.hash);
  }

  const removeCollateral = async ({amount, selectedCollateral, recipient}: DecreaseCollateralParams) => {
    if (address && VaultFactory) {
      const removeCollateralWrite = await writeContract({
        address: VaultFactory!.address,
        abi: VaultFactory!.abi,
        functionName: "removeCollateral",
        mode: 'recklesslyUnprepared',
        args: [vaultAddress, selectedCollateral.address, amount, recipient],
      });
      await removeCollateralWrite?.wait(TX_CONFIRMATIONS);
      setTx(removeCollateralWrite.hash);
    } else {
      setStatus(statuses.ERROR);
      setIsError(true);
    }
  }

  const decreaseCollateral = async (params: DecreaseCollateralParams) => {
    try {
      const { amount, selectedCollateral } = params;
      setIsLoading(true);
      setStatus(statuses.SIGNING);
      if (amount && VaultFactory) {
        if (isCollateralNative(selectedCollateral)) {
          await removeCollateralNative(params);
        } else {
          await removeCollateral(params);
        }
        getCollateralTokenBalance();
        setStatus(statuses.FINISHED);
      } else {
        setStatus(statuses.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      setStatus(statuses.ERROR);
    }
  };

  useEffect(() => {
    if (selectedCollateral) {
      getCollateralTokenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollateral]);

  return {
    getCollateralTokenBalance,
    getDecimalPrecision,
    increaseCollateral,
    decreaseCollateral,
    tokenBalance,
    status,
    isLoading,
    isError,
    txHash,
    reset,
  };
};

const troveCollateralStatuses = statuses;
export { useTroveCollateral, troveCollateralStatuses };
