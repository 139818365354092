import { Box, Divider, Tooltip } from "@mui/material";
import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { Button } from '@/components/atoms/inputs/button/Button';
import { InfoIcon } from "@/components/atoms/data-display/icons/Info";
import { TransparentCard } from "@/components/atoms/containers/Card";
import { FlexRowAlignCenter } from "@/components/atoms/containers/FlexRow";
import TheTransactionModal, { CONFIRM, ExplorerTx } from "@/components/compounds/common/TheTransactionModal";
import { useDashboardData } from "@/contexts/DashboardDataProvider";
import useStabilityPool from "@/hooks/useStabilityPool";
import useStaking from "@/hooks/useStaking";
import { stakingRewards, statuses } from "@/utils/constants";
import { StakingCardProps, Trove } from "@/utils/types";
import { formatValue, getContractValue } from "@/utils/utils";
import { ThePendingStakingRewards } from "./the-pending-staking-rewards/ThePendingStakingRewards";
import { TheTotalStakingRewards } from "./the-total-staking-rewards/TheTotalStakingRewards";
import TheSelectVaultModal from "@/components/compounds/common/TheSelectVaultModal";

const { a3a, euro3 } = stakingRewards;

export function TheStakingRewardsCard({ type }: StakingCardProps) {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-staking.the-staking-rewards-card"})
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })

  const [harvestAmount, setHarvestAmount] = useState<string>('');
  const [isModalOpen, showModal] = useState(false);
  const [isVaultModalOpen, showVaultModalOpen] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });

  const { selectedTrove, refetchTroveData } = useDashboardData();
  const { totalA3ARewardsLeft, getDepositorA3AGain  } = useStabilityPool();
  const {
    totalRewards: totalEurRewards,
    txHash,
    status,
    reset,
    redeemReward,
    getUnpaidStableCoinGain
  } = useStaking(selectedTrove?.address!);

  const {
    pendingRewards,
    pendingRewardsNum,
    pendingRewardsBn,
  } = useMemo(() => {
    const totalRewards = type === euro3 ? getDepositorA3AGain : getUnpaidStableCoinGain;
    const pending = getContractValue(totalRewards);

    return {
      pendingRewardsNum: pending,
      pendingRewards: formatValue(pending, { minimumFractionDigits: 0 }),
      pendingRewardsBn: totalRewards?.data as BigNumber,
    }
  }, [getDepositorA3AGain, getUnpaidStableCoinGain]);

  const unit = type === a3a ? euro3.toUpperCase() : a3a.toUpperCase()

  useEffect(() => {
    if (status === statuses.FINISHED) {
      refetchTroveData && refetchTroveData();
    }
  }, [status]);

  const onHarvest = () => {
    if(type === euro3){
      setCallTx({
        callTx: () => redeemReward({
          type,
          amount: pendingRewardsBn,
        })
      });
      showModal(true);
    }else{
      showVaultModalOpen(true);
    }
    
  };

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
            [statuses.SIGNING]: {
              title: modalT('harvesting.title'),
              detail: `Harvesting ${harvestAmount} ${unit}`,
            },
            [statuses.FINISHED]: {
              detail: (
                <>
                  {modalT('finished.subtitle')}<br /><ExplorerTx tx={txHash} label={txHash.slice(-6)} />
                </>
              ),
              confirmLabel: modalT('confirm.primary'),
            },
          }
        : {},
    [isModalOpen, txHash]
  );

  return (
    <>
      {isVaultModalOpen && (
        <TheSelectVaultModal 
          title={modalT('harvesting.title')}
          detail={`Harvesting ${pendingRewards} ${unit} Max`}
          value={pendingRewardsNum}
          status={""} 
          isOpen={isVaultModalOpen} 
          onClose={() => {
            showVaultModalOpen(false);
          }}
          onSubmit={(selectedTrove: Trove | undefined, harvestValue: number) => {
            showVaultModalOpen(false);
            setHarvestAmount(formatValue(harvestValue, {minimumFractionDigits: 0}))
            if(selectedTrove?.address){
              setCallTx({
                callTx: () => redeemReward({
                  type,
                  amount: pendingRewardsBn,
                  selectedTroveAddress: selectedTrove!.address as `0x${string}`
                })
              });
              showModal(true);
            }
          }}
          statusProps={{}}
        />
      )}
      {isModalOpen && callTx && (
        <TheTransactionModal
          status={status}
          isOpen={isModalOpen}
          onClose={() => {
            showModal(false);
            reset();
          }}
          callTx={callTx}
          statusProps={statusProps}
        />
      )}
      <TransparentCard sx={{ p: 4, pt: 2 }}>
        <FlexRowAlignCenter sx={{ mb: 2 }}>
          <Typography
            variant='h3'
            sx={{
              fontWeight: 600,
            }}
          >
            { type === 'euro3' ? t ("staking-rewards") : t("cashbacks") }
          </Typography>
          <Tooltip
            title={type === 'euro3' ? 'Staking Rewards' : 'Cashbacks'}
            placement="top"
            arrow
          >
            <Box ml={1} mt={0.5} display="none">
              <InfoIcon />
            </Box>
          </Tooltip>
        </FlexRowAlignCenter>
        <FlexRowAlignCenter
          sx={{
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Box>
            <ThePendingStakingRewards type={type} />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={onHarvest}
            disabled={pendingRewardsNum <= 0}
            sx={{
              width: "101px",
              height: "48px",
            }}
          >
            <Typography>
              Harvest
            </Typography>
          </Button>
        </FlexRowAlignCenter>
        <Divider sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
        }} />
        <Box sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'row',
        }}>
          <TheTotalStakingRewards type={type} />
        </Box>
      </TransparentCard>
    </>
  )
}