import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { TheKeepYourVaultSafeCard } from "./the-keep-your-vault-safe-card/TheKeepYourVaultSafeCard";
import { TheBotsComingSoonCard } from "./the-bots-coming-soon-card/TheBotsComingSoonCard";


export function TheBots() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-trove.the-trove-opt-card.keep-your-vault-safe"})

  const navigate = useNavigate()
  // TODO: to be updated
  const [isActivated, setActivated] = useState(false);

  const onClickActivate = () => {
    setActivated(true);
  };

  const onClickAutoRenew = () => {
    navigate("/bots/settings")
  };

  return (
    <>
      {!isActivated ? (
        <Box sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          py: 3.5,
          px: 5,
        }}>
          <Box sx={{
            flex: 1,
            mr: 4,
          }}>
            <TheKeepYourVaultSafeCard
              onClickPrimaryButton={onClickActivate}
            />
          </Box>
          <Box sx={{
            flex: 1,
            mr: 4,
          }}>
            <TheBotsComingSoonCard />
          </Box>
          <Box sx={{
            flex: 1,
            mr: 4,
          }}>
            <TheBotsComingSoonCard />
          </Box>
        </Box>
      ) : (
        <Box sx={{
          flex: 1,
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        }}>
          <Box width={556}>
            <TheKeepYourVaultSafeCard
              showExportSettings
              showToggle
              isToggleChecked
              primaryButtonText={`${t('auto-renew')}`}
              onClickPrimaryButton={onClickAutoRenew}
              sx={{ p: 4, pt: 2 }}
              titleProps={{
                sx: { mb: 1 },
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}