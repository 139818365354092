import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Typography } from '../../../atoms/data-display/typography/Typography';
import { WalletList } from '../../walletlist/WalletList';

export const TheWalletListCard = () => {
  const { t } = useTranslation("translation", { keyPrefix: "the-signin.the-walletlist-card"})

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '384px',
        pl: 6,
        py: 3,
        pr: 3,
        borderRadius: '8px',
        backgroundColor: 'common.white',
      }}
    >
      <Typography
        variant="h2" 
        color="common.black"
        align="center"
        sx={{
          pb: "48px"
        }}
      >
        { t ("connect-your-wallet") }
      </Typography>

      <WalletList textColor='common.black' />
    </Box>
  )
}