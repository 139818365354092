import { 
  Card as MuiCard, 
  CardProps as MuiCardProps 
} from "@mui/material";

type CardProps = Pick<
  MuiCardProps,
  | "variant" 
  | "sx" 
  | "elevation" 
  | "children"
>;

export const Card = ({ ...rest }: CardProps) => (
  <MuiCard {...rest} />
);
