import {
  FormControl,
  MenuItem,
  outlinedInputClasses,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps as MuiSelectProps,
  useTheme,
  InputLabel,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { FunctionComponent, ReactNode, SVGProps, useState } from "react";
import { Icon } from "../../data-display/icons/Icon";
import { Typography } from "../../data-display/typography/Typography";
import { KeyboardArrowDown } from "@mui/icons-material";
import { FlexRow } from "../../containers/FlexRow";

export type ItemProp = {
  label: string
  svg?: FunctionComponent<SVGProps<SVGSVGElement>> | null
  value: string | null
}

type SelectProps = MuiSelectProps & {
  placeholder?: string,
  defaultItem?: ItemProp
  items: ItemProp[]
  onSelectChanged: (value: string) => void
}

const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  minHeight: 56,
  '& .MuiSelect-select': {
    py: theme.spacing(2),
    px: theme.spacing(1.75),
  },
  "&:focus": {
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
  '&:not(.Mui-disabled):hover::before': {
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
  '& .Mui-focused': {
    borderRadius: '8px',
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
  '& .MuiInputLabel-root': {
    color: `${theme.palette.text.disabled} !important`,
    marginTop: theme.spacing(0.25),
  },
  '& .MuiSelect-icon': {
    color: theme.palette.text.primary,
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '8px',
    color: theme.palette.text.disabled,
  },
}));

export const Dropdown = ({
  placeholder,
  defaultItem,
  items,
  onSelectChanged,
  label,
  ...rest
}: SelectProps) => {
  const [value, setValue] = useState<string>(defaultItem?.value || "");
  const theme = useTheme();
  const { text } = theme.palette;

  const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const newValue: string = event.target.value as string
    onSelectChanged(newValue)

    setValue(newValue)
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          color: text.disabled,
          fontFamily: 'Public Sans',
          fontSize: 14,
          lineHeight: '16px',
          mt: 0.5,
        }}
        shrink
      >
        {label}
      </InputLabel>
      <StyledSelect
        value={value}
        IconComponent={KeyboardArrowDown}
        onChange={handleChange}
        inputProps={{
          sx: {
            '&::placeholder': {
              color: text.primary,
              opacity: 1,
            },
            '&:focus': {
              border: '1px solid rgba(145, 158, 171, 0.32)',
            },
          },
        }}
        {...rest}
        sx={{
          ...(!!placeholder && {
            "& .MuiSelect-select .notranslate::after": {
                content: `"${placeholder}"`,
                fontFamily: "Public Sans",
                fontSize: 20,
                lineHeight: "24px",
                color: text.primary,
              }
            }),
          ...rest.sx,
        }}
      >
      {items.map(({ label, svg, value }: ItemProp, index) => (
        <MenuItem
          key={index}
          value={value || ''}
          sx={{
            '& .MuiPaper-root': {
              border: 0,
              borderRadius: '8px',
            },
            border: 0,
            borderRadius: '8px',
          }}
        >
          <FlexRow>
            {!!svg && <Icon svg={svg} />}
            <Typography
              variant='h3'
              sx={{ fontWeight: 400, ml: svg ? 2 : 0  }}
            >
              {label}
            </Typography>
          </FlexRow>
        </MenuItem>
      ))}
      </StyledSelect>
    </FormControl>
  )
}