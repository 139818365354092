import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrowIcon } from "../../../../assets/icons/back-arrow.svg";
import { Icon } from "../../../atoms/data-display/icons/Icon";
import { Typography } from "../../../atoms/data-display/typography/Typography";
import { Button } from "../../../atoms/inputs/button/Button";
import { FlexColumn } from "../../../atoms/containers/FlexColumn";

import Background from '../../../../assets/images/background.svg';

function NotCenturion() {
  const navigate = useNavigate()

  return (
    <>
      <FlexColumn
        sx={{
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right bottom',
          width: '100%',
          height: "100vh",
        }}
      >
        <Typography
          variant="h1"
          sx={{ mb: 2 }}
        >
            Only users holding a Centurion NFT, or referred by a Centurion, can access this page.
        </Typography>
      </FlexColumn>
    </>
  );
}

export default NotCenturion