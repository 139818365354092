import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FlexRow = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
}));

export const FlexRowAlignCenter = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const FlexRowSpaceBetween = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
