import { useEffect, useMemo, useState } from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ReactTextTransition, { presets } from 'react-text-transition';

import { TheWalletListCard } from './the-walletlist-card/TheWalletListCard';
import Background from '../../../assets/images/background.svg';
import Logo from '../../../assets/images/3alogo.png';

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '48px',
  lineHeight: '64px',
}));

const StyledLogo = styled('img')(({ theme }) => ({
  width: 60,
  height: 60,
  lineHeight: 'auto',
  cursor: 'pointer',
  display: 'inline-flex',
}));

export function TheSignin() {
  const { t } = useTranslation("translation", { keyPrefix: "the-signin"})

  const theme = useTheme();
  const { secondary, warning } = theme.palette;

  const [textIndex, setTextIndex] = useState(0);

  const texts = [
    {
      color: secondary.main,
      text: 'DECENTRALIZED',
    },
    {
      color: secondary.main,
      text: 'ACCESSIBLE',
    },
    {
      color: warning.main,
      text: 'for EVERYONE',
    },
  ];

  useEffect(() => {
    let interval = setInterval(() => {
      const newIndex = textIndex + 1;
      if (newIndex > texts.length - 1) {
        setTextIndex(0);
      } else {
        setTextIndex(newIndex);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [textIndex]);

  const currentText = useMemo(() => texts[textIndex], [textIndex]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom center',
          height: '100vh',
          pl: '100px',
          pr: '116px',
        }}
      >
        <Box sx={{ pt: 3 }}>
          <Link
            href="/#"
            color="inherit"
            aria-label="go to homepage"
            sx={{ lineHeight: 0 }}
          >
            <StyledLogo alt="3A Logo" src={Logo} />
          </Link>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}>
          <Box sx={{ mr: '36px', flex: 1 }}>
            <StyledHeading variant="h2">
              {'No approvals, no denials.'}<br />{'Loans are '}
              <ReactTextTransition
                springConfig={presets.gentle}
                delay={100}
                inline
                direction={textIndex === 0 ? 'down' : 'up'}
                style={{
                  color: currentText.color,
                  fontSize: 48,
                  fontWeight: 700,
                  lineHeight: '64px',
                }}
              >
                {currentText.text}
              </ReactTextTransition>
            </StyledHeading>
          </Box>
          <Box sx={{ flex: 1 }}>
            <TheWalletListCard />
          </Box>
        </Box>
      </Box>
    </>
  );
}