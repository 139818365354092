import { useEffect, useMemo, useState } from "react";
import {
  Box,
  styled,
  Button,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { TransparentCard } from "@/components/atoms/containers/Card";
import { InputField } from "@/components/atoms/inputs/inputField/InputField";
import { SwapInputData as InputData, SwapToken } from "@/utils/types";
import { FlexRow } from "@/components/atoms/containers/FlexRow";
import { FORMAT_CONFIGS, statuses } from "@/utils/constants";
import useSwap from "@/hooks/useSwap";
import { TheSwapTokenModal } from "./TheSwapTokenModal";
import TheTransactionModal, { ExplorerTx } from "../../common/TheTransactionModal";
import { formatValue, limitDecimalPlaces } from "@/utils/utils";
import { useCollateralTokenBalance } from "@/hooks/useCollateralTokenBalance";

const StyledButton = styled(Button)(({ theme }) => ({
  height: "48px",
  fontSize: '15px',
  lineHeight: '26px',
  paddingTop: '14px',
  paddingBottom: '14px',
  backgroundColor: '#FA541C59',
}));

export function TheSwap() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-swap"})
  const { t: modalT } = useTranslation('translation', { keyPrefix: 'modal' })
  const {
    status,
    isLoading: isSwapping,
    setIsLoading: setIsSwapping,
    getInitialPrices,
    getTokens,
    getTokenPrice,
    handleSwap,
    txHash,
  } = useSwap();

  const [tokens, setTokens] = useState<SwapToken[]>([]);
  const [from, setFrom] = useState<InputData>({
    type: 'from',
    label: t('from'),
    value: 0,
    price: 0,
    token: null,
    disabled: false,
    showModal: false,
  });
  const [to, setTo] = useState<InputData>({
    type: 'to',
    label: t('to'),
    value: 0,
    token: null,
    disabled: true,
    showModal: false,
  });
  const [isFetchingPrice, setFetchingPrice] = useState(false);
  const [isModalOpen, showModal] = useState(false);
  const [{ callTx }, setCallTx] = useState<{ callTx: null | (() => Promise<void>) }>({
    callTx: null
  });
  const [priceData, setPriceData] = useState({
    isLoading: true,
    tokens: { EURO3: 0, A3A: 0 },
  });

  const { tokenBalance: fromBalance } = useCollateralTokenBalance({ selectedToken: from?.token });

  const fetchInitialData = async () => {
    const result: SwapToken[] = await getTokens();
    setTokens(result);

    const prices = await getInitialPrices();
    setPriceData({
      isLoading: false,
      tokens: {
        EURO3: Number(prices?.EURO3) || 0,
        A3A: Number(prices?.A3A) || 0,
      },
    })
  };
  useEffect(() => {
    fetchInitialData();
  }, []);

  const fromTokens = useMemo(() => {
    return tokens?.filter(token => token.name !== to?.token?.name) || [];
  }, [tokens, to]);

  const toTokens = useMemo(() => {
    return tokens?.filter(token => token.name === 'EURO3' || token.symbol === 'fxA3A') || [];
  }, [tokens, from]);

  const onCloseTokenModal = () => {
    setFrom({ ...from, showModal: false });
    setTo({ ...to, showModal: false });
  };

  const onShowModal = (data: InputData) => {
    if (data.type === 'from') {
      setFrom({ ...data, showModal: true })
    } else {
      setTo({ ...data, showModal: true })
    }
  };

  const fetchPrice = async () => {
    if (
      (!from.value) ||
      (!from.token) ||
      (!to.token)
    ) {
      return;
    }
    setFetchingPrice(true);
    const tokenPrice = await getTokenPrice(
      from.token!,
      from.value,
      to.token!
    );
    setFetchingPrice(false);
    if (tokenPrice) {
      setFrom({
        ...from,
        price: Number(tokenPrice.tokenFrom.price),
      });
      setTo({
        ...to,
        value: Number(tokenPrice.minAmountOut),
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchPrice();
    }, 2000);
    return () => clearTimeout(timeoutId)
  }, [from.value]);

  useEffect(() => {
    if (to.token?.address) {
      fetchPrice();
    }
  }, [to.token?.address]);

  useEffect(() => {
    if (from.token?.address) {
      fetchPrice();
    }
  }, [from.token?.address]);

  useEffect(() => {
    if (fromBalance) {
      updateFromAmount(from);
    }
  }, [fromBalance, from.token]);

  const updateFromAmount = (fromData: InputData, amount?: number,) => {
    let newAmount = amount;

    if (fromData.token && fromBalance) {
      if (Number(newAmount) > (fromBalance.valueNumber || 0)) {
        newAmount = fromBalance.valueNumber || 0;
      }
    }
    setFrom({
      ...fromData,
      value: Number(newAmount) ? limitDecimalPlaces(Number(newAmount)) : "",
    });
  };

  const onSelectToken = (item: SwapToken, data: InputData) => {
    if (data.type === 'from') {
      setFrom({
        ...from,
        token: item,
        showModal: false,
      })
    } else {
      setTo({
        ...to,
        token: item,
        showModal: false,
      })
    }
  };

  const onClickSwap = () => {
    setCallTx({
      callTx: () => handleSwap(from, to)
    })
    showModal(true);
  }

  const onCloseModal = () => {
    showModal(false);
    setIsSwapping(false);
  };

  const onClickMax = () => {
    setFrom({
      ...from,
      value: fromBalance?.valueNumber || 0,
    });
  };

  const statusProps = useMemo(
    () =>
      isModalOpen
        ? {
            [statuses.APPROVING]: {
              detail: 
              `Allowance for ${from.value} ${from?.token?.symbol}`,
            },
            [statuses.SIGNING]: {
              title: `Signing`,
              detail: `Swapping ${from.value} ${from?.token?.symbol} to ${to.value} ${to?.token?.symbol}`,
            },
            [statuses.FINISHED]: {
              detail: (
                <>
                  {modalT('finished.subtitle')}<br /><ExplorerTx tx={txHash} label={txHash.slice(-6)} />
                </>
              ),
              confirmLabel: modalT('confirm.primary'),
            },
          }
        : {},
    [isModalOpen, txHash, from, to]
  );

  const renderInput = (data: InputData) => {
    return (
    <InputField
      label={data.label}
      showAddMinusButtons={false}
      placeholder="0"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant={data.token ? 'text' : 'contained'}
              onClick={() => onShowModal(data)}
              sx={{
                maxHeight: 30,
                borderRadius: 8,
                px: 1.5,
                py: 0.5,
              }}
            >
              {data.token ? (
                <FlexRow alignItems="center">
                  <Box
                    component="img"
                    src={data.token.logoURI}
                    sx={{ width: 16, height: 16 }}
                  />
                  <Typography
                    variant="body_regular"
                    sx={{ mx: 1 }}
                  >
                    {data.token.symbol}
                  </Typography>
                </FlexRow>
              ) : (
                <>
                  {t('select-token')}
                </>  
              )}
              <KeyboardArrowDownIcon fontSize="small" />
            </Button>
          </InputAdornment>
        )
      }}
      disabled={data.disabled}
      value={data.value}
      setValue={(value) => {
        if (data.type === 'from') {
          updateFromAmount(data, value);
        }
      }}
    />);
  };

  const renderFromBalance = () => (
    <Box>
      <Typography
        variant="caption_regular"
        color="grey.300"
        sx={{ mt: 0.5 }}
      >
        {`Balance: ${fromBalance?.valueDisplay || '0.0'}`}
      </Typography>
      <Button
        onClick={onClickMax}
        sx={{
          bgcolor: 'transparent',
          color: 'primary.main',
          fontSize: 13,
          maxHeight: 13,
          p: 0,
          '&:hover': {
            bgcolor: 'transparent',
            color: 'primary.dark',
          },
        }}>
        Max
      </Button>
    </Box>
  );

  return (
    <Box sx={{ py: 3, px: 5 }}>
      {isModalOpen && callTx && (
        <TheTransactionModal
          status={status}
          isOpen={isModalOpen}
          onClose={onCloseModal}
          callTx={callTx}
          statusProps={statusProps}
        />
      )}
      <TheSwapTokenModal
        open={from.showModal}
        tokens={fromTokens}
        selected={from.token}
        inputData={from}
        onClose={onCloseTokenModal}
        onSelect={onSelectToken}
      />
      <TheSwapTokenModal
        open={to.showModal}
        tokens={toTokens}
        selected={to.token}
        inputData={to}
        onClose={onCloseTokenModal}
        onSelect={onSelectToken}
      />
      <Box
        mt={3}
        ml={12}
        display="flex"
        flexDirection="row"
      >
        {Object.keys(priceData.tokens).map(token => (
          <Box mr={9}>
            <Typography
              variant="h3"
              sx={{
                fontFamily: 'Barlow',
                fontWeight: 600,
                mb: 1,
              }}
            >
              {`1 ${token} = `}
              <Box component="span" sx={{ color: 'success.main' }}>
                {formatValue(priceData.tokens[token as 'EURO3' | 'A3A'], {
                  style: "currency",
                  currency: "USD",
                  notation: "standard",
                  minimumFractionDigits: (token === 'EURO3' ? 2 : 4),
                  maximumFractionDigits: (token === 'EURO3' ? 2 : 4),
                })}
              </Box>
            </Typography>
            {!!priceData.isLoading && <LinearProgress color="primary" />}
          </Box>
        ))}
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        mt: 5,
      }}>
        <TransparentCard
          sx={{
            p: 4.5,
            pb: 10,
            width: '440px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h3" sx={{ mb: 0.5 }}>
            {t('title')}
          </Typography>
          <Typography
            variant="body_regular"
            sx={{
              color: 'text.disabled',
            }}
          >
            {t('subtitle')}
          </Typography>
          <Box sx={{ mt: 4.5, mb: 2 }}>
            {renderInput(from)}
            <Box display="flex" justifyContent="space-between" mt={0.5}>
         
              <Typography variant="caption_regular" color="text.disabled">
                     {/*
                {formatValue(from.price || 0, FORMAT_CONFIGS.USD)}
                        */}
              </Typography>
      
              {renderFromBalance()}
            </Box>
          </Box>
          <ArrowDownwardIcon sx={{ alignSelf: 'center' }} />
          <Box sx={{ mt: 2, mb: 3 }}>
            {renderInput(to)}
            {isFetchingPrice && (
              <Typography variant="caption_regular" color="text.disabled">
                Fetching price...
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%' }}
          >
            <StyledButton
              variant="contained"
              color="primary"
              fullWidth
              disabled={!from.token || !to.token || isSwapping || !from.value || !to.value}
              onClick={onClickSwap}
            >
              Swap
            </StyledButton>
          </Box>
        </TransparentCard>
        <Box mt={2} width={440}>
          <Typography variant="caption_regular" sx={{ fontSize: 10 }}>
            <b>DISCLAIMER</b><br /><br />
            Users participating in token swapping should be aware that 3A relies on 1inch third-party solution for this service. Any issues related to the performance, security, or availability of the third-party solution are subject to the terms and conditions of the service provider. 3A shall not be held liable for any direct or indirect financial losses incurred by users in connection with their use of the third-party solution for token swapping.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}