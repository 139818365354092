import { Box, BoxProps } from '@mui/material';

import { Tooltip } from './Tooltip';
import { InfoIcon, SmallInfoIcon } from '../icons/Info';

type Props = {
  title: string;
  iconColor?: string;
  containerProps?: BoxProps;
  isSmallInfoIcon?: boolean;
}

const InfoToolTip = ({
  title,
  iconColor,
  containerProps,
  isSmallInfoIcon = true,
}: Props) => (
  <Tooltip
    title={title}
    placement="top"
    arrow
  >
    <Box ml={0.5} {...containerProps}>
      {isSmallInfoIcon
        ? <SmallInfoIcon {...iconColor && { color: iconColor }} />
        : <InfoIcon {...iconColor && { color: iconColor }} />
      }
    </Box>
  </Tooltip>
);

export default InfoToolTip;
