import { BoxProps, LinearProgress, TypographyProps } from '@mui/material';

import { FlexColumn } from '@/components/atoms/containers/FlexColumn';
import { FlexRow } from '@/components/atoms/containers/FlexRow';
import InfoToolTip from '@/components/atoms/data-display/tooltip/InfoTooltip';
import { DisabledTypography, Typography } from '@/components/atoms/data-display/typography/Typography'

type Props = BoxProps & {
  title: string;
  value?: string;
  tooltip?: string;
  isLoading?: boolean;
  containerProps?: BoxProps;
  valueProps?: TypographyProps;
}

const TheIndividualDataCard = ({
  title,
  value,
  tooltip,
  isLoading = false,
  containerProps,
  valueProps,
  ...rest
}: Props) => {
  return (
    <FlexColumn
      {...containerProps}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        borderRadius: '16px',
        p: 3,
        mr: 2,
        ...containerProps?.sx
      }}
    >
      <FlexRow sx={{
        mb: 2,
      }}>
        <DisabledTypography variant='subheading_regular' sx={{ fontSize: 14 }}>
          {title}
        </DisabledTypography>
        {!!tooltip && <InfoToolTip title={tooltip} />}
      </FlexRow>
      {rest.children || (
          <Typography
            variant="subheading_regular"
            {...valueProps}
            sx={{
              fontSize: 20,
              lineHeight: '24px',
              whiteSpace: 'pre-wrap',
              ...valueProps?.sx
            }}
          >
            {value || '0.00'}
          </Typography>
        )}
      {!!isLoading && <LinearProgress color="primary" sx={{ mt: 1 }} />}
    </FlexColumn>
  )
}

export default TheIndividualDataCard;
