import { useEffect, useState } from 'react';
import axios from 'axios';

import { API_BASE_URL, ENDPOINTS } from '@/utils/constants';
import { StatisticsDataResponse } from '@/utils/types';

const useStatistics = () => {
  const [isLoading, setLoading] = useState(true);
  const [isRefetching, setRefetching] = useState(false);
  const [data, setData] = useState<StatisticsDataResponse[]>([]);

  const getStatistics = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}${ENDPOINTS.PROTOCOL_STATS}`)
      setLoading(false);
      const data: StatisticsDataResponse[] = res.data.data;
      setData(data);
    } catch (err) {
      setLoading(false);
      setData([]);
    }
  }

  useEffect(() => {
    if (isLoading || isRefetching) {
      getStatistics();
    }
  }, [isLoading, isRefetching]);

  return {
    isLoading,
    data,
    isRefetching,
    setRefetching,
  };
}

export default useStatistics;