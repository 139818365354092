// ## Matic - 25/01/2023
// BONQStaking: "0xb1b72B3579b03dFdCfF3195486277605e55Cf703",
// StabilityPool: "0x661F9159334F2429010eC5972B668D1D084C2E6A",
// TokenToPriceFeed: "0x20D50159aff262f953C8913Ec859Cac13A010b8a",
// TroveFactory: "0x3bB7fFD08f46620beA3a9Ae7F096cF2b213768B3",
// BonqToken: "0x91efbe97e08d0ffc7d31381c032d05fad8e25aaa",
// TroveStableCoin: "0x338Eb4d394a4327E5dB80d08628fa56EA2FD4B81",

import { Chain } from "wagmi";
import { generatePathName } from "./strings";

// ### Mumbai - 25/01/2023
// BONQStaking: "0x9de6B05958080391ADBBF2E3Fa1e15D814971801",
// StabilityPool: "0x497dF8a27Af3E8aa1cCF0062a0909Cf56F5ec8cE",
// TokenToPriceFeed: "0xDD618366A24982bfF3192c7f8855F6a51E4C16f8",
// TroveFactory: "0xf6063b2A386397C6fD5bb468523328bD363Cf614",
// BonqToken: "0x3Eeea0391bDAC02ad061f30f6cF1b25013a8F2a1",
// TroveStableCoin: "0xFe14396F9335C4446Edd843F16258d563C841646",

export const ContractAddresses = {
  TroveFactory: {
    polygon: "0x4760847023fa0833221ae76E01Db1E483A5D20e0",
    mumbai: "0x4760847023fa0833221ae76E01Db1E483A5D20e0",
    bonq_fork: "0x7f61D134690de3450feCe50a660292C6852C471B"
  },
  BNQ: {
    polygon: "0x58c7B2828e7F2B2CaA0cC7fEef242fA3196d03df",
    mumbai: "0x58c7B2828e7F2B2CaA0cC7fEef242fA3196d03df",
    bonq_fork: "0x58c7B2828e7F2B2CaA0cC7fEef242fA3196d03df"
  },
  BEUR: {
    polygon: "0xA0e4c84693266a9d3BBef2f394B33712c76599Ab",
    mumbai: "0xA0e4c84693266a9d3BBef2f394B33712c76599Ab",
    bonq_fork: "0xA0e4c84693266a9d3BBef2f394B33712c76599Ab"
  },
  BONQStaking: {
    polygon: "0x9b5089A5a48A8F3A7f8F5CB4837249787533f85A",
    mumbai: "0x9b5089A5a48A8F3A7f8F5CB4837249787533f85A",
    bonq_fork: "0x9b5089A5a48A8F3A7f8F5CB4837249787533f85A"
  },
  TokenToPriceFeed: {
    polygon: "0xfBC5cfEb809c6352Bc4ef2FFe842f72a8769E45e",
    mumbai: "0xfBC5cfEb809c6352Bc4ef2FFe842f72a8769E45e",
    bonq_fork: "0xfBC5cfEb809c6352Bc4ef2FFe842f72a8769E45e"
  },
  StabilityPool: {
    polygon: "0xDFf76acD594101fB5e9FaE176aEDb21A7a1Fe39F",
    mumbai: "0xDFf76acD594101fB5e9FaE176aEDb21A7a1Fe39F",
    bonq_fork: "0x5223eA36c7b4529f5408FCD8636592C64289440D"
  }
}

export const graphqlURI = {
  polygon: {
    uri: "https://polygon-mainnet.graph-eu.p2pify.com/ddebee50fad1e25af3d012ff4cad0056/borrowing-protocol",
    // headers: {
    //   authorization: "Basic Z25ycHJvZDpvalhXUXU3RkNJTlZhQXZpRzFxZG1FRWxBd3F3dG4="
    // }
  },
  mumbai: {
    uri: "https://gn-mumbai.bonq_forkdao.com/oi2309lsd/subgraphs/name/borrowing-protocol",
    // headers: {
    //   authorization: "Basic Z25ycWE6WGJDVzBicTdqTklLZ09nendCMFNKamFnQVk3c0Jv"
    // }
  },
  bonq_fork: {
    uri: process.env.REACT_APP_BONQ_FORK_GRAPH
  }
}

export const stakingRewards = {
  beur: 'a3a',
  bonq: 'euro3',
  a3a: 'a3a',
  euro3: 'euro3',
}

export const Euro = '€';

export const DECIMAL_PRECISION = "1000000000000000000";

export const uiMcrList = {
  "DAI": "125",
  "USDC": "125",
  "WETH": "200",
  "WMATIC": "200",
  "WALBT": "500",
}

export const statuses = {
  NONE: 0,
  APPROVING: 1,
  SIGNING: 2,
  FINISHED: 3,
  NAVIGATING: 99,
  ERROR: -1
}

type ChainWithIcon = Chain & { iconUrl: string };

export const local: ChainWithIcon = {
  id: 31337,
  name: "Localhost",
  network: "localhost",
  nativeCurrency: {
    decimals: 18,
    name: "Hard Hat Token",
    symbol: "HHT"
  },
  rpcUrls: {
    default: "http://167.71.76.226/29a8525d-418a-4c03-8ba3-b0157957c6d4/rpc"
  },
  blockExplorers: {
    default: { name: "ewc-explorer", url: "https://volta-explorer.energyweb.org" }
  },
  testnet: true,
  iconUrl: "/EW_logo_black.svg"
};

export const mumbai: ChainWithIcon = {
  id: 80001,
  name: "Mumbai Testnet",
  network: "mumbai",
  nativeCurrency: {
    decimals: 18,
    name: "Mumabi Test Token",
    symbol: "MTT"
  },
  rpcUrls: {
    default:
      "https://polygon-mumbai.g.alchemy.com/v2/B0d4baXcbLwwEf0JxbJzerT14UHa25Vy"
  },
  blockExplorers: {
    default: { name: "mumbai polygonscan", url: "https://mumbai.polygonscan.com/" }
  },
  testnet: true,
  iconUrl: "/polygon-matic.svg"
};

export const polygon: ChainWithIcon = {
  id: 137,
  name: "Polygon PoS",
  network: "matic",
  nativeCurrency: {
    decimals: 18,
    name: "Matic",
    symbol: "MATIC"
  },
  rpcUrls: {
    default:
      "https://polygon-rpc.com"
  },
  blockExplorers: {
    default: { name: "Polygon Scan", url: "https://polygonscan.com" }
  },
  testnet: false,
  iconUrl: "/polygon-matic.svg"
};

export const supportedChains = [polygon, local];

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api-testnet.3adao.org/';
export const SWAP_API_BASE_URL = process.env.REACT_APP_SWAP_API_BASE_URL || 'https://swap-api-8ty49.ondigitalocean.app/';
export const TX_CONFIRMATIONS = parseInt(process.env.REACT_APP_TX_CONFIRMATIONS ?? "1");
export const CENTURION_NFT_CHECK = process.env.REACT_APP_CENTURION_NFT_CHECK || "true";
export const ENDPOINTS = {
  CHAIN: 'chain',
  ABI: 'abi/',
  ABI_LIST: 'abi/list',
  COLLATERALS: 'collaterals',
  COLLATERALS_PRICE: 'collaterals/price/',
  PROTOCOL_STATS: 'protocolStats',
  VAULTS: 'vaults',
  VAULTS_ADDRESS: 'vaults/',
  VAULTS_USER: 'vaultsByUser/',
  REDEEMABLE_VAULTS: 'redeemableVaults',
  LIQUIDATABLE_VAULTS: 'liquidatableVaults',
  LIQUIDITY_MINING_OVERVIEW: 'liquidity_mining/overview',
  PRICES: 'prices',
  A3A_STAKING: {
    OVERVIEW: 'a3a_staking/overview',
    CASHBACKS: 'a3a_staking/cashbacks/:address'
  },
  STABILITY_POOL_STAKING: {
    OVERVIEW: 'stability_pool/overview',
    REWARDS: 'stability_pool/rewards/:address'
  },
  YIELDS: {
    OVERVIEW: 'yields/overview',
  },
  SWAP: {
    HEALTH_CHECK: 'health',
    TOKENS: 'tokens',
    SWAP: 'swap',
    APPROVAL: 'approval',
    PRICE: 'price',
  },
};

export const PATHS = {
  HOME: '/',
  YIELDS: 'yields',
  VAULT: 'vault',
  VAULT_CREATE: 'vault/create',
  STAKING: 'staking',
  LIQUIDITY_MINING: 'liquidity_mining',
  BOTS: 'bots',
  BOTS_SETTINGS: 'bots/settings',
  REDEMPTION_LIQUIDATION :'redemption_liquidation',
  DOCS: 'https://docs.3adao.org',
  REFERRALS: 'referrals',
  STATISTICS: 'statistics',
  YIELDS_DISCLAIMER: 'yields/disclaimer',
  SWAP: 'swap',
};

export const SYMBOLS = {
  EURO3: 'EURO3',
  A3A: 'A3A',
};

export const FORMAT_CONFIGS = {
  EURO: {
    style: "currency",
    currency: "EUR",
    notation: "standard",
  },
  EURO_WITH_MAX: {
    style: "currency",
    currency: "EUR",
    notation: "standard",
    maximumFractionDigits: 3,
  },
  USD: {
    style: "currency",
    currency: "USD",
    notation: "standard",
  },
  NO_MIN_DIGITS: {
    minimumFractionDigits: 0,
  },
  MAX_DIGITS_3: {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  },
  PERCENTAGE_NO_MIN: {
    minimumFractionDigits: 0,
    style: 'percent',
  },
};
export const FOOTER_URLS = {
  TELEGRAM_URL: 'https://t.me/+UoTgLIswp3szM2I0',
  TWITTER_URL: 'https://twitter.com/3aaaDAO',
};

export const ALLOW_NON_CENTURION_PATHS = [
  generatePathName(PATHS.STAKING),
  generatePathName(PATHS.LIQUIDITY_MINING),
  generatePathName(PATHS.YIELDS),
  generatePathName(PATHS.SWAP),
];

export const REFERRAL_LINKS = {
  ZEALY_URL: 'https://zealy.io/c/3adao/invite/D9fMSnOhHIGaeZndSOVIi',
  OPENSEA_URL: 'https://opensea.io/collection/centurion-3adao',
};