import { useNavigate } from "react-router-dom";

import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { TransparentCard } from "@/components/atoms/containers/Card";
import { TheTrovesTable } from "./the-troves-table/TheTrovesTable";

export function TheTrovesTableCard() {
  const navigate = useNavigate()

  // TODO: change vault name
  const vaultName = 'My Vaults';

  return (
    <TransparentCard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        mb: 3,
      }}
    >
      <Typography
        variant='h2'
        sx={{
          lineHeight: '36px',
          mb: 3,
        }}
      >
        {vaultName}
      </Typography>
      <TheTrovesTable />
      {/* <Button
        variant="contained"
        color="tertiary"
        sx={{
          width: "200px",
          height: "32px"
        }}
        onClick={() => navigate("/vault/create")}
      >
        { t ("create-trove") }
      </Button> */}
    </TransparentCard>
  )
}