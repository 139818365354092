import { LinearProgress, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TheCreateTroveCard } from "./the-create-trove-card/TheCreateTroveCard";
import { Typography } from "@/components/atoms/data-display/typography/Typography";
import { useDashboardData } from "@/contexts/DashboardDataProvider";

export function TheCreateTrove() {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-create-trove.the-create-trove-card"})
  const { isLoading } = useDashboardData();

  return (
    <>
      {!isLoading
        ? <>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}>
              <Box sx={{ width: '516px', my: 4.5 }}>
                <Typography
                  variant='h3'
                  sx={{
                    color: 'grey.100',
                    fontSize: '32px',
                    lineHeight: '48px',
                    fontWeight: 700,
                    textAlign: 'center',
                    mb: 4,
                  }}
                >
                  {t("create-trove")}
                </Typography>
                <TheCreateTroveCard />
              </Box>
            </Box>
          </>
        : <LinearProgress color="primary" />
      }
    </>
  )
}