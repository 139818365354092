import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import { FunctionComponent, SVGProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { FlexRowAlignCenter } from '@/components/atoms/containers/FlexRow';
import { formatValue, getCollateralSvg } from '@/utils/utils';
import { FlexColumn } from '@/components/atoms/containers/FlexColumn';
import { BodyCell, Header } from '../../common/TheTable';
import { Icon } from '@/components/atoms/data-display/icons/Icon';
import { StatisticsCollateralData } from '@/utils/types';
import useStatistics from '@/hooks/useStatistics';
import { Typography } from '@/components/atoms/data-display/typography/Typography';
import { FORMAT_CONFIGS } from '@/utils/constants';

const StyledHeader = styled(Header)(({ theme }) => ({
  borderBottom: 'none',
}));

const StyledBodyCell = styled(BodyCell)(({ theme }) => ({
  borderBottom: 'none',
}));

const HEADER_COLOR = 'rgba(239, 240, 246, 0.4)';
const { NO_MIN_DIGITS } = FORMAT_CONFIGS;

type Row = StatisticsCollateralData & {
  svg: FunctionComponent<SVGProps<SVGSVGElement>>,
}

const TheStatisticsCollateralsTable = () => {
  const { t } = useTranslation("translation", { keyPrefix: "the-dashboard.the-statistics.table"})
  const theme = useTheme();
  const { primary } = theme.palette;

  const { isLoading, data } = useStatistics();

  const [keyword, setKeyword] = useState('');
  const [rows, setRows] = useState<Row[]>([]);
  const [allRows, setAllRows] = useState<Row[]>([]);

  useEffect(() => {
    if (!isLoading && data.length) {
      const collateralData = data[0]?.data?.collateralData || [];
      const collaterals = collateralData
        .map((item) => ({
          ...item,
          svg: getCollateralSvg(item.tokenName),
        }))
        .sort((a, b) => a.mcr - b.mcr);
      setAllRows(collaterals);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!keyword && allRows.length) {
      setRows(allRows);
      return;
    }

    const filtered = allRows.filter(row => row.tokenName.toLowerCase().includes(keyword.toLowerCase()));
    setRows(filtered);
  }, [keyword, allRows]);

  return (
    <Box>
      <FlexColumn
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
          borderRadius: '16px',
          p: 3,
          mt: 2.5,
        }}
      >
        <FlexRowAlignCenter>
          <Search color="primary" sx={{ fontSize: 24 }} />
          <TextField
            variant="standard"
            placeholder={`${t('search')}`}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            sx={{
              fontFamily: 'Public Sans',
              fontSize: 14,
              fontWeight: 500,
              ml: 1,
              '& .MuiInputBase-root': {
                color: primary.main,
              },
            }}
            inputProps={{
              sx: {
                '&::placeholder': {
                  color: primary.main,
                  opacity: 1,
                },
              },
            }}
          />
        </FlexRowAlignCenter>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pt: 1.5,
          }}
        >
          <TableContainer sx={{
            maxHeight: 351,
            width: '100%',
            border: 'none',
          }}>
            <Table sx={{ p: 0 }}>
              <TableHead>
                <TableRow>
                  <StyledHeader
                    name=""
                    bgcolor='transparent'
                    minWidth={48}
                  />
                  <StyledHeader
                    name={t('collateral')}
                    bgcolor={HEADER_COLOR}
                    minWidth={70}
                    sx={{
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    }}
                  />
                  <StyledHeader align='right' bgcolor={HEADER_COLOR}>
                    <Typography variant='body_semibold'>
                      {t('total-deposited-collateral')}
                    </Typography><br />
                    <Typography variant='body_regular'>
                      {t('total-deposited-collateral-tokens-sub')}
                    </Typography>
                  </StyledHeader>
                  <StyledHeader align='right' bgcolor={HEADER_COLOR}>
                    <Typography variant='body_semibold'>
                      {t('total-deposited-collateral')}
                    </Typography><br />
                    <Typography variant='body_regular'>
                      {t('total-deposited-collateral-value-sub')}
                    </Typography>
                  </StyledHeader>
                  <StyledHeader
                    align='right'
                    name={t('mcr')}
                    bgcolor={HEADER_COLOR}
                    minWidth={70}
                  />
                  <StyledHeader
                    align='right'
                    name={t('mlr')}
                    bgcolor={HEADER_COLOR}
                    minWidth={70}
                  />
                  <StyledHeader align='right' bgcolor={HEADER_COLOR}>
                    <Typography variant='body_semibold'>
                      {t('borrowing-capacity')}
                    </Typography><br />
                    <Typography variant='body_regular'>
                      {t('borrowing-capacity-sub')}
                    </Typography>
                  </StyledHeader>
                  <StyledHeader align='right' bgcolor={HEADER_COLOR}>
                    <Typography variant='body_semibold'>
                      {`${t('borrowing-capacity-used')} `}
                      <Typography variant='body_regular'>
                        {t('borrowing-capacity-used-sub')}
                      </Typography>
                    </Typography>
                  </StyledHeader>
                  <StyledHeader
                    align='right'
                    name={t('issuance-fee')}
                    bgcolor={HEADER_COLOR}
                    minWidth={95}
                    sx={{
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                    }}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length
                  ? rows.map(row => (
                    <TableRow key={row.address}>
                      <StyledBodyCell>
                        <Icon svg={row.svg} width="18" height="18" />
                      </StyledBodyCell>
                      <StyledBodyCell value={row.tokenName} />
                      <StyledBodyCell align='right' value={formatValue(row.capUsage, NO_MIN_DIGITS)} />
                      <StyledBodyCell align='right' value={formatValue(row.capUsageInEuro, NO_MIN_DIGITS)} />
                      <StyledBodyCell align='right' value={`${row.mcr}%`} />
                      <StyledBodyCell align='right' value={`${row.mlr}%`} />
                      <StyledBodyCell align='right' value={formatValue(row.cap, NO_MIN_DIGITS)} />
                      <StyledBodyCell align='right' value={`${formatValue(row.capUsagePercentage, NO_MIN_DIGITS)}%`} />
                      <StyledBodyCell align='right' value={formatValue(row.borrowRate, { style: 'percent' })} />
                    </TableRow>
                  ))
                  : (
                    <TableRow>
                      <StyledBodyCell />
                      {isLoading
                        ? (
                          <StyledBodyCell colSpan={6}>
                            <LinearProgress color='primary' />
                          </StyledBodyCell>
                        ) : (
                          <StyledBodyCell
                            colSpan={6}
                            value='No records found'
                            sx={{ textAlign: 'center' }}
                          />
                        )}
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </FlexColumn>
    </Box>
  )
}

export default TheStatisticsCollateralsTable;
